import React from 'react';

function IconWrapper({icon, style, ...rest}) {

    const newStyles = style || [];

    return <div style={{
        fontFamily: 'firmamex-icons', 
        fontSize:'20px',
        padding:'10px',
        WebkitUserSelect:'none',
        ...newStyles}}
        {...rest}>{icon}</div>
}

export function DownloadIcon(props) {
    return <IconWrapper icon="ic_download" {...props}></IconWrapper>
}

export function VerticalDots(props) {
    return <IconWrapper icon="ic_vertical_dots" {...props}></IconWrapper>
}

export function FirmantesIcon(props) {
    return <IconWrapper icon="ic_firmantes" {...props}></IconWrapper>
}

export function ChevronRightIcon(props) {
    return <IconWrapper icon="ic_chevron_right" {...props}></IconWrapper>
}

export function ChangeUserIcon(props) {
    return <IconWrapper icon="ic_change_user" {...props}></IconWrapper>
}
