import React, {useState, useEffect} from 'react';
import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import clamp from "lodash/clamp";
import FlexRow from './FlexRow'
import styles from './HorizontalSlider.module.css'
import { usePalette } from './Customizables';

const handleWidth = 30;

export default function HorizontalSlider({width, minValue, maxValue, value, onChange, style}) {

    const palette = usePalette();
    const [initialValue, setInitialValue] = useState(null);
    const [{ x }, api] = useSpring(() => ({
      x: 8
    }));    


    const bind = useDrag(({last, movement: [mx,]}) => {
        let newX =  mx;

        const p = (Math.min(newX, width) / width)
        const val = (p * (maxValue - minValue) + minValue)                    
        onChange(Math.max(val, 3))  

        if(last) {
            setInitialValue(newX + initialValue)
            return
        }

        api.start({
            x: clamp(newX + initialValue, minValue, width - handleWidth),
            config: config.stiff,
            immediate: true
        });
    
    },{
        from: () => [x.get(), 0],
        filterTaps: true,
        eventOptions: {
            capture: true
        }
    });


    useEffect(() => {

        if(!initialValue) {
            // value es un valor dentro del rango, necesitamos
            // el valor dentro de 0 a width
            // primero obtenemos el porcentaje dentro de minValue a maxValue
            const p = value / maxValue;
            // calculamos el valor del porcentaje dentro del rango 0 a width
            const val = (p * width);
            setInitialValue(val)
            api.start({
                x: val,
                config: config.stiff
            })
        }        
    }, [value, initialValue, maxValue, width])

    return (
        <FlexRow style={{...style, width}}>
            <img alt="slider-background" 
                src={`${process.env.REACT_APP_CDN_PATH}/images/horizontal-slider-background.png`}
                className={styles.background}
                style={{width}}></img>
            <animated.div {...bind()}
                className={styles.handle}
                style={{           
                    cursor:'grabbing',      
                    backgroundColor: palette.secondaryColor,   
                    borderRadius:handleWidth,
                    height: handleWidth,
                    width:handleWidth,
                    zIndex: 1,
                    left: `calc(-100vh + ${width - 100}px)`,
                    x,
                    touchAction: 'none'
                }}>
            </animated.div>
        </FlexRow>        
    )

}