import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPin } from '../redux/actions';


export default function useNextSigningSticker(ticket, ) {

    const dispatch = useDispatch();
    const signing = useSelector(state => state.status.signing)
    const [sticker, setSticker] = useState(null)
    const [status, setStatus] = useState('new');
    const [lastStickerData, setLastStickerData] = useState(null)
    const {tasks} = useSelector(state => state.tasks)
    const {signableStickers, active: activeSticker} = useSelector(state => state.signableStickers);

    useEffect(() => {
        
        // if(!ticket) {
            setStatus('new');
            setSticker(null);
        // }

    }, [ticket])

    useEffect(() => {

        setStatus(s => {

            if(signing.working) {
                setSticker(null);
                if(activeSticker != null) {
                    setLastStickerData(signableStickers.find(ss => ss.id === activeSticker.id).sticker.data)
                }                
                return 'signing';
            } 
            
            if(s === 'signing' && tasks.length === 0) {
                return 'signed';    
            }

            return s
        })

    }, [signing, activeSticker, signableStickers, tasks.length])

    useEffect(() => {
        if(tasks.length === 0 && status === 'signed' && signableStickers.length > 0 && sticker === null) {
            if(signableStickers[0].sticker.data === lastStickerData) {
                setSticker(signableStickers[0])
            }            
        }

    }, [status, signableStickers, sticker, lastStickerData, tasks.length])

    const cancel = useCallback(() => {
        setStatus('new')
        setSticker(null);
    }, [])

    return [sticker, cancel]

}