const DB = () => {

    const pages = () => {

        const items = {}
        
        const put = ({id, data}) => {
            items[id] = data;
        }

        const get = (id) => {
            const item = items[id]
            return item ? 
            {
                id,
                data: items[id]
            } : 
            null
        }

        return {
            put, get
        }

    }

    return {
        pages: pages()
    }

}

const db = DB();

export default db;