import React, { useState, useEffect, useCallback } from 'react';
import { usePalette } from "./shared/Customizables";
import FlexColumn from "./shared/FlexColumn";
import RadioButton from './shared/RadioButton';
import { Form } from 'reactstrap';
import Header from './shared/Header';
import { useDispatch } from 'react-redux';
import { fetchCustomizables } from './redux/actions';
import RegexInput from './shared/RegexInput';
import SolidButton from './shared/SolidButton';
import HttpRequest from './shared/HttpRequest';


const DATA_TYPE_MAP = {
    sat: {
        label: 'RFC',
        regex: /^[a-zA-Z]{3,4}[0-9]{6}[a-zA-Z0-9]{3,5}$/g,
        transform: v => v.toUpperCase()
    },
    email: {
        label: 'Correo',
        regex: /^(.+)@(.+)\.(.+)$/g
    },
    sms: {
        label: 'Teléfono',
        regex: /^[0-9]{6,12}$/g
    }
}


const authorities = [
    {
        label: 'Correo',
        value: 'email',
        dataName: 'Correo',
        description: 'Se enviará una liga única a tu correo. Abre la liga para poder firmar' 
    }, {
        label: 'SMS',
        value: 'sms',
        dataName: 'Teléfono',
        description: 'Se enviará un SMS con una liga única a tu teléfono. Abre la liga para poder firmar'
    }, {
        label: 'SAT',
        value: 'sat',
        dataName: 'RFC',
        description: 'Firmarás con tu certificado del SAT (e.firma). Ten a la mano tus archivos .cer y .key para poder firmar'
    }
]

export default function DemoDocument() {

    const palette = usePalette();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        authority: 'email',
        data: '',
        url: null,
    })
    const [formState, setFormState] = useState('new')

    useEffect(() => {
        dispatch(fetchCustomizables())    
    }, [])

    function handleChangeAuthority(authority) {
        return function(event) {
            setState({...state, authority})
        }
    }

    const handleChangeData = useCallback((val) => {
        setState((state) => ({
            ...state, data: val
        }))
    }, [])

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        if(!state.data) {
            return;
        }
        setFormState('working')
        try {
            const response = await HttpRequest.post('/demo', state)
            setState({...state, url: response.data.document_url});
            setFormState('done')
        } catch(e) {
            setFormState('error')
        }
    }

    return (
        palette.waiting ? null :
        formState !== 'done' ? 
        <Form style={{display:'flex', marginTop:'80px', alignItems:'center', justifyContent: 'center'}} onSubmit={handleSubmit}>
            <FlexColumn style={{maxWidth:'500px'}}>
                <Header>Documento de prueba</Header>
                <br/>
                <p>
                    Genera un documento de prueba para que puedas ver lo fácil que es firmar un documento
                </p>
                <p>
                    Selecciona la autoridad que quieres utilizar. La autoridad nos indica qué datos 
                    debe validar una persona para poder firmar un documento.
                </p>
                {
                    authorities.map(a => (
                        <div key={a.label} >
                            <RadioButton
                            label={a.label}
                            checked={state.authority === a.value} 
                            value={a.value}
                            onChange={handleChangeAuthority(a.value)}></RadioButton>
                            <div style={{marginLeft:'40px'}}>{a.description}</div>
                        </div>
                    ))
                }
                <br/>
                <RegexInput
                    {...DATA_TYPE_MAP[state.authority]}
                    value={state.data}
                    onChange={handleChangeData}
                    required="true"></RegexInput>
                <br/>
                <SolidButton type="submit" disabled={formState !== 'new'}>Generar documento</SolidButton>
            </FlexColumn>
        </Form> : 
        <div style={{display:'flex', marginTop:'80px', alignItems:'center', justifyContent: 'center'}} >
            <FlexColumn style={{maxWidth:'400px', textAlign:'center'}}>
                <Header>¡Listo!</Header>
                <br/>
                {
                    state.authority === 'email' ?
                    <React.Fragment>
                        <p>Abre el correo que llegará a <b>{state.data}</b> y da click en la liga para ver tu documento.</p>
                        <p>Puede tardar unos minutos en llegar, si no lo encuentras, por favor revisa tu bandeja de spam.</p>
                    </React.Fragment>
                    : state.authority === 'sms' ? 
                    <p>Abre el SMS que llegará a <b>{state.data}</b> y da click en la liga para ver tu documento.</p> 
                    : <React.Fragment>
                        <p>Presiona el siguiente botón para ver tu documento</p>
                        <br/>
                        <SolidButton onClick={e => window.open(state.url, '_blank')}>Ver documento</SolidButton>
                    </React.Fragment>
                }
            </FlexColumn>
        </div>
    )

}