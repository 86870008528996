import React, {useState, useReducer, useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest';
import db from '../utils/Storage'
import {removeHtmlSignatureImgPage, hideUploadingDocument, changeDocument, setDocStatus} from '../redux/actions'
import { useSelector, useDispatch }  from 'react-redux';
import { REMOVE_HTML_UPDATE_PAGE_QUEUE } from '../redux/actionTypes';
import { wait } from '../utils/toolbox';

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

const initialState = {
    totalPages: 0,
    pages:[],
    css:'',
    docStatus: {
        status: 'CONVERTING',
        updatedAt: new Date().getTime()
    },
    ticket: null
}

const ACTIONS = {
    SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
    SET_CSS: 'SET_CSS',
    PAGE_LOADED: 'PAGE_LOADED',
    RESET_PAGES: 'RESET_PAGES',
    UPDATE_STORED_PAGE: 'UPDATE_STORED_PAGE',
    // SET_DOC_READY: 'SET_DOC_READY',
    CHANGE_DOCUMENT: 'CHANGE_DOCUMENT'
}

function reducer(state, action) {

    switch(action.type) {
        case ACTIONS.SET_TOTAL_PAGES: {
            const {totalPages} = action.payload;
            return {...state, totalPages, pages: new Array(totalPages)}
        }
        case ACTIONS.SET_CSS: {
            const {asset} = action.payload;            
            return {...state, css:b64DecodeUnicode(asset)}
        }
        case ACTIONS.PAGE_LOADED: {
            const {asset, page} = action.payload;
            const {updatedAt} = state.docStatus;
            

            const idx = page - 1;
            const id = `page.${idx}`
            db.pages.put({
                id, 
                idx,
                data: b64DecodeUnicode(asset)
            })
            
            // const newPages = [...state.pages];
            // newPages.splice(page - 1, 0, {id, idx, updatedAt: updatedAt})
            const newPages = state.pages.slice();
            newPages[idx] = {id, idx, updatedAt: updatedAt}
            return {...state, pages:newPages}
           
        }
        case ACTIONS.UPDATE_STORED_PAGE: {
            const {asset, idx, updatedAt} = action.payload;
        
            const id = `page.${idx}`

            db.pages.put({
                id, 
                data: b64DecodeUnicode(asset)
            })
            const pages = state.pages.map(p => {
                if(p.id === id) {
                    return {id, idx, updatedAt: updatedAt}
                }
                return p;
            })
            return {...state, pages}
        }
        // case ACTIONS.SET_DOC_READY: {
        //     const {exists} = action.payload;
        //     return {...state, docReady: exists}
        // }
        case ACTIONS.RESET_PAGES: {
            return {...state, pages:[]}
        }
        case ACTIONS.CHANGE_DOCUMENT: {
            return {
                ...initialState,
                ticket: action.payload.ticket
            }
        }
        default: {
            return state
        }
    }
}

export default function useDocument(initialTicket) {
    
    const [state, dispatch] = useReducer(reducer, initialState);
    const {docStatus, htmlUpdatePagesQueue, report} = useSelector(state => state.document);
    const reduxDispatch = useDispatch();
    const updatedAt = state.docStatus.updatedAt;

    useEffect(() => {
        if(report && report.deleted) {            
            dispatch({
                type: ACTIONS.RESET_PAGES
            })
            reduxDispatch(setDocStatus({
                status: 'DELETED',
                updatedAt: new Date()
            }))
        }
    }, [report])

    useEffect(() => {
        if(initialTicket !== state.ticket) {
            dispatch({
                type: ACTIONS.CHANGE_DOCUMENT,
                payload: {
                    ticket: initialTicket
                }
            })
            reduxDispatch(changeDocument())      
        }          
    }, [initialTicket, reduxDispatch, state.ticket])

    useEffect(() => {
        reduxDispatch(setDocStatus({status:'CONVERTING', updatedAt: null}))
    },[state.ticket, reduxDispatch])

    useEffect(() => {
        
        let timeout = null;
        async function checkDocStatus() {
           const response = await HttpRequest.get(`/document/${report.ticket}/exists`);
           
           if(response.data.status !== 'CONVERTING' && response.data.status !== 'ERROR') {
        
            reduxDispatch(setDocStatus(response.data))
           
            } else {
                timeout = setTimeout(() => {
                    checkDocStatus()
               }, 1000);
           }
        }                            
        
        if(docStatus.status === 'CONVERTING' && report.ticket) {
            checkDocStatus();
        }

        return () => {
            if(timeout) {
                clearTimeout(timeout);
            }
        }

    }, [report.ticket, docStatus, reduxDispatch])

    useEffect(() => {
        async function fetchTotalPages() {
            const response = await HttpRequest.get(`/document/${state.ticket}/pages`)
            dispatch({type:ACTIONS.SET_TOTAL_PAGES, payload: response.data})
        }
        async function fetchCss()  {
            const response = await HttpRequest.get(`/document/${state.ticket}/assets/style.css?format=json`)
            if(response.data && response.data.error) {
                return;
            }
            dispatch({type:ACTIONS.SET_CSS, payload: response.data})
        }

        if(docStatus.status === 'CONVERTED') {
            fetchTotalPages();
            fetchCss();
        }        
        

    }, [docStatus, state.ticket])

    useEffect(() => {

        let shouldReload = false;
        async function fetch(page) {
            const response = await HttpRequest.get(`/document/${state.ticket}/assets/${page}.html?format=json`);
            if(response.data.error === 'retrying conversion') {
                shouldReload = true
                await wait(1000)
                await fetch(page);
                return
            }
            if(shouldReload) {
                window.location.reload();
            }
            dispatch({type:ACTIONS.PAGE_LOADED, payload:{...response.data, page}})
        }
        
        for(let i = 0; i < state.totalPages; i++) {
            fetch(i + 1)
        }

    },  [state.totalPages, state.ticket])

    
    useEffect(() => {
        
        async function fetchCss()  {
            const response = await HttpRequest.get(`/document/${state.ticket}/assets/style.css?format=json&date=${new Date().getTime()}`)
            dispatch({type:ACTIONS.SET_CSS, payload: response.data})
        }

        async function fetch(page, pageUpdatedAt) {
            const response = await HttpRequest.get(`/document/${state.ticket}/assets/${page + 1}.html?format=json&date=${new Date().getTime()}`);
            dispatch({
                type:ACTIONS.UPDATE_STORED_PAGE, 
                payload:{
                    asset: response.data.asset,
                    idx: page,
                    updatedAt: pageUpdatedAt
                }
            })
        }

        /**
         * Hace un poll hasta que detecta que el servidor actualizo el documento html
         * y se trae de nuevo cada una de las paginas en el arreglo 
         * @param {*} pages 
         */
        let cancel = null;
        async function fetchWhenUpdated(updates) {
            if(!updates || updates.length === 0) {
                return;
            }            
            const pages = updates[0].pages;
            const response = await HttpRequest.get(`/document/${state.ticket}/exists?date=${new Date().getTime()}`);
           if(updatedAt && updatedAt <= response.data.updatedAt) {
               try {
                    await fetchCss();
                    
                    const fetching = [];
                    for(let p of pages) {
                        fetching.push(fetch(p, response.data.updatedAt));
                    }
                    await Promise.all(fetching)

                    reduxDispatch({
                        type: REMOVE_HTML_UPDATE_PAGE_QUEUE,
                        payload: {
                            idx: updates[0].idx,
                            updatedAt: response.data.updatedAt
                        }
                   })                                                                  
               } catch(e) {
                   cancel = setTimeout(() => {
                        fetchWhenUpdated(updates);
                   }, 5000)
               }            
           } else {               
               cancel = setTimeout(() => {
                    fetchWhenUpdated(updates);
               }, 5000)
           }
        }

        cancel = setTimeout(() => {
            
            if(report.deleted) {
                return;
            }
            
            if(report.blockDownload && report.allSigned) { 
                return;
            }

            fetchWhenUpdated(htmlUpdatePagesQueue);
           }, 5000)

        return () => {
            if(cancel) {
                clearTimeout(cancel);
            }
        }

    }, [htmlUpdatePagesQueue, updatedAt, state.ticket, report, reduxDispatch])


    return [docStatus, state]

}
