import {ADD_SIGNABLE_STICKER, SET_ACTIVE_STICKER, SET_ACTIVE_STICKER_IMAGE, RESET_STATE, CLEAR_ACTIVE_STICKER, SET_NEXT_SET_DOCUMENT, CHANGE_DOCUMENT, SET_SIGNABLE_STICKERS, SET_PIN} from '../actionTypes'

const initialState = {
    signableStickers: [],
    nextSetDocument: null,
    active: null,
    stickersLoaded: false,
    pin: null,
    activeSignerFound: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_PIN: {
            const {pin} = action.payload;
            return {
                ...state,
                pin
            }
        }
        case SET_NEXT_SET_DOCUMENT: {
            const {nextSetDocument} = action.payload;
            return {
                ...state,
                nextSetDocument
            }
        }
        case SET_SIGNABLE_STICKERS: {
            const {signableStickers, nextSetDocument, activeSignerFound} = action.payload;
            return {
                ...state,
                signableStickers,
                nextSetDocument,
                stickersLoaded: true,
                activeSignerFound
            }
        }
        case ADD_SIGNABLE_STICKER: {
            const {id, sticker} = action.payload;
            return {
                ...state,                
                signableStickers: [
                    ...state.signableStickers, {id, sticker}
                ]
            }
        }
        case SET_ACTIVE_STICKER: {
            const {id, status, img, singleUse} = action.payload;
            return {
                ...state,
                active: {id, status, img, singleUse}
            }
        }
        case SET_ACTIVE_STICKER_IMAGE: {
            const {img} = action.payload;
            return {
                ...state,
                active: {
                    ...state.active,
                    img
                }
            }
        }
        case CLEAR_ACTIVE_STICKER: {
            return {
                ...state,
                active: null
            }
        }
        case CHANGE_DOCUMENT: {
            return initialState
        }
        case RESET_STATE: {
            return {
                ...initialState,
                pin: state.pin,
                nextSetDocument: state.nextSetDocument
            };
        }
        default: {
            return state;
        }
    }
}