import React, {useReducer, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import ResponsiveSheetModal from './ResponsiveSheetModal'
import FlexColumn from './FlexColumn';
import FlexRow from './FlexRow';
import styles from './SignersReportDesktop.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCheckCircle, faUserCheck, faChevronLeft, faEnvelope, faComments, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import SwipeableViews from "react-swipeable-views";
import CertificateReportModal from './CertificateReportModal'
import {IS_MOBILE} from '../Consts'
import SolidButton from './SolidButton';
import HttpRequest from './HttpRequest';
import { usePalette } from './Customizables';
import CustomModalHeader from './CustomModalHeader';
import { parseDate } from '../utils/toolbox';
import i18n from '../i18n';
import useUniversalAvailable from "../hooks/useUniversalAvailable";


const ValidCertTypes = {
    IDENTITY:'IDENTITY',
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
    SPEI: 'SPEI',
    EMAIL_LINK: 'EMAIL_LINK',
    SMS_LINK: 'SMS_LINK',
    EMAIL_PIN: 'EMAIL_PIN',
    SMS_PIN: 'SMS_PIN',
    FIREBASE: 'FIREBASE'
}


function isNomSignature({signature}) {
    return signature?.campoFirma?.indexOf('NOM 151') === 0;
}

function isSAT(issuer) {
    return issuer === 'AUTORIDAD CERTIFICADORA' 
        || issuer === 'A.C. del Servicio de Administración Tributaria'
}

function isCecobanTimestamp(authority) {
    return authority && authority.includes('L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151');
}

function buildSigntureBadge({certType, validSignature, ...signer}) {

    const signerCert = signer.certificados && signer.certificados.length > 0 ? signer.certificados[0] : null;

    if(!validSignature) {
        return {
            icon: 'error',
            icon2: '',
            text: <div>{i18n.t('notValidSignature')}</div>,
            color: 'red', 
            expirationDate: signerCert ? signerCert.expirationDate : '',
            signatureDate: signer.signDate,
            timestampAuthority: signer.timestampAuthority || <div>{i18n.t('none')}</div>,
            ocsp: signer.ocsp ? {
                includes: true,            
                valid: signer.ocsp.signatureValid ? i18n.t('valid') : i18n.t('notValid'),
                status: signer.ocsp.certStatus === 'good' ? i18n.t('valid') : i18n.t('revoked'),
                signer: signer.ocsp.signer
            }: <div>{i18n.t('containsOCSP')}</div>,
            certificates: signer.certificados.map((c, i) => ({
                ...c,
                label: i === 0 ? i18n.t('serialNumber') :
                    i === signer.certificados.length - 1 ? i18n.t('certSerialNumber') : i18n.t('cerIntSerialNumber')
            }))
        }
    }

    
    let result = {
        icon: <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>,
        color: 'validColor',
        email: signerCert.email,
        isSAT: isSAT(signerCert.issuerCommonName),
        issuerName: signerCert.issuerCommonName,
        expirationDate: signerCert.expirationDate,
        signatureDate: signer.signDate,
        isCecobanTimestamp: isCecobanTimestamp(signer.timestampAuthority),
        timestampAuthority: signer.timestampAuthority || <div>{i18n.t('none')}</div>,        
        ocsp: signer.ocsp ? {
            includes: true,            
            valid: signer.ocsp.signatureValid ? i18n.t('valid') : i18n.t('notValid'),
            status: signer.ocsp.certStatus === 'good' ? i18n.t('valid') : i18n.t('revoked'),
            signer: signer.ocsp.signer
        } : <div>{i18n.t('containsOCSP')}</div>,
        certificates: signer.certificados.map((c, i) => ({
            ...c,
            label: i === 0 ? i18n.t('serialNumber') :
                i === signer.certificados.length - 1 ? i18n.t('certSerialNumber') : i18n.t('cerIntSerialNumber')
        }))
    };

    if(signer.ipAddress) {
        result = {
            ...result,
            ipAddress: signer.ipAddress
        }
    }

    if(signer.latLng) {
        result = {
            ...result,
            latLng: signer.latLng
        }
    }
    
    switch(certType) {
        case ValidCertTypes.IDENTITY: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faUserCheck}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModified')}</div>               
            }
            break;
        }
        case ValidCertTypes.EMAIL: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedEmail')}</div>  
            }
            break;
        }
        case ValidCertTypes.SPEI: {
            result = {
                ...result,
                icon2: 'email',
                text: <div>{i18n.t('notModifiedSPEI')}</div>
            }
            break;
        }
        case ValidCertTypes.PHONE: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faComments}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedSMS')}</div>
            }
            break;
        }
        case ValidCertTypes.EMAIL_LINK: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedLinkEmail')}</div>
            }
            break;
        }
        case ValidCertTypes.EMAIL_PIN: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedPinEmail')}</div>
            }
            break;
        }
        case ValidCertTypes.SMS_LINK: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faComments}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedLinkSMS')}</div>
            }
            break;
        }
        case ValidCertTypes.SMS_PIN: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faComments}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedPinSMS')}</div>
            }
            break;
        }
        case ValidCertTypes.FIREBASE: {
            result = {
                ...result,
                icon2: <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>,
                text: <div>{i18n.t('notModifiedFirebase')}</div>
            }
            break;
        }
        case 'NONE': {
            result = {
                ...result,
                icon: <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>,
                color: 'gold',
                text: <div>{i18n.t('notModifiedNotice')}</div>    
            };
            break;
        }
        default: {           
        }
    }    

    return result 
}

const SLIDER_INDEX = {
    SIGNERS: 0,
    DETAIL: 1
}

const initialReportState = {
    currentSigner: null,
    validationInfo: {},
    sliderIndex: SLIDER_INDEX.SIGNERS,
}

function reducer(state, action) {
    switch(action.type) {
        case 'SET_SIGNER': {
            const {signature, index} = action.payload;
            
            if(signature) {
                return {
                    currentSigner: {
                        signature, index
                    },
                    sliderIndex: SLIDER_INDEX.DETAIL,
                    validationInfo: buildSigntureBadge(signature)
                }                
            } 
            return {
                currentSigner: signature,
                sliderIndex: SLIDER_INDEX.SIGNERS,
                validationInfo: {}
            }
        }
        case 'INDEX_CHANGE': {
            const {index} = action.payload;
            return {
                ...state,
                sliderIndex: index  
            }   
        }
        default: {
            return state;
        }
    }
}

function Nom151Row({signature, ticket}) {
    return (
        <FlexRow flexWrap="nowrap">
            <img src={`${HttpRequest.backend}/document/${ticket}/nom151Logo`}
                    alt="logo nom151"
                    style={{
                        width:'30px',
                        margin:'8px'
                    }}></img>
            <FlexColumn>
                <span className={styles.signerName}>{i18n.t('timestampNOM151')}</span>
                <span>Cecoban PSC</span>
            </FlexColumn>                                    
            <FontAwesomeIcon icon={faChevronRight} className={styles.button}></FontAwesomeIcon>                                    
        </FlexRow>
    )
}

function SignerRowBadge({signature}) {

    const [icon, setIcon] = useState(null);

    useEffect(() => {

        if(signature) {

            if(signature.validSignature) {

                if(signature.certType === 'NONE') {
                    setIcon({
                        icon: faExclamationCircle,
                        style: {
                            color: 'gold'
                        }
                    })
                } else {
                    setIcon({
                        icon: faCheckCircle,
                        style: {}
                    })
                }

            } else {
                setIcon({
                    icon: faTimesCircle,
                    style: {
                        color: 'red'
                    }
                })
            }

        } else {
            setIcon(null)
        }

    }, [signature])

    return (
        icon !== null ? 
        <FontAwesomeIcon {...icon}></FontAwesomeIcon>
        : null
    )

}

function SignerRow({signature, palette}) {
    return (
        <FlexRow flexWrap="nowrap">
            {
                signature.certificados[1] && isSAT(signature.certificados[1].commonName) && IS_MOBILE ?
                null : 
                <div className={styles.valid} style={{color: palette.validColor}}>
                    <SignerRowBadge signature={signature}></SignerRowBadge>
                </div>
            }                                        
            {
                signature.certificados[1] && isSAT(signature.certificados[1].commonName) ?
                <img src={`${process.env.REACT_APP_CDN_PATH}/images/sat_logo.png`}
                    alt="logo sat"
                    style={{
                        width:'30px',
                        margin:'8px'
                    }}></img> : null
            }
            <FlexColumn>
                <span className={styles.signerName}>{signature.certificados[0].commonName}</span>
                <span style={{wordBreak:'break-word'}}>{parseDate(signature.signDate)}</span>
            </FlexColumn>                                    
            <FontAwesomeIcon icon={faChevronRight} style={{color:palette.secondaryColor}}></FontAwesomeIcon>                                    
        </FlexRow>
    )
}

function Nom151Body({timestampInfo, timestampIndex, ticket, universalNom151Available}) {

    function handleClickDownloadStamped(documentType) {
        return function() {
            window.open(`${HttpRequest.backend}/nom151Timestamp/${ticket}/${timestampIndex}/${documentType}/zip`, "_blank");
        }
    }

    if(!timestampInfo) {
        return (
            <FlexColumn>
                {i18n.t('noInfoAboutTimeStamp')}
            </FlexColumn>
        )
    }

    return (
        <FlexColumn>
            <div className={styles.detailSection}>
                <span className={styles.detailTitle}>TSA</span><br/>
                <FlexRow flexWrap="nowrap">
                    <img src={`${HttpRequest.backend}/document/${ticket}/nom151Logo`}
                        alt="logo nom151"
                        style={{
                            width:'40px',
                            margin:'10px'
                        }}></img>
                    {timestampInfo.tsa}
                </FlexRow>                
            </div>
            <div className={styles.detailSection}>
                <span className={styles.detailTitle}>{i18n.t('date')}</span><br/>
                {timestampInfo.genTime ? new Date(timestampInfo.genTime).toString() : null}
            </div>
            <SolidButton onClick={handleClickDownloadStamped('original')}>{i18n.t('downloadOriginal')}</SolidButton>
            {
                universalNom151Available && <SolidButton onClick={handleClickDownloadStamped('universal')}>{i18n.t('downloadUniversal')}</SolidButton>
            }
        </FlexColumn>
    )
}

function SignerBody({validationInfo, onClickCert, ticket}) {
    return (
        <FlexColumn>
            {/* <div className={styles.detailSection}>
                {validationInfo.email}
            </div> */}
            <div className={styles.detailSection}>                                            
                <span className={styles.detailTitle}>{i18n.t('issuer')}</span><br/>
                {
                    validationInfo.isSAT ?
                    <img src={`${process.env.REACT_APP_CDN_PATH}/images/sat_logo.png`}
                        alt="logo de sat"
                        style={{
                            width:'40px',
                            margin:'10px'
                        }}></img> : null
                }
                {validationInfo.issuerName}
            </div>
            {
                validationInfo.ipAddress ?
                <div className={styles.detailSection}>
                    <span className={styles.detailTitle}>{i18n.t('ipAddress')}</span><br/>
                    {validationInfo.ipAddress}
                </div> : null
            }
            {
                validationInfo.latLng ?
                <div className={styles.detailSection}>
                    <span className={styles.detailTitle}>{i18n.t('latLng')}</span><br/>
                    {validationInfo.latLng}
                </div> : null
            }
            <div className={styles.detailSection}>
                <span className={styles.detailTitle}>{i18n.t('date')}</span><br/>
                {parseDate(validationInfo.signatureDate)}
            </div>
            <div className={styles.detailSection}>
                <span className={styles.detailTitle}>{i18n.t('OCSP')}</span><br/>
                {
                    validationInfo.ocsp.includes ? 
                    <React.Fragment>
                        <span>{`${i18n.t('signature')}: ${validationInfo.ocsp.valid}`}</span><br/>
                        <span>{i18n.t('certificateStatus')}: {validationInfo.ocsp.status}</span><br/>
                        <span>{i18n.t('signedBy')}: {validationInfo.ocsp.signer}</span>
                    </React.Fragment> :
                    <div>
                        {validationInfo.ocsp}
                    </div>
                }
            </div>
            <div className={styles.detailSection}>
                <span className={styles.detailTitle}>{i18n.t('expirationDate')}</span><br/>
                {parseDate(validationInfo.expirationDate)}
            </div>            
            <div className={styles.detailSection}>
                <span className={styles.detailTitle}>{i18n.t('authorityDate')}</span><br/>
                <FlexRow flexWrap="no-wrap">
                {
                    validationInfo.isCecobanTimestamp ?
                    <img src={`${HttpRequest.backend}/document/${ticket}/nom151Logo`}
                        alt="logo nom151"
                        style={{
                            width: '30px',
                            margin: '10px'
                        }}></img> : null
                }
                <div>{validationInfo.timestampAuthority}</div>
                </FlexRow>
            </div>            
            {
                validationInfo.certificates.map((c,i) => (
                    <div key={i} className={styles.detailSection}>
                        <span className={styles.detailTitle}>{c.label}</span><br/>
                        <span className={styles.serial}
                            onClick={onClickCert(c)}>{c.serial}</span>
                    </div>
                ))
            }
        </FlexColumn>
    )
}

export default function SignersReport({isOpen, onClose, selectedSigner, onSignerSelected}) {

    
    const report = useSelector(state => state.document.report);
    const [universalAvailable, universalNom151Available] = useUniversalAvailable();
    const [{currentSigner, validationInfo, sliderIndex}, dispatch] = useReducer(reducer, initialReportState);
    const [cert, setCert] = useState(null)
    const swiperRef = useRef(null)
    const [lastY, setLastY] = useState(null)
    const palette = usePalette();

    useEffect(() => {
        
        let index = 
            selectedSigner ?
            report.firmas.findIndex(f => f.campoFirma === selectedSigner.campoFirma) : 0

        dispatch({
            type: 'SET_SIGNER',
            payload: {
                signature:selectedSigner,
                index
            }
        })
    }, [selectedSigner])


    function handleClickSigner(signer) {
        return function() {
            onSignerSelected(signer);
        }
    }


    function handleCloseClick() {
        if(currentSigner) {
            onSignerSelected(null)
        } else {
            onClose();
        }
    }

    function handleClickCert(cert) {
        return function() {
            setCert(cert);
        }
    }

    function handleCloseCert() {
        setCert(null)
    }

    function handleViewScroll(e) {        

        if(e.type === 'scroll') {
            // esta haciendo scroll, deten el evento ahi
            e.stopPropagation();
            return;
        }

        if(!lastY) {
            // no se ha hecho scroll antes, guarda el valor actual y deten el evento
            setLastY(e.touches[0].clientY)
            e.stopPropagation();
            return;
        }        
        
        const currentY = e.touches[0].clientY;
        if(currentY > lastY && swiperRef.current.rootNode.scrollTop === 0) {
            // se esta arrastrando el dedo hacia abajo (scroll hacia arriba) 
            // ya esta hasta arriba (scroll 0), deja pasar el evento al bottomsheet
            e.preventDefault();
            return;  
        } 

        // guarda el valor y deten el evento
        e.stopPropagation();
        setLastY(currentY)
    }

    function handleIndexChange(index) {
        if(index === 0) {
            onSignerSelected(null)
        }
        dispatch({
            type: 'INDEX_CHANGE',
            payload: {
                index
            }
        })
    }

    function signatureIndexToNomIndex(signatureIndex) {
        // the signature index is the index of the signature in the report.firmas array
        // the nom index tells us if the signature is the 1st, 2nd, or n item in the nom151 array

        let nomIndex = 0;
        for(let i = 0; i < signatureIndex; i++) {
            if(isNomSignature({signature: report.firmas[i]})) {
                nomIndex++;
            }
        }

        return nomIndex;

    }

    function getTimestampInfoFromReport(index) {
        if(!report.nom151Timestamps && report.nom151Timestamp) {
            return report.nom151Timestamp;
        }

        return report.nom151Timestamps[signatureIndexToNomIndex(currentSigner.index)]
    }

    return (
        <React.Fragment>
            <ResponsiveSheetModal isOpen={isOpen} onClose={onClose}
                mobile={{
                    height: window.innerHeight - 50,
                    fullScreen: !!selectedSigner,
                    header:  
                        currentSigner ? 
                        // hay un firmante seleccionado
                        isNomSignature(currentSigner) ?
                            // el firmante actual es una estampilla nom151
                                <span>{i18n.t('timestampNOM151')}</span> :
                            // el firmante es una persona, mostramos el nombre en el certificado
                            <FlexRow justifyContent="space-between">
                                <FontAwesomeIcon icon={faChevronLeft} onClick={handleCloseClick}></FontAwesomeIcon>
                                <div>{currentSigner.signature.certificados[0].commonName}</div>
                            </FlexRow> : 
                        // no hay ningun firmante seleccionado
                        <span>{i18n.t('signers')}</span>
                }}
                desktop={{
                    header: (
                    <CustomModalHeader toggle={handleCloseClick} className={styles.header}>
                        {
                            currentSigner ? 
                                // hay un firmante seleccionado
                                isNomSignature(currentSigner) ?
                                    // el firmante actual es una estampilla nom151
                                    <span>{i18n.t('timestampNOM151')}</span> :
                                    // el firmante es una persona, mostramos el nombre en el certificado
                                    currentSigner.signature.certificados[0].commonName : 
                                // no hay ningun firmante seleccionado
                                <span>{i18n.t('signers')}</span>
                        }
                    </CustomModalHeader>)
                }}>
                <SwipeableViews 
                    disabled={sliderIndex === SLIDER_INDEX.SIGNERS} 
                    index={sliderIndex} 
                    style={{height: IS_MOBILE ? window.innerHeight - 140 : null }}
                    onChangeIndex={handleIndexChange}
                    onScroll={handleViewScroll}                    
                    onTouchMove={handleViewScroll}
                    ref={swiperRef}>
                    <FlexColumn>
                        {
                            report.firmas.length === 0 ?
                            <p style={{textAlign:'center', fontWeight:'bold', margin:'30px'}}>
                                {i18n.t('noSignatures')}
                            </p> :
                            report.firmas.map(s => (
                                <div key={s.campoFirma} className={styles.signer} onClick={handleClickSigner(s)} style={{background: palette.contentBackground}}>
                                    {
                                        isNomSignature({signature:s}) ? 
                                        <Nom151Row signature={s} ticket={report.ticket}></Nom151Row> :
                                        <SignerRow signature={s} palette={palette}></SignerRow>
                                    }                                    
                                </div>
                            ))
                        }
                    </FlexColumn>
                    <FlexColumn>
                        {
                            currentSigner ? 
                            <React.Fragment>
                                <div style={{
                                    backgroundColor:validationInfo.color === 'validColor' ? palette.validColor : validationInfo.color, 
                                    color:'white', 
                                    fontWeight:'bold'}}>
                                    <FlexRow className={styles.detailContent} flexWrap='nowrap'>
                                        <div className={styles.validationBarIcon}>
                                            {validationInfo.icon}
                                        </div>
                                        <div className={styles.validationBarIcon}>
                                            {validationInfo.icon2}
                                        </div>
                                        <div className={styles.validationBarText}>
                                            {
                                                isNomSignature(currentSigner) ?
                                                <div>{i18n.t('protectedDocument')}</div> :
                                                validationInfo.text
                                            }
                                        </div>
                                    </FlexRow>
                                </div>
                                <div className={styles.detailContent}>
                                    {
                                        isNomSignature(currentSigner) ?
                                        <Nom151Body
                                            universalNom151Available={universalNom151Available}
                                            timestampInfo={getTimestampInfoFromReport(currentSigner.index)} 
                                            timestampIndex={signatureIndexToNomIndex(currentSigner.index)}
                                            ticket={report.ticket}></Nom151Body> :
                                        <SignerBody validationInfo={validationInfo} onClickCert={handleClickCert} ticket={report.ticket}></SignerBody>
                                    }                                    
                                </div>
                            </React.Fragment> : null
                        }
                    </FlexColumn>
                </SwipeableViews>            
            </ResponsiveSheetModal>
            <CertificateReportModal cert={cert} onClose={handleCloseCert}></CertificateReportModal>
        </React.Fragment>
    )

}