import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HttpRequest from "../shared/HttpRequest";

export default function useAttachments() {

    const [status, setStatus] = useState(null)
    const [hasAttachments, setHasAttachments] = useState(false)
    const [fileNames, setFileNames] = useState(null)
    const {report} = useSelector(state => state.document);

    useEffect(() => {

        setHasAttachments(report.hasAttachments);

    }, [report.ticket])

    async function downloadAttachments() {


        setStatus('starting attachments download')
        const response = await HttpRequest.get(`/pdf/attachments/${report.ticket}`);
        if (response.status === 200) {

            const fileNames = response.data;

            if(report.autoDownloadAttachments === false) {
                setFileNames(fileNames);
                setStatus('attachments download')
                return;
            }

            let blocked = false;

            for(let fileName of fileNames) {
                const opened = window.open(`${HttpRequest.backend}/pdf/attachments/${report.ticket}/${fileName}`, '_blank');
                if(!opened) {
                    setStatus("blocked download of atachments")
                    blocked = true;
                    break;
                }
            }

            setFileNames(fileNames);

            if(!blocked) {
                setStatus('finished attachments download')
            }            
            
        } else {
            setStatus('error downloading attachments')
        }
    }

    return [hasAttachments, status, fileNames, downloadAttachments, report.autoDownloadAttachments]

}