import forge from "../crypto/forge";
import queryString from 'query-string';

function logAndReturn(data) {
    // console.log(data);
    return data;
}

function signatureCount(report) {
    const count = report.firmas.length;
    switch(count) {
        case 0: return '';
        case 1: return '(1 firma)';
        default: return `(${count} firmas)`;
    }
}

async function retryPromiseUntilSuccess(promiseFunc, params) {
    try {
        return await promiseFunc(params)
    } catch(e) {
        return await retryPromiseUntilSuccess(promiseFunc, params)
    }    
}

function b64ToBinary(data) {
    var binary_string = window.atob(data);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
};

function arrayBufferToB64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

function blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
        var fileReader = new FileReader();
        fileReader.onload = function () {
            resolve(this.result);
        };
        fileReader.readAsArrayBuffer(blob);
    })    
};

function appendToArray(array, extra) {
    if(extra) {
        const result = new Uint8Array(array.byteLength + extra.byteLength);
        result.set(new Uint8Array(array), 0);
        result.set(new Uint8Array(extra), array.byteLength)
        return result;
    }    
    return array;
}

function b64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
        type: contentType
    });
    return blob;
};

function blobToB64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.addEventListener('load', function() {
            resolve(reader.result.split('base64,')[1])
        })
        reader.readAsDataURL(blob);
    })
}

function _waitFor(selectorFunc, callback) {
    const elem = selectorFunc();
    if(!elem) {
        setTimeout(() => {
            _waitFor(selectorFunc, callback);
        }, 1000);
        return;
    }
    callback(elem);
}

function waitForElem(selectorFunc) {
    return new Promise((resolve, reject) => {
        _waitFor(selectorFunc, resolve);
    })
}

function sha256Hash(data) {    
    const md = forge.md.sha256.create();
    md.update((new Uint8Array(data).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
    }, '')));
    const digest = md.digest();
    return forge.util.encode64(digest.data);
}

function checkStickerOrder({ordered, nextSigner}, sticker) {
    if(ordered) {
        if(nextSigner === sticker.order) {
            return true;
        }                    
    } else {
        return true;
    }
    return false;
}

function buildQueryParams(params, remove) {
    const parsed = queryString.parse(window.location.search);
    if(remove) {
        for(let p of remove) {
            delete parsed[p];
        }
    }   

    return '?' + queryString.stringify({
        ...parsed,
        ...params
    })
}


function goToApp1() {
    const ticket = window.location.href.split('/')[4];
    let searchParams = window.location.search;
    searchParams = searchParams.includes('source=app2') ? searchParams : `?source=app2&${searchParams.replace('?', '')}`;
    const host = window.app1Host || process.env.REACT_APP_APP1;
    window.location.href = `https://${host}/pdf/${ticket}/${searchParams}`
}

function ellipsisTrimmer(string, maxLength) {
    if (string.length > maxLength) {
        return string.slice(0, (maxLength / 2) - 3) + "..." + string.slice(string.length - maxLength / 2);
    } else { return string }
}

function isEven(num) {
    return num % 2 === 0;
}

function parseDate(d) {
    try {
        return new Date(d).toString()
    } catch(e) {
    }
    return d;
}

/**
 * 
 * Esta funcion se requiere para manejar un error en el cambio del conversor
 * de html a imagenes. Cuando el conversor era a html, las paginas 
 * iniciaban en 1, cuando se paso a imagenes, inician en 0
 * Por lo que los documentos dados de alta antes del cambio se renderizan
 * incorrectamente (no se les puede dar click a las firmas, stickers, etc)
 * 
 * La diferencia entre los documentos procesados antes y despues
 * del cambio al conversor es la siguiente
 * 
 * Antes de cambio (HTML):
 * <div class="Page" id="page.0">
 *   <div id="pf1" class="pf w0 h0" data-page-no="1">
 * 
 * Despues de cambio (IMG):
 * <div class="Page" id="page.0">
 *   <div id="pf0" class="frmx1 pf" data-page-no="1" style="width:612px;height:792px;background-image: url('https://storage.googleapis.com/static.firmamex.com/2.gif');
                background-repeat: no-repeat;
                background-position: center;">

    Despues del cambio, el indice en los ids page.n y pfn es el mismo
 * 
 * @param {pagina comenzando desde 1} page 
 */
function getPageLayer(page) {
    let pageLayer = document.getElementById(`pf${page - 1}`)    
    // debugger
    if(pageLayer && pageLayer.parentElement.id === `page.${page - 1}`) {
        // existe con indice IMG y su contenedor tiene el mismo indice
        return pageLayer;
    }

    // significa que o no ha cargado la pagina o que se debe buscar la version HTML
    pageLayer = document.getElementById(`pf${page}`)    
    if(pageLayer && pageLayer.parentElement.id === `page.${page - 1}`) {
        // existe con indice HTML y su contenedor contiene el indice anteriro
        return pageLayer;
    }

    const pageContainer = document.getElementById(`page.${page - 1}`);
    if(pageContainer) {
        for(let c of pageContainer.children) {
            if(c.tagName === 'DIV' && c.className.includes('pf')) {
                return c;
            }
        }
    }

    return null;
} 

function wait(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms)
    })
}


export {
    wait,
    parseDate,
    buildQueryParams,
    retryPromiseUntilSuccess,
    appendToArray,
    b64ToBinary,
    arrayBufferToB64,
    blobToArrayBuffer,
    b64ToBlob,
    blobToB64,
    signatureCount,
    waitForElem,
    sha256Hash,
    checkStickerOrder,
    ellipsisTrimmer,
    isEven,
    logAndReturn,
    getPageLayer
}