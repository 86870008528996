import { SET_DOCUMENT, SET_REPORT, ADD_HTML_SIGNATURE_IMG, SET_LOCAL_SIGNATURES, REMOVE_HTML_SIGNATURE_PAGE,
    ADD_HTML_UPDATE_PAGE_QUEUE, REMOVE_HTML_UPDATE_PAGE_QUEUE, CHANGE_DOCUMENT } from '../actionTypes'

const initialState = {
    document: null,
    localSignatures: null,
    report: {
        document: {},
        dirty: true,
        boxes:[],
        stickerPages: [],
        signaturePages: [],
        firmas: [],
	deleted: false
    },
    docStatus: {
        status: 'CONVERTING',
        updatedAt: null
    },
    htmlUpdatePagesQueue:[],
    htmlSignatureImgPages:[]
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_DOCUMENT: {
            const {document} = action.payload;
            return {
                ...state,
                document
            }
        }
        case 'SET_DOC_STATUS': {
            const {status, updatedAt} = action.payload;
            return {
                ...state,
                docStatus: {
                    status, 
                    updatedAt: new Date().getTime()
                }      
            }
        }
        case SET_LOCAL_SIGNATURES: {
            const {localSignatures} = action.payload;
            return {
                ...state,
                localSignatures
            }
        }
        case SET_REPORT: {
            const {report} = action.payload;
            return {
                ...state,
                report: {
                    document: {},
                    ...report,
                    dirty: false
                }
            }
        }
        case ADD_HTML_SIGNATURE_IMG: {
            const {page} = action.payload;
            const htmlSignatureImgPages = [...state.htmlSignatureImgPages];            
            if(!htmlSignatureImgPages.find(p => p.page === page)) {
                htmlSignatureImgPages.push({page: page, signatures:[]})
            }
            const sp = htmlSignatureImgPages.find(p => p.page === page);
            sp.signatures.push(action.payload);
            return {
                ...state,
                htmlSignatureImgPages: [...htmlSignatureImgPages]
            }
        }
        case REMOVE_HTML_SIGNATURE_PAGE: {
            const {page} = action.payload;
            return {
                ...state,
                htmlSignatureImgPages: state.htmlSignatureImgPages.filter(p => p.page !== page)
            }
        }
        case ADD_HTML_UPDATE_PAGE_QUEUE: {
            const {update} = action.payload;
            return {
                ...state,
                htmlUpdatePagesQueue: [...state.htmlUpdatePagesQueue, update]
            }
        }
        case REMOVE_HTML_UPDATE_PAGE_QUEUE: {
            const {idx, updatedAt} = action.payload;
            return {
                ...state,
                htmlUpdatePagesQueue: state.htmlUpdatePagesQueue.filter(p => p.idx !== idx),
                updatedAt
            }
        }
        case CHANGE_DOCUMENT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
