import React, {Suspense, useEffect} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {Provider} from 'react-redux';
import store from './redux/store'
import DemoDocument from './DemoDocument';
import FirmamexApp from './FirmamexApp';
import { loadConfig } from './redux/actions';
import ErrorScreen from './ErrorScreen';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


function App() {  


  useEffect(() => {

    function removeZoom(event) {
        if(event.touches.length > 1) {
            event.preventDefault();
            event.stopPropagation(); // maybe useless
        }
    }

    document.addEventListener("touchstart", removeZoom, {passive: false});
    store.dispatch(loadConfig())

    return () => {
      document.removeEventListener('touchstart', removeZoom)
    }    

  }, [])


  return (
    <Suspense fallback={<div></div>}>
      <Provider store={store}>      
        <Router>
          <Switch>
              <Route path="/demo" component={DemoDocument}></Route>              
              <Route path="/" component={FirmamexApp}></Route>
          </Switch>
          {/* <Suspense fallback={<div>Cargando...</div>}>
                <Route path="/pdf/:ticket/signup" component={props =>
                    <Signup {...props}></Signup>
                }></Route>
            </Suspense>
            <Route exact path="/form/:uuid" component={FormRedirect}></Route>
            <Suspense fallback={<div>Cargando...</div>}>
                <Route path="/form/i/:ticket" component={Forms}></Route>
            </Suspense>
            <LoadingTasks></LoadingTasks>
            <TaskModal></TaskModal>             */}
        </Router>   
    </Provider>  
    </Suspense>
  );
}

export default App;
