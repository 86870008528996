import React, { useEffect, useReducer, useCallback, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../shared/Spinner'
import Signature from '../crypto/Signature';
import { setSigning, addHtmlSignatureImg, loadReport, clearActiveSticker, addTask, removeTask, setLocalSignaures, logout, loadOAuthCertificate } from '../redux/actions'
import { withRouter } from 'react-router-dom'
import SolidButton from './SolidButton';
import { appendToArray, signatureCount, wait } from '../utils/toolbox'
import useToggle from '../hooks/useToggle';
import PasswordModal from './PasswordModal';
import HttpRequest from './HttpRequest';
import { usePalette, ImgSuccess } from './Customizables';
import queryString from 'query-string';
import { saveAs } from 'file-saver'
import UserFeedbackForm from './UserFeedbackForm';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { useTranslation } from 'react-i18next';
import GeoLocateOverlay from './GeoLocateOverlay';
import useGeoLocate from '../hooks/useGeoLocate';
import useAttachments from '../hooks/useAttachments';
import useHasAccount from '../hooks/useHasAccount';
import FlexColumn from './FlexColumn'

const MODAL_STATUS = {
    NEW: 'NEW',
    SIGNING: 'SIGNING',
    SIGNED: 'SIGNED',
    OCSP_ERROR: 'OCSP_ERROR',
    OCSP_INVALID_FIRMAMEX: 'OCSP_INVALID_FIRMAMEX',
    OCSP_INVALID_PSC: 'OCSP_INVALID_PSC',
    DOC_CHANGED: 'DOC_CHANGED',
    ALREADY_SIGNED: 'ALREADY_SIGNED',
    RELOAD_ERROR: 'RELOAD_ERROR',
    FIREBASE_SESSION_ERROR:'FIREBASE_SESSION_ERROR',
    FIREBASE_TOKEN_ERROR: 'FIREBASE_TOKEN_ERROR',
    OCSP_TIMESTAMP_ERROR: 'OCSP_TIMESTAMP_ERROR'
}

const initialState = {
    status: MODAL_STATUS.NEW,
    messages: ['Cargando'],
    currentSignatures: 0
}

function modalReducer(state, action) {
    switch (action.type) {
        case 'RETRY': {
            return {
                status: MODAL_STATUS.NEW,
                messages: ['Cargando'],
                currentSignatures: action.payload.currentSignatures
            }
        }
        case 'SET_AS_SIGNING': {
            return {
                ...state,
                status: MODAL_STATUS.SIGNING
            }
        }
        case 'ADD': {
            const { message } = action.payload;
            if(message === 'ocsp_retry') {
                return {
                    ...state,
                    messages: ['Reintentando por error externo de OCSP']
                }
            }
            if (message === 'Firmado') {
                return {
                    status: MODAL_STATUS.SIGNED,
                    messages: []
                }
            } else {
                return {
                    ...state,
                    messages: [...state.messages, message]
                };
            }
        }
        case 'SET_FIREBASE_SESSION_ERROR': {
            return {
                ...state,
                status: MODAL_STATUS.FIREBASE_SESSION_ERROR
            }
        }
        case 'SET_FIREBASE_TOKEN_ERROR': {
            return {
                ...state,
                status: MODAL_STATUS.FIREBASE_TOKEN_ERROR
            }
        }
        case 'SET_DOC_CHANGED': {
            return {
                ...state,
                status: MODAL_STATUS.DOC_CHANGED
            }
        }
        case 'SET_ALREADY_SIGNED': {
            return {
                ...state,
                status: MODAL_STATUS.ALREADY_SIGNED
            }
        }
        case 'SET_OCSP_ERROR': {
            return {
                ...state,
                status: MODAL_STATUS.OCSP_ERROR
            }
        }
        case 'SET_OCSP_INVALID': {
            const { userSession } = action.payload;
            return {
                ...state,
                status: isFirmamexCert(userSession.x509.signupAuthority) ?
                    MODAL_STATUS.OCSP_INVALID_FIRMAMEX :
                    MODAL_STATUS.OCSP_INVALID_PSC
            }
        }
        case 'SET_RELOAD_ERROR': {
            return {
                ...state,
                status: MODAL_STATUS.RELOAD_ERROR
            }
        }
        case 'SET_OCSP_TIMESTAMP_ERROR': {
            return {
                ...state,
                status: MODAL_STATUS.OCSP_TIMESTAMP_ERROR
            }
        }
        case 'CLEAR': {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

function isFirmamexCert(signupAuthority) {
    return signupAuthority !== 'Cecoban' &&
        signupAuthority !== 'SAT' &&
        signupAuthority !== 'SeguriData'
}

function InvalidOCSPMessage({ t, modalState }) {
    const message = t(modalState.status === MODAL_STATUS.OCSP_INVALID_FIRMAMEX ? 'invalidOCSPFirmamex' : 'invalidOCSPPSC');
    return <p>{message}</p>
}



export default withRouter(function SignatureModal({ location, match }) {

    const { t } = useTranslation()
    const document = useSelector(state => state.document);
    const signing = useSelector(state => state.status.signing)
    const userSession = useSelector(state => state.session.current)
    const signableStickers = useSelector(state => state.signableStickers)
    const { hideConfirmation  } = useSelector(state => state.customizables)
    const [modalState, modalDispatch] = useReducer(modalReducer, initialState)
    const dispatch = useDispatch()
    const palette = usePalette();    

    const [isPassModalOpen, togglePassModal] = useToggle(false);
    const [feedbackForm, setFeedbackForm] = useState(null)

    const [requestLocation, locationAllowed] = useGeoLocate()

    const [hasAttachments, attachmentsStatus, attachments, downloadAttachments, autoDownloadAttachments] = useAttachments();
    const hasAccount = useHasAccount()


    useEffect(() => {

        function addToMsgs(message) {

            if(message !== 'Firmado' || !hideConfirmation) {
                modalDispatch({
                    type: 'ADD',
                    payload: {
                        message
                    }
                })
                if (message === 'Firmado') {
                    setTimeout(() => {
                        if(hasAttachments && document.report.boxes.filter(b => !b.signed).length === 1) {
                            downloadAttachments();
                        }
                    }, 600);
                }
            } else {
               handleOkClick();
            }

        }

        async function handleSignatureError(error) {

            switch (error) {
                case 'firebase_session_error': {
                    modalDispatch({
                        type: 'SET_FIREBASE_SESSION_ERROR'
                    })
                    break;
                }
                case 'firebase_token_required': {
                    modalDispatch({
                        type: 'SET_FIREBASE_TOKEN_ERROR'
                    })
                    break;
                }
                case 'ocsp_timestamp_error_max_attempts': {
                    modalDispatch({
                        type: 'SET_OCSP_TIMESTAMP_ERROR'
                    })
                    break;
                }
                case 'ocsp_error': {
                    modalDispatch({
                        type: 'SET_OCSP_ERROR'
                    })
                    break;
                }
                case 'invalid_cert_ocsp': {
                    modalDispatch({
                        type: 'SET_OCSP_INVALID',
                        payload: { userSession }
                    })
                    break;
                }
                case 'doc_changed': {
                    modalDispatch({
                        type: 'SET_DOC_CHANGED'
                    })
                    break;
                }
                case 'already_signed': {
                    modalDispatch({
                        type: 'SET_ALREADY_SIGNED'
                    })
                    break;
                }
                default: {
                    modalDispatch({
                        type: 'SET_RELOAD_ERROR'
                    })
                    break;
                }
            }

        }

        async function signDocument() {
            // verificar si se inicio el proceso por un sticker
            if (signableStickers.active && userSession.x509) {
                modalDispatch({
                    type: 'SET_AS_SIGNING'
                })

                
                let x509 = userSession.x509;
                let pkcs8 = userSession.pkcs8;
                let password = signing.data.password;

                if(userSession.x509.signupAuthority.includes('Google') || userSession.x509.signupAuthority.includes('Identity Provider')) {
                    const { userData: newUserData, error } = await dispatch(loadOAuthCertificate(document.report.ticket));
                    if(error) {
                        return;
                    }
                    if(newUserData) {
                        x509 = newUserData.x509;
                        pkcs8 = newUserData.pkcs8;
                        password = newUserData.passphrase;
                    }
                }
                
                addToMsgs('Asignando imagen')
                if (signableStickers.active.img) {
                    await HttpRequest.post('/browser/sticker/img', {
                        b64Image: signableStickers.active.img.data,
                        boxId: signableStickers.active.id
                    })
                } else {
                    // await HttpRequest.post('/browser/img', {
                    //     signatureImage:signatureImg
                    // })
                }

                const sticker = document.report.boxes.find(b => b._id === signableStickers.active.id);

                let latLng = {};
                if (sticker.requireLocation) {
                    latLng = await requestLocation()
                }
                
                const fullDoc = appendToArray(document.document, document.localSignatures);

                const signWithMaxAttempts = maxAttempts => {
                    let attempts = 0;
                    return async () => {
                        if(attempts > maxAttempts) {
                            return 'ocsp_timestamp_error_max_attempts';
                        }
                        attempts++;

                        return await Signature.sign({
                            report: document.report,
                            certData: {
                                chain: x509.chain,
                                pem: x509.pem
                            },
                            latLng,
                            pkcs8Pem: pkcs8.pem,
                            pass: password,
                            original: new Blob([fullDoc], { type: 'application/pdf' }),
                            sticker,
                            signatureImg: signableStickers.active.img,
                            onProgress: addToMsgs,
                            signatureCount: Math.max(document.report.firmas.length, modalState.currentSignatures),
                            onError: function (e) {
                            }
                        })
                    }
                }
                
                const sign = signWithMaxAttempts(10);
                let error = null;
                while(error === null || (error && error === 'ocsp_timestamp_error')) {
                    error = await sign();
                    if(error === 'ocsp_timestamp_error') {
                        addToMsgs('ocsp_retry');
                        await wait(1000);
                    }
                }
                if(error) {
                    handleSignatureError(error);
                } else {
                    dispatch(addHtmlSignatureImg({ ...sticker, signatureImg: signableStickers.active.img }))
                }

            }
        }

        // verificar que se activo el modo de firma
        if (match.url === location.pathname && signing.working && signing.data.type === 'certified' && signing.data.password && modalState.status === MODAL_STATUS.NEW) {
            signDocument();
        }
    }, [match.url, location,
    signing.working,
    signing.data,
    document.report.boxes,
    document.document,
    document.localSignatures,
    document.report,
        dispatch,
        userSession,
    signableStickers.active,
    modalState.status,
    modalState.currentSignatures
    ])

    useEffect(() => {
        if (match.url === location.pathname && signing.working && signing.data.type === 'certified' && modalState.status === MODAL_STATUS.NEW) {
            if (!signing.data.password) {
                togglePassModal();
            }
        }
    }, [match.url, location.pathname, signing.working, modalState.status, signing.data, togglePassModal])

    function handleOkErrorClick() {
        modalDispatch({
            type: 'CLEAR'
        })
        dispatch(setSigning({
            signing: {
                working: false,
                data: {}
            }
        }))
        dispatch(clearActiveSticker())

    }

    async function handleClickLogout() {
        await HttpRequest.post('/user/logout')
        dispatch(logout())
    }

    async function restartSignature() {
        dispatch(addTask('reloading signatures', 'Actualizando documento'))
        const signatures = await HttpRequest.get(`/pdf/signatures/original/${document.report.ticket}`, {
            responseType: 'arraybuffer'
        })
        dispatch(setLocalSignaures({
            localSignatures: signatures.data
        }))

        const response = await HttpRequest.get('/browser/report/' + document.report.ticket + '?t=' + new Date().getTime())

        dispatch(removeTask('reloading signatures'))
        modalDispatch({
            type: 'RETRY',
            payload: {
                currentSignatures: response.data.firmas.length
            }
        })

    }

    async function handleOkClick() {

        const hasRemainingStickers = signableStickers.signableStickers.find(s => s.id !== signableStickers.active?.id);

        if (feedbackForm != null) {
            HttpRequest.post('/feedback', {
                ...feedbackForm, frmxId: document.report.ticket
            });
        }

        await dispatch(loadReport(document.report.ticket, document.report.queryString))
        dispatch(setSigning({
            signing: {
                working: false,
                data: {
                    justSigned: true
                }
            }
        }))
        modalDispatch({
            type: 'CLEAR'
        })

        const qs = queryString.parse(location.search);
        if (!hasRemainingStickers && qs.fromUrl && qs.fromUrl.indexOf('javascript') === -1) {
            let decodedUrl = decodeURIComponent(qs.fromUrl);
            if(!decodedUrl.includes('doc=')) {
                const splitted = decodedUrl.split('?');
                const fromUrlQuery = queryString.parse(splitted[1] || '');
                fromUrlQuery.doc = document.report.ticket;
                decodedUrl = queryString.stringifyUrl({url: splitted[0], query: fromUrlQuery})
            }
            window.location.href = decodeURIComponent(decodedUrl);
        } else if (qs.callback && window.opener) {
            window.onbeforeunload = undefined;
            window.opener.postMessage(JSON.stringify({
                signed: true,
                doc: document.report.ticket
            }), '*');
            if(!hasRemainingStickers) {
                window.close();
            }        
        }
    }


    const handlePassComplete = useCallback((password) => {
        togglePassModal();
        dispatch(setSigning({
            signing: {
                ...signing,
                data: {
                    ...signing.data,
                    password
                }
            }
        }))
    }, [dispatch, signing, togglePassModal])


    function handlePassCancel() {
        togglePassModal();
        dispatch(setSigning({
            signing: {
                working: false,
                data: {}
            }
        }))
        dispatch(clearActiveSticker())
    }

    function handleClickDownloadOriginal() {
        const blob = new Blob([appendToArray(document.document, document.localSignatures)], { type: "application/pdf" });
        saveAs(blob, `${document.report.originalName}${signatureCount(document.report)}.pdf`)
    }

    async function handleClickDownloadUniversal() {
        const signatures = await HttpRequest.get(`/pdf/signatures/universal/${document.report.ticket}`, {
            responseType: 'arraybuffer'
        })
        const blob = new Blob([appendToArray(document.document, signatures.data)], { type: "application/pdf" });
        saveAs(blob, `${document.report.originalName}${signatureCount(document.report)}.pdf`)
    }

    function handleChangeFeedback(feedback) {
        setFeedbackForm(feedback)
    }

    return (
        <React.Fragment>
            <Modal isOpen={signing.working && signing.data.type === 'certified'}>
                <ModalBody style={{ textAlign: 'center', paddingTop: '20px' }}>
                    {
                        modalState.status === MODAL_STATUS.OCSP_TIMESTAMP_ERROR &&
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('errorSigning')}
                                </h3>
                                <br />
                                <p>{t('errorSigningOcspTimestamp')}</p>
                                <SolidButton onClick={() => { window.location.reload() }}>{t('accept')}</SolidButton>
                                <br />
                            </div> 
                    }
                    {
                        modalState.status === MODAL_STATUS.RELOAD_ERROR ?
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('errorSigning')}
                                </h3>
                                <br />
                                <p>{t('errorSigning2')}</p>
                                <p>
                                    {t('errorSigning3')}
                                </p>
                                <p>
                                    {t('errorSigning4')}
                                </p>
                                <SolidButton onClick={handleOkErrorClick}>{t('accept')}</SolidButton>
                                <br />
                            </div> : null
                    }
                    {
                        modalState.status === MODAL_STATUS.OCSP_ERROR ?
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('errorOCSP')}
                                </h3>
                                <br />
                                <p>{t('errorOCSP2')}</p>
                                <p>{t('errorOCSP3')}</p>
                                <SolidButton onClick={handleOkErrorClick}>{t('accept')}</SolidButton>
                                <br />
                            </div> : null
                    }
                    {
                        modalState.status === MODAL_STATUS.OCSP_INVALID_FIRMAMEX ||
                            modalState.status === MODAL_STATUS.OCSP_INVALID_PSC ?
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('invalidOCSP')}
                                </h3>
                                <br />
                                <InvalidOCSPMessage t={t} modalState={modalState} />
                                <SolidButton onClick={handleOkErrorClick}>{t('accept')}</SolidButton>
                                <SolidButton onClick={handleClickLogout}>{t('logOut')}</SolidButton>
                                <br />
                            </div> : null
                    }
                    {
                        modalState.status === MODAL_STATUS.DOC_CHANGED ?
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('documentChanged')}
                                </h3>
                                <br />
                                <p>{t('documentChanged2')}</p>
                                <p>{t('documentChanged3')}</p>
                            </div> : null
                    }
                    {
                        (modalState.status === MODAL_STATUS.FIREBASE_SESSION_ERROR
                            ||
                        modalState.status === MODAL_STATUS.FIREBASE_TOKEN_ERROR) && 
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                    {t('firebase session error')}
                            </h3>
                            <br />
                            <p>{t('firebase resignup')}</p>
                            <br />
                            <SolidButton onClick={async () => {
                                await HttpRequest.post('/user/logout')
                                dispatch(logout());
                                window.location.reload() 
                            }}>{t('accept')}</SolidButton>
                            <br />
                        </div>
                    }
                    {
                        modalState.status === MODAL_STATUS.ALREADY_SIGNED ?
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('sameSignature')}
                                </h3>
                                <br />
                                <p>{t('sameSignature2')}</p>
                                <SolidButton onClick={() => { window.location.reload() }}>{t('accept')}</SolidButton>
                                <br />
                            </div> : null
                    }
                    {
                        modalState.status === MODAL_STATUS.SIGNED ? 
                        <div>
                            <h3 style={{color: palette.accentText}}>
                                {t('documentSigned')} <br/>{t('successfully')}
                            </h3>
                            <br/>
                            <ImgSuccess></ImgSuccess>
                            <br/>
                            {
                                // tiene attachments y se firmo el ultimo sticker
                                hasAttachments && document.report.boxes.filter(b => !b.signed).length === 1 ?
                                <div> 
                                    <br/>
                                    <p>{t(attachmentsStatus)}</p>
                                    {
                                        attachments && attachments.length > 0 ?
                                        <div>
                                            <div>
                                                {t('download attachments here')}
                                            </div>
                                            {
                                                attachments.map(f => (
                                                    <div key={f}>
                                                        <a href={`${HttpRequest.backend}/pdf/attachments/${document.report.ticket}/${f}`}>{f}</a>
                                                    </div>
                                                ))
                                            }
                                        </div> : null
                                    }
                                </div> : null
                            }
                              <br/>                                        
                            <FlexColumn alignItems={'center'}>
                                {
                                    !hasAccount && (window.location.href.includes('docmage') || window.location.href.includes('firmamex.com'))  &&
                                        <SolidButton 
                                        tag="a" href="https://www.firmamex.com/prueba-gratis/" 
                                        target="_blank">{t('Prueba gratis Firmamex')}</SolidButton>                                    
                                }
                                <SolidButton color="secondary" onClick={handleOkClick}>{t('ok')}</SolidButton>
                            </FlexColumn>                                    
                            <br/>
                            {
                                document.report.inFlow && (!document.report.allSigned || document.report.boxes.filter(b => !b.signed) >= 1) ?
                                <p style={{margin:'0 30px'}}>
                                    {t('documentInFlow')}
                                </p> : null
                            }   
                            {
                                // askForFeedback && (signableStickers.signableStickers && signableStickers.signableStickers.length === 1) &&
                                //     (window.location.href.includes('docmage') || window.location.href.includes('firmamex.com') || window.location.href.includes('xpertal')) ? 
                                // <UserFeedbackForm onChange={handleChangeFeedback}></UserFeedbackForm> : null
                            }      
                        </div> : null
                    }
                    {
                        modalState.status === MODAL_STATUS.SIGNING ?
                        <div className='imageDialog'>
                            <img src={`${process.env.REACT_APP_CDN_PATH}/images/firmando.svg`}></img>
                            <div className="imageDialogSeparator"></div>                    
                            <p className='imageDialogTitle'>
                                {t('signing')}
                            </p>
                            <br/>
                            {
                                modalState.messages.length > 0 ?
                                    <div>
                                        {t(modalState.messages[modalState.messages.length - 1])}
                                    </div>
                                : null
                            }
                        </div>  
                        : null
                    }    
                    <br />
                    <Spinner show={modalState.status === MODAL_STATUS.SIGNING}></Spinner>
                </ModalBody>
                {
                    modalState.status === MODAL_STATUS.DOC_CHANGED ?
                        <ModalFooter>
                            <SolidButton onClick={restartSignature}>{t('confirm')}</SolidButton>
                            <SolidButton color="secondary" onClick={handleOkErrorClick}>{t('cancel')}</SolidButton>
                        </ModalFooter> : null
                }
            </Modal>
            <PasswordModal
                isOpen={isPassModalOpen}
                onComplete={handlePassComplete}
                onCancel={handlePassCancel}></PasswordModal>
            <GeoLocateOverlay isOpen={locationAllowed === false}></GeoLocateOverlay>
        </React.Fragment>
    )

})