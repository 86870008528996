import { SET_TRANSLATE, ADD_TRANSLATE } from "../actionTypes";


const initialState = {
    translate: {},
    loaded: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_TRANSLATE: {
            return {
                ...state,
                // translate,
                loaded: true
            }
        }
        default: {
            return state;
        }
    }
}
