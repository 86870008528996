import React, {useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest';
import { useTranslation } from 'react-i18next';

export default function FormRedirect({match, history}) {

    const {t} = useTranslation()

    useEffect(() => {
        async function createFormInstance() {
            const response = await HttpRequest.get(`/forms/${match.params.uuid}`);
            history.push(`/form/i/${response.data.ticket}`);
        }
        createFormInstance();
    }, [match, history])

    return (
        <div>{t('loading')}</div>
    )

}