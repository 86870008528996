import React, {useState, useEffect, Suspense} from 'react';
import {useSelector} from 'react-redux';
import ResponsiveModal from './ResponsiveModal';
import SignatureCanvas from './SignatureCanvas';
import PickSignatureStyle from './PickSignatureStyle';
import ResponsiveSheetModal from './ResponsiveSheetModal';
import PollNom151Timestamp from './PollNom151Timestamp';
import AlertModal from './AlertModal';
import UploadDocumentModal from './UploadDocumentModal';

import { useTranslation } from 'react-i18next';

export default function TaskModal() {

    const {t} = useTranslation()
    const drawingSignature = useSelector(state => state.status.drawingSignature);
    const pickingSignatureStyle = useSelector(state => state.status.pickingSignatureStyle);
    const waitingNom151Timestamp = useSelector(state => state.status.waitingNom151Timestamp);
    const showingAlert = useSelector(state => state.status.showingAlert);
    const uploadingDocument = useSelector(state => state.status.uploadingDocument)

    function handleDrawingDone(data) {
        drawingSignature.data.resolve(data);
    }

    function handleDrawingCancel() {
        drawingSignature.data.reject();
    }

    function handlePickSignatureDone(imageData) {
        pickingSignatureStyle.data.resolve(imageData);
    }

    function handlePickSignatureCancel() {
        pickingSignatureStyle.data.resolve();
    }

    return (
        <React.Fragment>
            <ResponsiveModal isOpen={drawingSignature.working}
                onCancel={handleDrawingCancel}
                title='signatureDrawing'>
                <SignatureCanvas
                    imageType={drawingSignature.data ? drawingSignature.data.imageType : 'stroke'}
                    singleUse={drawingSignature.data ? drawingSignature.data.singleUse : false}
                    onDone={handleDrawingDone}
                    onCancel={handleDrawingCancel}></SignatureCanvas>
            </ResponsiveModal>
            <Suspense fallback={<div></div>}>
                <PickSignatureStyle
                    isOpen={pickingSignatureStyle.working}
                    stickerId={pickingSignatureStyle.data.stickerId}
                    commonName={pickingSignatureStyle.data.commonName}
                    issuerName={pickingSignatureStyle.data.issuerName}
                    cancelable={pickingSignatureStyle.data.cancelable}
                    requestedImageType={pickingSignatureStyle.data.requestedImageType}
                    onDone={handlePickSignatureDone}
                    onCancel={handlePickSignatureCancel}></PickSignatureStyle>
            </Suspense>            
            <PollNom151Timestamp isPolling={waitingNom151Timestamp.working}></PollNom151Timestamp>
            <AlertModal isOpen={showingAlert.working}>
                {showingAlert.children}
            </AlertModal>
            <UploadDocumentModal
                isOpen={uploadingDocument.working}
                file={uploadingDocument.file}>
            </UploadDocumentModal>
        </React.Fragment>
    )

}