

export default function buildRadioStyles({color}) {
    return {
        RadioContainer: {
            display: 'block',
            position: 'relative',
            paddingLeft: '35px',
            marginBottom: '6px',
            marginTop: '6px',
            cursor: 'pointer',
            userSelect:'none',
            lineHeight:'40px',
            fontSize: '16px'
        },
        RadioButton: {
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer'
        },
        StyledRadioButton: {
            position: 'absolute',
            top: '6px',
            left: 0,
            height: '25px',
            width: '25px',
            backgroundColor: '#fff',
            borderRadius: '50%',
            border: '1px solid',
            borderColor: '#484848',
            ':after': {
                content: '""',
                position: 'absolute',
                display: 'none',
                top: '2.8px',
                left: '2.8px',
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                background: color
            }
        },
        CheckedRadioButton: {
            ':after': {
                display:'block'
            }
        }
    }
}