import React from 'react';
import {useSelector} from 'react-redux';

const CustomModalHeaderFirmamex = React.lazy(() => import('./CustomModalHeaderFirmamex'));
const CustomModalHeaderAcertia = React.lazy(() => import('./acertia/CustomModalHeaderAcertia'));
export default function CustomModalHeader({children, toggle, className}) {

    const {companyComponents} = useSelector(state => state.customizables);

    return (
        <React.Fragment>
            {
                (companyComponents === "Acertia") ? <React.Suspense fallback={<div></div>}>
                    <CustomModalHeaderAcertia
                        children={children}
                        toggle={toggle}
                        className={className}
                    />
                </React.Suspense> : <React.Suspense fallback={<div></div>}>
                    <CustomModalHeaderFirmamex
                        children={children}
                        toggle={toggle}
                        className={className}
                    />
                </React.Suspense>
            }
        </React.Fragment>
        
    )

}