import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Sticker from './Sticker';
import {useSelector} from 'react-redux'
import { getPageLayer } from '../utils/toolbox';

export default function StickerLayer({page, stickers, pageRendered, onClickOwner, editable, activeSigner, activeSignerFound}) {

    const [container] = useState(document.createElement('div'))
    const [pageHeight, setPageHeight] = useState(0) 
    const activeSticker = useSelector(state => state.signableStickers.active)    

    useEffect(() => {
        if(pageRendered) {
            // renderizamos la capa de stickers sobre cada pagina renderizada
            const pageLayer = getPageLayer(page)
            if(!pageLayer) {
                return;
            }
            setPageHeight(pageLayer.offsetHeight)
            container.id = `sticker-layer-${page}`;
            container.className = 'stickerLayer'     
            pageLayer.appendChild(container);
            return () => {
                pageLayer.removeChild(container);
            }
        }
    }, [pageRendered, container, page])    

    return ReactDOM.createPortal((
        <div className="stickers">
            {
                stickers.map((s, i) => (
                    <Sticker key={i} {...s} pageHeight={pageHeight} 
                        onClickOwner={() => onClickOwner(s)}
                        editable={editable}
                        signing={activeSticker != null && s._id === activeSticker.id && activeSticker.status !== 'click'}
                        activeSigner={activeSigner}
                        activeSignerFound={activeSignerFound}/>
                ))
            }
        </div>), container)
}