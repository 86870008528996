import React, {useState, useEffect, useMemo} from 'react';
import { useSpring, animated, interpolate } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import styles from './Draggable.module.css'
import ConfirmCancel from './ConfirmCancel';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TOOLTIP_WIDTH = 200;

function calcTooltipTop(draggableScale, docScale) {
    // const initial = -60 - 40/(scale)
    return -60 - (40/docScale) * draggableScale
}

function DraggableImage({type}) {

    const {t} = useTranslation()

    switch(type) {
        case 'STICKER': {
            return <img src={`${process.env.REACT_APP_CDN_PATH}/images/es_sign_here.svg`} alt={t("firme aqui")} style={{height:'90%', pointerEvents:'none'}}></img>
        }
        default: {
            return <div></div>
        }
    }
}

function getDraggableLabel({type}) {
    switch(type) {
        case 'STICKER': {
            return 'Arrastra el sticker hacia donde deseas colocarlo';
        }
        default: {
            return null
        }
    }
}


function intersect(a, b) {
    return a.lx <= b.tx &&
        a.tx >= b.lx &&
        a.ly <= b.ty &&
        a.ty >= b.ly
}


function checkBoundingBoxes(boxes, position, pageSize, page) {

    const draggable = {
        lx: position.x,
        ly: pageSize.height - position.height - position.y,
        tx: position.x + position.width,
        ty: pageSize.height - position.y
    }

    const intersectingBox = boxes
                .filter(b => b.page === page - 1)
                .find(b => intersect(draggable, {
                    lx: b.lx,
                    ly: b.ly,
                    tx: b.lx + b.width,
                    ty: b.ly + b.height
                }))

    return intersectingBox !== undefined
}


function calcTooltipLeft() {
    return 0;
}

export default function AbstractDraggable({size, type, page, scale, pageSize, resolve, reject}) {

    const {t} = useTranslation()
    const mainRef = React.useRef(null);
    const bottomRightRef = React.useRef(null);
    const bottomLeftRef = React.useRef(null);
    const topRightRef = React.useRef(null);
    const topLeftRef = React.useRef(null);
    const {boxes} = useSelector(state => state.document.report)

    const draggableLabel = getDraggableLabel({type});
    const [intersects, setIntersects] = useState(false)

    // la posicion inicial es el centro de la pagina actual
    const centerPos = useMemo(function() {

        if(!pageSize) {
            return {
                x: 0, y:0
            }
        }

        return {
            x: pageSize.width / 2 - size.width / 2,
            y: pageSize.height / 2 - size.height / 2,
            ratio: size.height / size.width
        }
    }, [pageSize, size])

    // para guardar la posicion en la que queda el sticker
    // el movimiento es relativo a la posicion actual
    const [current, setCurrent] = useState({...centerPos, ...size, 
        tooltipTop: centerPos.y - 75,
        scaleX: 1,
    })

    const [{ x, y, width, height, scaleX, tooltipTop }, set] = useSpring(() => ({ ...current, config: {
        mass: 1, tension: 210, friction: 20, clamp:true
    } }))

     // drag hook
    const bind = useDrag(({ 
            event, // evento original
            movement: [mx, my], // last gesture offset (xy - initial)
            delta,
            first, // true si es el inicio de la interaccion
            memo, // contiene le ultimo valor regresado de la funcion
            last // true si es el fin de la interaccion
        }) => {
        // no queremos que se selecciona algo detras del sticker al estar
        // arrastrandolo
        event.preventDefault();
        event.stopPropagation();
        
        // el target puede ser el sticker o uno de los handlers
        // para cambiar su tamano
        let currentTarget = memo;
        if(first) {
            // se define cuando inicia la interaccion
            currentTarget = event.target;            
        }

        switch(currentTarget) {
            case bottomLeftRef.current: {
                const smx = mx/scale;
                const newScale = (current.scaleX * size.width - smx) / (size.width);
                set({                    
                    tooltipLeft: calcTooltipLeft(current.width - smx),
                    x:current.x + (smx * centerPos.ratio),
                    y: current.y - (smx/2 * centerPos.ratio),
                    scaleX: newScale
                })
                break;
            }
            case bottomRightRef.current: {                
                const smx = mx/scale;
                const newScale = (current.scaleX * size.width + smx) / (size.width);
    
                const y = current.y + (smx/2 * centerPos.ratio);

                const height = size.height * newScale;
                const realY = y - (height - size.height) / 2;

                set({
                    tooltipTop: realY - 75,
                    x: current.x + smx/2,
                    y: current.y + (smx/2 * centerPos.ratio),
                    scaleX: newScale
                })
                break;
            }
            case topLeftRef.current: {
                const smx = mx/scale;
                const newScale = (current.scaleX * size.width - smx) / (size.width);
                set({
                    tooltipLeft: calcTooltipLeft(current.width - smx),
                    x:current.x + (smx * centerPos.ratio),
                    y: current.y + (smx/2 * centerPos.ratio),
                    scaleX: newScale
                })
                break;
            }
            case topRightRef.current: {
                const smx = mx/scale;
                const newScale = (current.scaleX * size.width + smx) / (size.width);
                set({
                    tooltipLeft: calcTooltipLeft(current.width + smx),
                    x: current.x + smx/2,
                    y: current.y - (smx/2 * centerPos.ratio),
                    scaleX: newScale
                })
                break;
            }
            case mainRef.current: {
                const newX = current.x + mx/scale;
                const newY = current.y + my/scale;
                const realX = newX - (current.width - size.width) / 2;
                const realY = newY - (current.height - size.height) / 2;
                const withinWidth = realX > 0 && realX < (pageSize.width - current.width);
                const withinHeight = realY > 0 && realY < (pageSize.height - current.height);
                if(withinWidth && withinHeight) {
                    set({ 
                        tooltipTop: realY - 75,
                        x: newX,
                        y: newY
                    })        
                }                            
                break;
            }
            default: {}
        }

        if(last) {
            // cuando finaliza la interaccion se guardan los datos
            // actuales del sticker

            setCurrent({
                width: size.width * scaleX.value, 
                height: size.height * scaleX.value,
                scaleX: scaleX.value,
                x: x.value,
                y: y.value
            })
        }

        return currentTarget;
    }, {
        domTarget: mainRef,
        eventOptions: {
            passive: false
        }
    })

    useEffect(bind, [bind])

    useEffect(() => {
        const result = checkBoundingBoxes(boxes, current, pageSize, page)
        setIntersects(result)
    }, [boxes, current, pageSize, page])

    function handleClickCancelDraggable() {
        reject();
    }

    function handleClickConfirm() {
        resolve({
            ...current,
            x: current.x - (current.width - size.width) / 2,
            y: pageSize.height - (current.y - (current.height - size.height)/2) - current.height,
            page: page - 1
        })
    }
    return (
        <React.Fragment>
            <div className={styles.container}
                style={{
                    width: size.width,
                    height:size.height,
                    zIndex:1
                }}>                    
                    {
                        draggableLabel ? 
                        <animated.div className={styles.tooltip} 
                            style={{
                                top: 0,
                                width: TOOLTIP_WIDTH, 
                                // y: tooltipTop,
                                // transform: `scale(${0.8/scale})`
                                transform: interpolate([x,y,tooltipTop], (x, y, tooltipTop) => `translate3d(${x - (TOOLTIP_WIDTH - size.width)/2}px, ${(tooltipTop)}px, 0) scale(${1})`)
                            }}>
                            {draggableLabel}
                            <div className={styles.arrowDown}></div>
                        </animated.div> : null
                    }
                    <animated.div ref={mainRef} style={{
                        width:'100%', 
                        height:'100%', 
                        top: 0,
                        left: 0,
                        position: 'absolute',
                        transform: interpolate([x,y,scaleX], (x, y, scaleX) => `translate3d(${x}px, ${y}px, 0) scale(${scaleX})`)
                    }}>
                        <div className={`${styles.background}`}>
                            <DraggableImage type={type}></DraggableImage>
                        </div>                
                        <div ref={bottomRightRef} className={`${styles.handler} ${styles.bottomRight}`}></div>
                        <div ref={bottomLeftRef} className={`${styles.handler} ${styles.bottomLeft}`}></div>
                        <div ref={topRightRef} className={`${styles.handler} ${styles.topRight}`}></div>
                        <div ref={topLeftRef} className={`${styles.handler} ${styles.topLeft}`}></div>
                    </animated.div>
                    
            </div>
            <ConfirmCancel confirmLabel={t('accept')}
                onConfirm={handleClickConfirm}
                onCancel={handleClickCancelDraggable}
                disabled={intersects}
                disabledLabel={t('notAvailable')}></ConfirmCancel>
        </React.Fragment>
    )

}