import React, {useReducer, useCallback, useEffect} from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import db from '../utils/Storage';

const initialPageState = {
    visible: false,
    html:null,
    updatedAt: null
}


const ACTIONS = {
    SHOW:'SHOW',
    HIDE: 'HIDE',
    SET_HTML: 'SET_HTML'
}

function reducer(state, action) {
    switch(action.type) {
        case ACTIONS.SHOW: {
            return {                
                visible: true
            }
        }
        case ACTIONS.HIDE: {
            return {
                visible: false,
                html:null
            }
        }
        case ACTIONS.SET_HTML: {
            return {
                visible: true,
                html: action.payload.html,
                updatedAt: action.payload.updatedAt
            }
        }
        default: {
            return state;
        }
    }
}

export default function Page({idx, onVisibleChange, pageSize, page}) {    

    const [{visible, html, updatedAt}, dispatch] = useReducer(reducer, initialPageState)

    useEffect(() => {
        let cancel = false;
        async function loadFromCache() {
            const data = await db.pages.get(page.id)
            if(data && !cancel) {
                dispatch({
                    type: ACTIONS.SET_HTML,
                    payload: {
                        html: data.data,
                        updatedAt: page.updatedAt
                    }
                })
            }
        }

        // se desactiva esta funcionalidad para cargar todas las paginas al mismo tiempo
        // esto deberia permitir eliminar el db storage como siguiente paso
        // y eliminar el problema de abrir varios documentos en diferentes tabs
        // if(visible) {
            loadFromCache()
        // }        

        return () => {
            cancel = true;
        }
    }, [page.id, page.updatedAt])

    function handleVisibleChange(isVisible) {
        if(isVisible) {
            dispatch({
                type: 'SHOW',
                payload: {
                    pageId: page.id
                }
            })
        } else {
            // dispatch({
            //     type: 'HIDE'
            // })
            // onVisibleChange(idx, null, null)
        }        
    }

    const pageRef = useCallback(node => {

        if(visible && node) {            

            function waitUntilRendered(page) {
                if(page.getBoundingClientRect().height > 50) {
                    onVisibleChange(idx, page.getBoundingClientRect(), updatedAt)
                } else {
                    setTimeout(() => {
                        waitUntilRendered(page)
                    });
                }
            }
            waitUntilRendered(node.children[node.children.length - 1])
        }

    },  [visible, onVisibleChange, idx, updatedAt])


    return (                           
            <React.Fragment>
                {
                    !html ?
                    <div className={`Page`} id={page.id}>
                        <div className="pf" style={{
                        margin:'auto',
                        background:'#fff',
                        backgroundImage: `url('https://storage.googleapis.com/static.firmamex.com/2.gif')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height:pageSize.height + 'px', 
                        width:pageSize.width+ 'px'}}></div>
                    </div> :
                    <div
                        key={updatedAt}
                        className="Page" id={page.id}
                        dangerouslySetInnerHTML={{
                    __html:html
                    }}
                    ref={pageRef}></div>   
                }
            </React.Fragment>                            
    )

    // return (
    //     <VisibilitySensor 
    //         intervalCheck={false}
    //         scrollCheck={true}
    //         containment={document.getElementById('pdf-renderer-container')}            
    //         partialVisibility={true}
    //         offset={100}
    //         onChange={handleVisibleChange}>
    //         {                                
    //             !visible || !html ?
    //             <div className={`Page`} id={page.id}>
    //                 <div className="pf" style={{
    //                 margin:'auto',
    //                 background:'#fff',
    //                 height:pageSize.height + 'px', 
    //                 width:pageSize.width+ 'px'}}></div>
    //             </div> :
    //             <div
    //                 key={updatedAt}
    //                 className="Page" id={page.id}
    //                 dangerouslySetInnerHTML={{
    //             __html:html
    //             }}
    //             ref={pageRef}></div>                               
    //         }
    //     </VisibilitySensor>
    // )             
}