import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


import { useTranslation } from 'react-i18next';


export function AppLogo({subtitle}) {

    const {t, i18n} = useTranslation()
    const {logo, accentFontColor, customStyle, logoSignup, showLabel} = useSelector(state => state.customizables);

    const labelStyle = subtitle ? {
        position: 'absolute',
        bottom: '20px',
        right: '50px',
        width: '100px',
        fontSize: '12px'
    } : {
        position: 'relative',
        top: '30px',
        left: '-50px',
        fontSize: '12px'
    }

    let label = null;
    if(showLabel) {
        const lang = i18n.language.split('-')[0];
        const parsedLabel = JSON.parse(showLabel);
        label = parsedLabel[lang] ?? parsedLabel['es'];
    }
    

    return customStyle !== undefined ? 
        <React.Fragment>
            <div style={{ 
                position: subtitle ? 'relative' : null,
                top: '20px'
                }}>
                <img 
                    src={subtitle ? logoSignup : logo} 
                    alt="logo" 
                    style={{
                        position: 'inherit',
                        maxWidth:subtitle ? null : '300px',
                        maxHeight: subtitle ? null : '60px',
                        marginBottom: subtitle ? '50px' : null,
                        top: '0px',
                        left: '0px'
                    }}/>
                {
                    label ?
                        <span style={labelStyle}>{label}</span> : null
                }
            </div>
            {
                subtitle ? 
                <p style={{fontSize:'10px', fontWeight:'bold', color:accentFontColor}}>
                    {t('signQuickly1')} <br/>
                    {t('signQuickly2')}
                </p> : null
            }
        </React.Fragment> : <div></div>
}

export function ImgSuccess() {
    const {imgSuccess, customStyle} = useSelector(state => state.customizables)

    return customStyle !== undefined && imgSuccess ?
        <img src={imgSuccess} alt="exito"
            style={{
                maxHeight: '200px'
            }}></img> : <div></div>
}

export function ImgSticker({activeSigner, data}) {
    const {imgSticker, customStyle} = useSelector(state => state.customizables)
    const {t} = useTranslation()

    return customStyle !== undefined && imgSticker ?
        <img src={imgSticker} alt={t("firme aqui")}
            style={{
                opacity: activeSigner ? activeSigner === data ? 1 : 0.05 : 1,
                height: '90%'
            }}></img> : <div></div>
}

export function usePalette() {
    const {customStyle, palette} = useSelector(state => state.customizables);
    return customStyle !== undefined ? palette : { waiting: true, styles: {}};
}