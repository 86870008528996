import {useEffect, useState} from 'react'
import {debounce} from 'lodash'
import {IS_MOBILE} from '../Consts'

const TOP_OFFSET = IS_MOBILE ? 400 : 100

export default function useCurrentPage(rendererRef, originalSize, scale) {

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        function handleScroll(e) {
            const pageHeight = scale * originalSize.height;
            const scrollTop = e.target.scrollTop + (pageHeight/3);            
            const cp = Math.floor(scrollTop / pageHeight) + 1;
            setCurrentPage(cp);
        }

        const debounced = debounce(handleScroll, 500);

        const elem = rendererRef.current;
        elem.addEventListener('scroll', debounced);
        return () => {
            elem.removeEventListener('scroll', debounced);
        }
    }, [rendererRef, originalSize, scale])

    return [currentPage]

}