import React, {useState, useCallback} from 'react';

export default function useToggle(initialState) {
    const [value, setValue] = useState(initialState);

    const toggle = useCallback(() => {
        setValue(v => !v);
    }, [setValue]);

    return [value, toggle]
}