import {combineReducers} from 'redux';
import tasks from './tasks';
import stickers from './stickers'
import document from './document'
import userData from './user_data'
import status from './status'
import session from './session'
import customizables from './customizables'
import config from './config';

export default combineReducers({tasks, signableStickers:stickers, document, userData, status, session, customizables, config});