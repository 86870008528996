import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { usePalette } from './Customizables';

export default function Spinner({show}) {

    const palette = usePalette();
    const [styles, setStyles] = useState(null)

    useEffect(() => {
        if(!palette.waiting) {
            setStyles(StyleSheet.create({
                dualRing: {
                    display: 'inline-block',
                    width: '64px',
                    height: '64px',
                    ':after': {
                        content: '" "',
                        display: 'block',
                        width: '46px',
                        height: '46px',
                        margin: '1px',
                        borderRadius: '50%',
                        border: `5px solid ${palette.secondaryColor}`,
                        borderColor:  `${palette.secondaryColor} transparent ${palette.secondaryColor} transparent`,
                        animation: 'dualRing 1.2s linear infinite'
                    }
                }
            }))
        }
    }, [palette.waiting, palette.secondaryColor])

    return show && styles ? <div className={css(styles.dualRing)}></div> : null
}