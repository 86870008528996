import React from 'react';
import styles from './TextButton.module.css';
import { usePalette } from './Customizables';

export default function TextButton({className, color, flexGrow, style, disabled, onClick, ...rest}) {

    const palette = usePalette();

    const fontColor = disabled ? styles.disabled : 
        color && color === 'warning' ? '#dc3545' : 
        color === 'secondary' ? palette.secondaryAction : palette.mainAction;

    function handleClick() {
        if(!disabled && onClick) {
            onClick()
        }
    }

    return <div {...rest} className={` ${styles.button} ${className || ''}`}  
        onClick={handleClick}      
        style={{
            color: fontColor,
            flexGrow: flexGrow ? "1" : "0",
            ...style
        }}
        ></div>
}