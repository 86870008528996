import React from 'react';
import { usePalette } from './Customizables';

export default function Header({children, style}) {

    const palette = usePalette();

    return (
        <h4 style={{
            ...style,
            color: palette.primaryText
        }}>{children}</h4>
    )

}