import React, {useState, useEffect, useRef} from 'react';
import Signature from '../crypto/Signature'
import {Tooltip} from 'reactstrap';
import styles from './Sticker.module.css';
import useToggle from '../hooks/useToggle'

export default function SignatureHtmlImg({pageHeight, lx, ly, width, height, page, signing, authority, data, line, signatureImg}) {    

    const stickerRef = useRef();
    const [imgPos, setImgPos] = useState(null)
    const [tooltipOpen, toggleTooltip] = useToggle(false)    

    useEffect(() => {
        if(signatureImg) {
            const signaturePos = Signature.getPosFromSticker(signatureImg, {width, height, lx, ly, page, line});            
            setImgPos({
                width: signaturePos.width,
                height: signaturePos.height,
                top: signaturePos.yDiff,
                left: signaturePos.xDiff
            })
        }
    }, [signatureImg])

    return (     
        <React.Fragment>
            <div
                ref={stickerRef}
                className={styles.containerNoBorder} 
                style={{
                position: 'absolute',
                top:  pageHeight - ly - height,
                left: lx,
                width: width,
                height: height
            }}>
                {
                    !signatureImg ? 
                    <div className={styles.background} style={{
                        width: width - 8,
                        height: height - 8
                    }}>
                    </div> : null
                }
                {
                    signatureImg && imgPos ? 
                        <img src={`data:image/png;base64,${signatureImg.data}`}
                            style={{
                                position:'absolute',                            
                                ...imgPos
                            }}
                            alt="signature-img"
                        /> : null
                }                
            </div>
            {
                stickerRef.current ? 
                <Tooltip position="top" 
                target={stickerRef.current} 
                isOpen={tooltipOpen} 
                toggle={toggleTooltip}>
                    {authority.uiName}<br/>
                    {data}
                </Tooltip> : null
            }
        </React.Fragment>                           
    )
}