import { createStore, applyMiddleware } from "redux";
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from "./reducers";
import { composeWithDevTools } from '@redux-devtools/extension'

export default createStore(rootReducer, composeWithDevTools(
    // applyMiddleware(thunk, logger)
    applyMiddleware(thunk)
));
