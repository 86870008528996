import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import HttpRequest from './HttpRequest';
import { Alert } from 'reactstrap';
import { IS_MOBILE } from '../Consts';
import { loadReport } from '../redux/actions';
import axios from 'axios'
import { italics } from '../verificationPoller.worker';


export default function PollNom151Timestamp({isPolling}) {

    const dispatch = useDispatch();
    const signing = useSelector(state => state.status.signing)
    const report = useSelector(state => state.document.report);    


    useEffect(() => {

        let timeout = null;
        let source = null;

        async function fetchNom151TimestampStatus() {
            source = axios.CancelToken.source();
            const response = await HttpRequest.get(`/nom151Timestamp/${report.ticket}/status`, {cancelToken: source.token});
            return response.data.status;                            
        }

        async function poll() {
            const status = await fetchNom151TimestampStatus();
            if(status !== 'ready' || signing.working) {
                timeout = setTimeout(() => {
                    poll();
                }, 3000)
            } else {
                dispatch(loadReport(report.ticket, ''))   
            }        
        }

        if(isPolling) {
            poll();
        }

        return () => {
            if(source) source.cancel();
            if(timeout) clearTimeout(timeout);
        }

    }, [isPolling, report.ticket, dispatch])

    return (
        // isPolling ? 
        // <div style={{
        //     position:"absolute",
        //     width:'100%',
        //     bottom: IS_MOBILE ? '80px' : '20px'
        // }}>
        //     <Alert color="primary" style={{
        //         margin:'auto',
        //         width:'80%'
        //     }}>
        //         Esperando estampilla de tiempo NOM
        //     </Alert>
        // </div> : null
        null
    )

}