import React, {useState} from 'react';
import HttpRequest from '../shared/HttpRequest';

export default function useHttpGet() {

    const [working, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    async function fetch(path) {
        setWorking(true);
        setError(null)
        try {
            const response = await HttpRequest.get(path);
            setWorking(false);
            setResponse(response.data)
            return response.data;
        } catch(e) {
            setWorking(false);
            setError(e);
        }        
        return null;
    }

    return [working, fetch, error, response];

}