import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'


const maxSignatures = Number(process.env.REACT_APP_MAX_SIGNATURES);

export default function useMaxSignaturesReached() {

    const {firmas} = useSelector(state => state.document.report);
    const [reached, setReached] = useState(false);

    useEffect(() => {
        setReached(maxSignatures > 0 && firmas && firmas.length >= maxSignatures)
    }, [firmas])

    return reached;

}