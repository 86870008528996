import {LOGIN, OAUTH_RELOGIN, SET_CLIENT_DATA, LOAD_USER, LOGOUT, SET_SIGNATURE_STROKE, 
    SET_SIGNATURE_STROKE_IMAGE, GO_TO_APP1,
    SET_SIGNATURE_IMAGE, SAVE_PASSPHRASE, CHANGE_ORG} from '../actionTypes';
import axios from 'axios'
import HttpRequest from "../../shared/HttpRequest";

function loadAxios(jwt) {
    axios.interceptors.request.handlers = [];
    axios.interceptors.request.use(
        (config) => {
            if(!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${ jwt }`;
            }            
        return config;
        }, 
        (error) => {
        return Promise.reject(error);
        }
    );
}

const initialState = localStorage.getItem('session') ?
JSON.parse(localStorage.getItem('session')) :
{
    current: {
        jwt:null,
        pkcs8:{},
        signatureStroke:{},
        signatureSrokeImage: null,
        x509:{},
        passphrase:null,
        clientData: null,
        signatureImage: null,
        uid: null
    },
    directory: []
};


if(initialState.current.uid === undefined && initialState.current.jwt) {
    initialState.current.uid = Number(JSON.parse(atob(initialState.current.jwt.split('.')[1])).uid)
}

if(initialState.current.jwt) {
    loadAxios(initialState.current.jwt);
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOAD_USER: {
            const userId = action.payload;
            const stored = localStorage.getItem(`session-${userId}`);
            if(!stored) {
                // no existe la informacion del usuario
                return state;
            }
            const userData = JSON.parse(stored);    
            loadAxios(userData.jwt)      
            
            const session = {
                ...state,
                current: userData
            }

            localStorage.setItem('session', JSON.stringify(session))

            return session;
        }
        case SAVE_PASSPHRASE: {
            const {passphrase} = action.payload;
            const session = {
                ...state,
                current: {
                    ...state.current,
                    passphrase
                }
            }
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem(`session-${session.current.x509.data}-${session.current.x509.authority}-${session.current.x509.signupAuthority}`, JSON.stringify(session.current));
            return {
                ...state,
                ...session
            }

        }
        case CHANGE_ORG: {
            const jwt = action.payload;
            const userData = {...state.current};

            userData.jwt = jwt;
            userData.uid = Number(JSON.parse(atob(jwt.split('.')[1])).uid);

            const accountId = `${userData.x509.data}-${userData.x509.authority}-${userData.x509.signupAuthority}`;
            const session = {
                ...state,
                current: userData
            }

            loadAxios(userData.jwt)
            localStorage.setItem('session', JSON.stringify(session))
            localStorage.setItem(`session-${accountId}`, JSON.stringify(userData));

            return session;
        }
        case LOGIN: {
            const userData = action.payload;

            if(userData.jwt) {
                userData.uid = Number(JSON.parse(atob(userData.jwt.split('.')[1])).uid)
            }

            let session = {
                current: userData
            }
            let directory = [...state.directory]

            if(userData.x509) {
                // la sesion que se esta abriendo es de Firebase
                if(userData.x509.authority.includes('Google')) {
                    // solo puede haber una sesion de Firebase abierta                    
                    // buscamos si hay otra sesion de Firebase
                    const existing = state.directory.find(s => s.id.includes('Google-'));
                    if(existing) {
                        directory = directory.filter(s => s.id !== existing.id)
                        localStorage.removeItem(`session-${existing}`)
                    }
                }

                const accountId = `${userData.x509.data}-${userData.x509.authority}-${userData.x509.signupAuthority}`;
                session = {
                    current: userData,
                    directory: [
                        ...directory, {
                        id: accountId
                    }]
                }
                localStorage.setItem('session', JSON.stringify(session));
                localStorage.setItem(`session-${accountId}`, JSON.stringify(userData));
            }
            loadAxios(userData.jwt)
            return {
                ...state,
                ...session
            }
        }
        case OAUTH_RELOGIN: {
            const userData = action.payload;

            if(userData.jwt) {
                userData.uid = Number(JSON.parse(atob(userData.jwt.split('.')[1])).uid)
            }

            let session = {
                current: userData
            }
            let directory = [...state.directory]

            if(userData.x509) {
                // solo puede haber una sesion de Firebase abierta                    
                // buscamos si hay otra sesion de Firebase
                const existing = state.directory.find(s => s.id.includes('Google-'));
                if(existing) {
                    directory = directory.filter(s => s.id !== existing.id)
                    localStorage.removeItem(`session-${existing}`)
                }

                const accountId = `${userData.x509.data}-${userData.x509.authority}-${userData.x509.signupAuthority}`;
                session = {
                    current: {
                        ...state.current,
                        ...userData,
                        x509: {
                            ...state.current.x509,
                            ...userData.x509
                        }
                    },
                    directory: [
                        ...directory, {
                        id: accountId
                    }]
                }
                localStorage.setItem('session', JSON.stringify(session));
                localStorage.setItem(`session-${accountId}`, JSON.stringify(userData));
            }
            loadAxios(userData.jwt)
            
            return {
                ...state,
                ...session
            }
        }
        case LOGOUT: {
        
            if(state.directory.length === 1) {
                localStorage.clear();
            } else {
                const {x509} = state.current;
                const currentId = `${x509.data}-${x509.authority}-${x509.signupAuthority}`;
                const directory = state.directory.filter(d => d.id !== currentId);  
                
                if(directory.length === 0) {
                    localStorage.clear();
                    window.location.reload();
                    return;
                }

                const session = {
                    current: JSON.parse(localStorage.getItem(`session-${directory[0].id}`)),
                    directory
                }
                localStorage.removeItem(`session-${currentId}`);
                localStorage.setItem('session', JSON.stringify(session));
            }
            window.location.reload();
            return;
        }
        case GO_TO_APP1: {
            const ticket = window.location.href.split('/')[4];
            let searchParams = window.location.search;
            searchParams = searchParams.includes('source=app2') ? searchParams : `?source=app2&${searchParams.replace('?', '')}`;            
            const host = window.app1Host || process.env.REACT_APP_APP1;
            window.location.href = `https://${host}/pdf/${ticket}/${searchParams}`
            return state;
        }
        case SET_CLIENT_DATA: {
            const clientData = action.payload;
            const current = {
                ...state.current,
                clientData
            }          
            return {
                ...state,
                current
            }
        }
        case SET_SIGNATURE_STROKE: {
            const signatureStroke = action.payload;
            const current = {
                ...state.current,
                signatureStroke
            }
            return {
                ...state,
                current
            }
        }
        case SET_SIGNATURE_STROKE_IMAGE: {
            const signatureStrokeImage = action.payload;
            const session = {
                ...state,
                current: {
                    ...state.current,
                    signatureStrokeImage
                }
            }
            
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem(`session-${session.current.x509.data}-${session.current.x509.authority}-${session.current.x509.signupAuthority}`, JSON.stringify(session.current));
            
            return {
                ...state,
                ...session
            }
        }
        case SET_SIGNATURE_IMAGE: {
            const signatureImage = action.payload;
            const current = {
                ...state.current,
                signatureImage
            };
            return  {
                ...state,
                current
            }
        }
        default: {
            return state;
        }
    }
}