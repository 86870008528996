import React from 'react';
import {Modal, ModalBody} from 'reactstrap'
import FlexColumn from './FlexColumn';
import styles from './SignersReportDesktop.module.css';
import CustomModalHeader from './CustomModalHeader';

import { useTranslation } from 'react-i18next';

export default function CertificateReportModal({cert, onClose}) {

    const {t} = useTranslation()

    return (
        <React.Fragment>
            {
                cert ? 
                <Modal isOpen={true} toggle={onClose}>
                    <CustomModalHeader toggle={onClose}>
                        {t('certificate')}
                    </CustomModalHeader>
                    <ModalBody className={styles.body}>
                        <div className={styles.detailContent}>
                            <FlexColumn>
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('name')}</span><br/>
                                    {cert.commonName}
                                </div>
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('email')}</span><br/>
                                    {cert.email}
                                </div>
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('country')}</span><br/>
                                    {cert.country}
                                </div>
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('serialNumber')}</span><br/>
                                    <span style={{wordBreak:'break-all'}}>{cert.serial}</span>
                                </div>
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('date')}</span><br/>
                                    {cert.validationDate}
                                </div> 
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('issuer')}</span><br/>
                                    {cert.issuerCommonName}
                                </div>                                                                
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('issuerLocation')}</span><br/>
                                    {cert.issuerLocation}
                                </div>
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('issuerCountry')}</span><br/>
                                    {cert.issuerCounty}
                                </div>                
                                <div className={styles.detailSection}>
                                    <span className={styles.detailTitle}>{t('algorithm')}</span><br/>
                                    {cert.signatureAlgorithm}
                                </div>                
                            </FlexColumn>
                        </div>
                    </ModalBody>
                </Modal> : <div></div>
            }
        </React.Fragment>        
    )
}