import axios from 'axios';

const backend = `https://${process.env.REACT_APP_HOST}`

function get(path, options) {
    return axios.get(backend + path, {
        ...options,
        withCredentials: true
    });
}


function postMultipart(path, data) {
    return axios.post(backend + path, data, {
        headers: {
            'Content-Type':'multipart/form-data'
        },
        onUploadProgress: e => {
            let progress = Math.floor((e.loaded * 100) / e.total);
        }
    })
}

function post(path, data, options) {
    return axios.post(backend + path, data, {
        ...options,
        withCredentials: true
    });
}

export default {
    backend, get, post, postMultipart
}