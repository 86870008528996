import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import FlexRow from './FlexRow'
import styles from './ViewerControls.module.css'
import {IS_MOBILE} from '../Consts'
import useMaxSignaturesReached from '../hooks/useMaxSignaturesReached';

const containerClass = IS_MOBILE ? styles.containerMobile : styles.container;

export default function ViewerControls({scale, onZoomIn, onZoomOut}) {

    const maxSignaturesReached = useMaxSignaturesReached();

    return (
        <FlexRow className={containerClass}
            style={
                IS_MOBILE && maxSignaturesReached ? {
                    top: '150px'
                } : {}
            }>            
            <FontAwesomeIcon icon={faSearchPlus} className={styles.control} onClick={onZoomIn}></FontAwesomeIcon>
            <div style={{width:'20px'}}></div>
            <FontAwesomeIcon icon={faSearchMinus} className={styles.control} onClick={onZoomOut}></FontAwesomeIcon>
        </FlexRow>
    )
}