import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

function TaskComponent({fn, label, description}) {

    const {t} = useTranslation()
    const Component = fn;

    return <Component label={t(label)} description={t(description)}></Component>

}

export default function LoadingTasks() {

    const {t} = useTranslation()
    const {tasks} = useSelector(state => state.tasks)
    const [isOpen, setIsOpen] = useState(tasks.length > 0);

    const [tasksTasks, errorTasks] = tasks.reduce(
     (pv, cv) => {
        const typeIdx = cv.type === 'task' ? 0 : 1;
        pv[typeIdx].push(cv);
        return pv;
     }, [[],[]])
    const hasError = errorTasks.length > 0;
    const currentTask = tasksTasks.length > 0 ? tasksTasks[tasksTasks.length - 1] : null;
    const currentError = errorTasks.length > 0 ? errorTasks[errorTasks.length - 1] : null;

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsOpen(tasks.length > 0);
        }, 1000)
        
        return () => clearTimeout(timeout);
    }, [tasks.length])

    return (
        <Modal isOpen={isOpen} className="LoadingTasks">
          <ModalBody>
            <div className='Task imageDialog'>
                <img src={`${process.env.REACT_APP_CDN_PATH}/images/trabajando.svg`}/>
                <div className="imageDialogSeparator"></div>                    
                <p className='imageDialogTitle'>
                    {t(hasError ? '' : 'loading')}
                </p>
                {
                    tasksTasks.length > 0 ?
                    <div>
                        <p className={`loading-task-${currentTask.type}`}>{t(currentTask.description)}</p>
                        {
                            currentTask.component && <TaskComponent 
                                fn={currentTask.component}
                                label={currentTask.label}
                                description={currentTask.description}
                                ></TaskComponent>
                        }
                    </div> : null
                }
                {
                    errorTasks.length > 0 ?
                    <div>
                        <p className={`loading-task-${currentError.type}`}>{t(currentError.description)}</p>
                        {
                            currentError.component && <TaskComponent 
                                fn={currentError.component}
                                label={currentError.label}
                                description={currentError.description}
                                ></TaskComponent>
                        }
                    </div> : null
                }
                <br/>
            </div>
          </ModalBody>
        </Modal>
    )
}