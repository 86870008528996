import React, { useRef, useState } from 'react'
import styles from './SignatureCanvas.module.css'
import ResponsiveModal from './ResponsiveModal'
import FlexColumn from './FlexColumn'
import FlexRow from './FlexRow'
import SolidButton from './SolidButton'

import { useTranslation } from 'react-i18next'


function readAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = function(evt) {
            resolve(evt.target.result)
        }
        fileReader.readAsArrayBuffer(file);
    })
}

function readAsBase64(buffer) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([buffer], {type: 'image/png'});
        const fileReader = new FileReader();
        fileReader.onload = function(evt) {
            resolve(evt.target.result)
        }
        fileReader.readAsDataURL(blob);
    })
}


export default function UploadImageModal({isOpen, onCancel, onImageSelected}) {

    const {t} = useTranslation()
    const inputRef = useRef(null);
    const [error, setError] = useState(null);

    async function handleFileInputChange(event) {
        if(event.target.files.length === 0) {
            return;
        }
        const f = event.target.files[0];
        if(f) {

            if(!f.name.toLowerCase().endsWith('.png')) {
                setError(<div>{t('uploadPNG')}</div>);
                return;
            }

            const buffer = await readAsArrayBuffer(f);

            if(buffer.byteLength > 1024 * 1024) {
                setError(<div>{t('uploadSize')}</div>)
                return;
            }

            const b64Data = await readAsBase64(buffer);
            onImageSelected(b64Data)

        } else {
            onImageSelected(null)
        }        
    }


    function handleClickUpload() {
        inputRef.current.click();
    }

    return (
        <ResponsiveModal isOpen={isOpen} title={'uploadSize'}>
            <div className={styles.container}>
                <FlexColumn>
                    <p>
                        {t('uploadNotice2')}
                    </p>
                    <p>
                        {t('uploadNotice3')}
                    </p>
                    {
                        error ?
                        <p style={{color:'red', fontWeight:'bold'}}>{error}</p> : null
                    }
                    <p>
                        {t('uploadNotice4')}
                    </p>
                    <FlexRow justifyContent="flex-end" flexWrap="nowrap">
                        <SolidButton onClick={handleClickUpload}>{t('upload')}</SolidButton>
                        <SolidButton color="secondary" onClick={onCancel}>{t('cancel')}</SolidButton>                
                    </FlexRow>
                </FlexColumn>
                <input type="file" ref={inputRef} 
                    onChange={handleFileInputChange} 
                    accept=".png"
                    multiple={false}
                    style={{display:'none'}}></input>
            </div>
        </ResponsiveModal>
    )

}