import { useEffect } from "react";
import { useHistory } from "react-router-dom";


export default function useTracking(trackingId) {

    const { listen } = useHistory();

    useEffect(() => {
        const cancelListener = listen(location => {
            
            if(!window.gtag || !trackingId) return;

            window.gtag('config', trackingId, {
                page_path: `${location.pathname}${location.search}`
            })

            return cancelListener;

        }, [trackingId, listen])
    })

}