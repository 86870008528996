import React from 'react';
import ReactDOM from 'react-dom'
import FlexRow from './FlexRow'
import styles from './ConfirmCancel.module.css'
import {IS_MOBILE} from '../Consts';
import SolidButton from './SolidButton';
import { useTranslation } from 'react-i18next';
import PulseButton from './PulseButton';

const containerClass = IS_MOBILE ? styles.containerMobile : styles.container;

export default function ConfirmCancel({confirmLabel, onConfirm, onCancel, disabled, disabledLabel}) {

    const {t} = useTranslation();
    const cancelLabel = disabled ? (disabledLabel || 'Cancelar') : 'Cancelar';


    return ReactDOM.createPortal(        
        <FlexRow className={`${containerClass}`}>            
            <SolidButton color="secondary" onClick={onCancel} noMargin>{t(cancelLabel)}</SolidButton>
            {
                !disabled ? <PulseButton onClick={onConfirm} noMargin>{t(disabled ? disabledLabel : confirmLabel)}</PulseButton> :null
            }
        </FlexRow>,
        document.getElementById('root')
    )

}