import React, {useState, useEffect} from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Input} from 'reactstrap'
import InputPassword from './InputPassword';
import {useSelector, useDispatch} from 'react-redux';
import forge from '../crypto/forge'
import {savePassphrase} from '../redux/actions'
import SolidButton from './SolidButton';
import CustomModalHeader from './CustomModalHeader';

import { useTranslation } from 'react-i18next';

export default function PasswordModal({isOpen, onComplete, onCancel}) {

    const {t} = useTranslation()
    const dispatch = useDispatch(); 
    const [pass, setPass] = useState('');
    const [error, setError] = useState('')
    const savedPassphrase = useSelector(state => state.session.current.passphrase)
    const [rememberPass, setRememberPass] = useState(!!pass);
    const pkcs8 = useSelector(state => state.session.current.pkcs8);

    useEffect(() => {
        if(isOpen && savedPassphrase) {
            onComplete(savedPassphrase);
        }
    }, [isOpen, savedPassphrase, onComplete])

    function handleChange(event) {
        if(error !== '') {
            setError('');
        }
        setPass(event.target.value)
    }

    function handleClickComplete() {
        
        let pk = null;
        try {
            pk = forge.pki.decryptRsaPrivateKey(pkcs8.pem, pass);
        } catch(e) {
            // contrasena incorrecta
        }

        if(!pk) {
            setError(<span>{t('incorrectPassword')}</span>);
            return;
        }

        const validatedPass = pass;
        setPass('');
        setError('')
        if(rememberPass) {
            dispatch(savePassphrase(pass));
        }
        onComplete(validatedPass)                
    }

    function handleClickCancel() {
        setPass('');
        setError('')
        onCancel();
    }

    function handleRememberPassChange(event) {
        setRememberPass(event.target.checked)
    }

    return (
        <Modal isOpen={isOpen && !savedPassphrase}>
            <CustomModalHeader>{t('enterPassword')}</CustomModalHeader>
            <ModalBody style={{textAlign:'center', paddingTop:'20px'}}>
                <InputPassword value={pass} onChange={handleChange}></InputPassword>
                <p style={{fontWeight:'bold', color:'red'}}>{error}</p>
                <FormGroup check style={{
                    textAlign:'right'
                }}>
                    <Label check>
                        <Input type="checkbox" 
                            style={{lineHeight:'24px'}} 
                            value={rememberPass}
                            onChange={handleRememberPassChange}></Input>{' '}
                        {t('rememberPassword')}
                    </Label>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <SolidButton color="primary" noMargin onClick={handleClickComplete}>{t('Aceptar')}</SolidButton>{' '}
                <SolidButton color="secondary" noMargin onClick={handleClickCancel}>{t('Cancelar')}</SolidButton>
            </ModalFooter>
        </Modal>   
    )

}