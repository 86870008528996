import React, {useState} from 'react';
import FlexRow from '../shared/FlexRow'
import {IS_MOBILE} from '../Consts'
import {useSelector, useDispatch} from 'react-redux'
import {changeUser, goToApp1, uploadDocument} from '../redux/actions'
import styles from './TopBar.module.css'
import FlexColumn from './FlexColumn';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, UncontrolledTooltip} from 'reactstrap';
import { AppLogo, usePalette } from './Customizables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcertiaInfo from './acertia/Info';
import Contacto from './Contacto';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

export default function TopBar() {

    const {t} = useTranslation()
    const session = useSelector(state => state.session)
    const {companyComponents} = useSelector(state => state.customizables)
    const { report } = useSelector(state => state.document);
    const [isDirectoryOpen, setDirectoryOpen] = useState(false);    
    const dispatch = useDispatch();
    const palette = usePalette();

    const {x509, clientData} = session.current;
    const currentId = x509 ? `${x509.data}-${x509.authority}-${x509.signupAuthority}` : ''

    function handleClickChangeUser(userId) {
        return function(event) {
            dispatch(changeUser(userId));
        }
    }

    function toggleDirectory() {
        if(!isDirectoryOpen && session.directory.length > 1) {
            setDirectoryOpen(true);
        } else {
            setDirectoryOpen(false);
        }
    }

    function handleFileSelected(file) {
        if(file) {
            dispatch(uploadDocument({file}))
        }
    }

    function handleClickUpload() {
        dispatch(goToApp1())
    }

    function openTabSupport() {
        window.open("https://firmamex.atlassian.net/servicedesk/customer/portal/3");
    }

    return (
            IS_MOBILE ? null : 
            <FlexRow className={styles.container}
                style={{
                    background: palette.darkBackground,
                    borderBottomColor: palette.dividerColor,
                    color: palette.secondaryText
                }}>
                <div style={{'flexGrow':1}}>
                    <AppLogo></AppLogo>
                </div>
                {
                    x509 && x509.data ?
                    <Dropdown isOpen={isDirectoryOpen} toggle={toggleDirectory} className={`${styles.dropdownToggle}`}>
                        <DropdownToggle tag="div">
                            <InputGroup>
                                <FlexRow className={styles.userData} >
                                        <div className={styles.verticalSeparator}></div>
                                        <FlexColumn flexGrow="unset" style={{padding:'10px'}}>
                                            <div className={styles.userName}>
                                                {x509.data}
                                            </div>
                                            <div style={{fontSize:'10px'}}>
                                            {t(x509.signupAuthority)}
                                            </div>
                                            {
                                                clientData ? 
                                                <div style={{fontSize:'10px'}}>
                                                    {clientData.name}
                                                </div> : null
                                            }
                                        </FlexColumn>
                                        {
                                            session.directory.length > 1 ?
                                            <div className={styles.iconDropdown}>
                                                <img src={`${process.env.REACT_APP_CDN_PATH}/images/icon_dropdown.svg`} alt="user accounts"></img>
                                            </div> : 
                                            <div className={styles.iconDropdown}>
                                            </div>
                                        }                                           
                                </FlexRow>
                            </InputGroup>
                        </DropdownToggle>
                        <DropdownMenu right className={styles.dropdownMenu}>
                            {
                                session.directory.filter(u => u.id !== currentId).map((u, i) => {
                                    // 0 - certData
                                    // 1 - authority
                                    // 2 -  signupAuthority
                                    const data = u.id.split('-');
                                    return (
                                        <React.Fragment key={u.id}>
                                            <DropdownItem 
                                                onClick={handleClickChangeUser(u.id)}
                                                className={styles.dropdownItem}>
                                                <FlexColumn>
                                                    <div style={{fontWeight:'bold'}}>{data[0]}</div>
                                                    <div>{data[2]}</div>
                                                </FlexColumn>
                                            </DropdownItem>
                                            {
                                                i < session.directory.length - 2 ? 
                                                <DropdownItem divider /> : null
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </Dropdown>                    
                    : null
                }             
                {/* {
                    session.current && session.current.uid ? 
                    <SelectFileButton onFileSelected={handleFileSelected}></SelectFileButton> : null
                }                 */}
                {
                    (companyComponents === "Acertia") ? <AcertiaInfo /> : null
                }
                {
                    report && report.blockDownload === false ?
                    <div onClick={handleClickUpload} style={{
                        margin:'0 15px 0 0',
                        fontSize:'20px',
                        cursor:'pointer'
                    }}>
                    
                    {
                        (companyComponents === "Acertia") ?  <React.Fragment>
                            <FontAwesomeIcon 
                                icon={faUpload} 
                                id="topbarUpload" 
                                style={{display: "none"}}
                            >
                            </FontAwesomeIcon>
                        </React.Fragment>
                         :
                        <FontAwesomeIcon icon={faUpload} id="topbarUpload"></FontAwesomeIcon>
                    }
                    <UncontrolledTooltip target="topbarUpload">
                        {t('upload')}
                    </UncontrolledTooltip>    
                </div> : null
                }
                {
                    (companyComponents === "Firmamex") ? <Contacto /> : null
                }  
            </FlexRow>        
    )
}
