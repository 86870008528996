
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'reactstrap';
import FlexColumn from './FlexColumn';
import FlexRow from './FlexRow';
import SolidButton from './SolidButton';
import HttpRequest from './HttpRequest'
import useHttpGet from '../hooks/useHttpGet';

const PIN_LENGTH = 6;
const PINS = [...Array(PIN_LENGTH).keys()]    

function ThrottleButton({children, disabled, throttle, onClick, ...rest}) {

    const [timer, setTimer] = useState(0)

    useEffect(() => {

        if(timer === 0) {
            return;
        }

        const timeout = setTimeout(() => {
            setTimer(timer - 1)
        }, 1000)

        return () => {
            if(timeout) {
                clearTimeout(timeout)
            }
        }

    }, [timer])

    function handleClick() {
        onClick()
        setTimer(throttle)
    }

    return (
        <Button {...rest} 
            disabled={disabled || timer > 0}
            onClick={handleClick}>{children}</Button>
    )

}

export default function PinInput({ticket, sticker, onSuccess, onCancel}) {

    const {t} = useTranslation()
    const [pin, setPin] = useState([...Array(PIN_LENGTH)].map(i => ''))
    const [error, setError] = useState('')
    const [firstTryDone, setFirstTryDone] = useState(false);
    const [working, doVerify, ,,] = useHttpGet();
    const refs = useRef([])    

    useEffect(() => {
        HttpRequest.get(`/pin/${ticket}/${sticker._id}`)
    }, [])

    useEffect(() => {
        
        if(pin.find(p => !p) === undefined && !firstTryDone) {
            setFirstTryDone(true)
            handleClickVerify()
        }

    }, [pin, firstTryDone])

    useEffect(() => {

        if(refs.current[0]) {
            refs.current[0].focus()
        }

    }, [refs.current[0]])

    function handleSetRef(i) {
        return function(ref) {
            refs.current[i] = ref;            
        }
    }

    function handlePinChange(i) {
        return function(e) {
            let val = e.key;

            if(val === 'Backspace') {
                if(val && i > 0) {
                    refs.current[i - 1].focus()
                    refs.current[i - 1].select()
                }
                return;
            }

            if(e.type !== 'change') {
                return;
            }

            val = e.target.value;
            if(val === '' || isNaN(val)) {
                return;
            }            

            val = val[val.length - 1]

            const prev = [...pin];
            prev[i] = val;
            setPin(prev);

            if(val && i + 1 < PIN_LENGTH) {
                refs.current[i + 1].focus()
                refs.current[i + 1].select()
            } 

        }
    }

    async function handleClickVerify(e) {
        const response = await doVerify(`/pin/${pin.join('')}/verify/${sticker._id}`)
        if(!response.valid) {
            setError('invalid pin')
            return;
        } 
        setPin([...Array(PIN_LENGTH)].map(i => ''))
        setError('')
        onSuccess(pin.join(''))
    }

    function handleClickResend() {
        HttpRequest.get(`/pin/${ticket}/${sticker._id}`)
    }

    return (
        <FlexColumn>
            <p>{t('please type pin', {pin: sticker.data})}</p>
            <FlexRow flexWrap="nowrap">
            {
                PINS.map(i => (
                    <Input className="pinNumber"
                        key={i}
                        onClick={e => {
                            e.target.select()
                        }}
                        type="number"
                        innerRef={handleSetRef(i)}
                        maxLength="1"
                        value={pin[i]}
                        onChange={handlePinChange(i)}
                        onKeyDown={handlePinChange(i)}></Input>
                ))
            }
            </FlexRow>
            <br/>
            {
                error ? <p style={{fontWeight:'bold', color:'red'}}>{t(error)}</p> : null
            }
            <br/>
            <FlexRow justifyContent="center" alignItems="center">
                <SolidButton color="primary" noMargin 
                    disabled={pin.find(p => !p) !== undefined || working}
                    onClick={handleClickVerify}>{t('accept')}</SolidButton>{' '}
                <SolidButton color="secondary" noMargin onClick={onCancel}>{t('cancel')}</SolidButton>
            </FlexRow>
            <br/>
            <FlexRow justifyContent="center" alignItems="center">
                <ThrottleButton color="link" throttle={30} onClick={handleClickResend}>{t('Reenviar')}</ThrottleButton>{' '}
            </FlexRow>
        </FlexColumn>
    )
}