import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { getPageLayer } from '../utils/toolbox';

export default function SignatureImgLayer({page, signatures, pageRendered, onClick}) {

    const [container] = useState(document.createElement('div'))
    const [pageHeight, setPageHeight] = useState(0)  

    useEffect(() => {
        if(pageRendered) {
            const pageLayer = getPageLayer(page)        
            if(!pageLayer) {
                return;
            }
            setPageHeight(pageLayer.offsetHeight)
            container.className = 'signatureLayer'
            pageLayer.appendChild(container);
            return () => {
                pageLayer.removeChild(container);
            }
        }
    }, [pageRendered, container, page])    

    return ReactDOM.createPortal((
        <div className="images">
            {
                signatures.map((s, i) => (
                    <div 
                        key={i}
                        onClick={onClick(s)}
                        style={{
                        position: 'absolute',
                        top:  pageHeight - s.top,
                        left: s.left,
                        width: s.width,
                        cursor:'pointer',
                        height: s.height}}></div>
                ))
            }
        </div>), container)
}