
import { SET_CUSTOMIZABLES, SET_CUSTOMIZABLES_DEFAULT } from '../actionTypes'
import { StyleSheet, css } from 'aphrodite';
import buildRadioStyles from './styles/radio';
import buildStickerTooltipStyles from './styles/stickerTooltip'

const initialState = {
    logo: `${process.env.REACT_APP_CDN_PATH}/images/full_logo.svg`,
    logoSignup: `${process.env.REACT_APP_CDN_PATH}/images/full_logo.svg`,
    imgSticker: `${process.env.REACT_APP_CDN_PATH}/images/es_sign_here.png`,
    imgSuccess: `${process.env.REACT_APP_CDN_PATH}/images/firmado.svg`,
    title: process.env.REACT_APP_TITLE,
    showLabel: false,
    askForFeedback: true,
    hideConfirmation: false,
    palette: {
        dividerColor: '#e0e8f1',
        secondaryColor: '#0966C9',
        lightSecondaryColor: '#f6faff',
        primaryText: '#5a5a5a',
        secondaryText: '#484848',
        contentBackground: 'rgb(246, 246, 246)',
        darkBackground: 'rgb(246, 246, 246)',
        lightBackground: '#fafafa',
        lightBackgroundSignup: '#f7fbff',
        secondaryAction: '#6c757d',
        mainAction: '#0966C9',
        accentText: '#0966C9',
        validColor: '#2b98f0',
        mobileTopBar: '#0966c9',
        mobileBadge: '#FF0000',
        mobileTopBarText: '#FAFAFA',
        stickerBackground: '#D7E8FA',
        stickerRejectedBackground: '#ffd5d5',
        stickerInactive: '#000000',
        styles: StyleSheet.create({
            ...buildRadioStyles({color: '#0966C9'}),
            ...buildStickerTooltipStyles({background: '#0966C9'})
        })
    },
    customStyle: undefined,
    companyComponents: 'Firmamex'
}

export default function(state = initialState, action) {
    
    switch(action.type) {
        case SET_CUSTOMIZABLES_DEFAULT: {
            const params = action.payload || {};
            document.title = initialState.title;
            return {
                ...initialState, ...params, customStyle:false,
            }
        }
        case SET_CUSTOMIZABLES: {
            document.title = action.payload.title;
            const {palette, favicon, ...rest} = action.payload;
            
            if(favicon) {
                const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = favicon;
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            
            return {
                ...initialState,
                ...rest,
                palette: { 
                    ...initialState.palette,                   
                    ...palette,
                    styles: StyleSheet.create({
                        ...buildRadioStyles({color: palette.secondaryColor}),
                        ...buildStickerTooltipStyles({background: palette.secondaryColor})
                    })
                }
                
            }
        }
        default: {
            return state;
        }
    }

}