import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'

// function handleSuccess(callback) {
//     return function (response) {
//         callback({
//             lat: response.coords.latitude,
//             lng: response.coords.longitude
//         })
//     }
// }

const GEOLOCATE_OPTIONS = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
};


export default function useGeoLocate() {

    const [locationAllowed, setLocationAllowed] = useState(null)
    
    function _requestLocation(callback) {
        navigator.geolocation.getCurrentPosition(
            function(response) {
                setLocationAllowed(true)
                callback({
                    lat: response.coords.latitude,
                    lng: response.coords.longitude
                })
            }, function(error) {
                if (error.code == error.PERMISSION_DENIED){                    
                }
                setLocationAllowed(false)
                setTimeout(() => _requestLocation(callback), 1000)
            }, GEOLOCATE_OPTIONS);   
    }

    function requestLocation() {
        return new Promise((resolve, reject) => {
            
            if (navigator.geolocation) {

                _requestLocation(resolve);
                 
            } else {
                setLocationAllowed(false)
            }          
        })        
    }

    return [requestLocation, locationAllowed]

}