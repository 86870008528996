import React, {useState, useEffect, useCallback, Suspense} from 'react';
import PdfRenderer from './PdfRenderer'
import FlexColumn from './shared/FlexColumn'

import {useSelector, useDispatch} from 'react-redux'
import {
    loadReport,
    setVerifyingUser,
    fetchCustomizables,
    setSigningSticker,
    loadOAuthCertificate,
    clearActiveSticker
} from './redux/actions'
import SignatureModal from './shared/SignatureModal';
import { usePalette } from './shared/Customizables';
import AcertiaSideBar from './shared/acertia/AcertiaSideBar';
import { useTranslation } from 'react-i18next';
import { buildQueryParams } from './utils/toolbox';
import qs from 'qs';


export default function Main({match, location, history}) {
    
    const signableStickers = useSelector(state => state.signableStickers.signableStickers);
    const activeSignerFound = useSelector(state => state.signableStickers.activeSignerFound);
    const report = useSelector(state => state.document.report)
    const verifyingUser = useSelector(state => state.status.verifyingUser)
    const companyComponents = useSelector(state => state.customizables.companyComponents) 
    const dispatch = useDispatch()
    const palette = usePalette();
    const {i18n} = useTranslation()
    const [signer, setSigner] = useState('');
    const [activeSigner, setActiveSigner] = useState('');


    useEffect(() => {
        if(companyComponents === 'Acertia') {
            i18n.changeLanguage('es')
        }
    }, [companyComponents, i18n])

    useEffect(() => {
        if(verifyingUser.working && !location.pathname.includes('signup') && 
            match.params.ticket === verifyingUser.data.ticket) {
                // el usuario se quedo en el paso de verificacion y volvio a entrar al mismo
                // documento, lo mandamos a la pantalla de verificacion            
                history.push(`/pdf/${match.params.ticket}/signup${buildQueryParams({
                    stickerId: verifyingUser.data.stickerId
                })}`)    
                //history.push(`/pdf/${match.params.ticket}/signup?stickerId=${verifyingUser.data.stickerId}`);
        } else if(verifyingUser.working && match.params.ticket !== verifyingUser.data.ticket) {
            // el usuario se quedo en el proceso de verificacion pero entro
            // a otro documento, olvidamos el proceso guardado
            setVerifyingUser({
                verifyingUser: {
                    working: false,
                    data: {}
                }
            })
        }
        
        if(location.search.includes('activeSigner')) {
            const parsedQueryString = qs.parse(location.search, { ignoreQueryPrefix: true });
            if(parsedQueryString.activeSigner && !parsedQueryString.signerLink) {
                let activeSignerLower = parsedQueryString.activeSigner.toLocaleLowerCase();
                setActiveSigner(activeSignerLower);
            }
        }
    }, [verifyingUser, history, location.pathname, match.params.ticket, location.search, activeSignerFound])

    useEffect(() => {
        if(report.ticket !== match.params.ticket) {
            dispatch(loadReport(match.params.ticket, location.search));
            dispatch(fetchCustomizables(match.params.ticket))
            dispatch(loadOAuthCertificate(match.params.ticket))
        }    
    }, [match.params.ticket, location.search, dispatch, report.ticket])

    return (
        <FlexColumn alignItems="center" className="MainContainer" id="MainContainer" style={ companyComponents === "Acertia" ? {background: "#E9E5DE"} : {background: palette.lightSecondaryColor}}>            
            <PdfRenderer ticket={match.params.ticket} 
                report={report} 
                signableStickers={signableStickers}
                signerData={signer}
                activeSigner={activeSigner}
                activeSignerFound={activeSignerFound}>
            </PdfRenderer>
            <Suspense fallback={<div></div>}>
                <SignatureModal></SignatureModal>
            </Suspense>
        </FlexColumn>
    )

}