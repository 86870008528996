import React, { useState, useEffect, useReducer } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Progress } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { hideAlert } from '../redux/actions';
import Spinner from './Spinner';
import FlexColumn from './FlexColumn'
import HttpRequest from './HttpRequest';
import FlexRow from './FlexRow';
import {withRouter} from 'react-router-dom'

import { useTranslation } from 'react-i18next';

const initialState = {
    uploadProgress: 0,
    status: 'UPLOADING',
    ticket: null
}

const ACTIONS = {
    SET_PROGRESS: 'SET_PROGRESS',
    SET_WAITING: 'SET_WAITING'
}

function reducer(state, action) {
    switch(action.type) {
        case ACTIONS.SET_PROGRESS: {
            const {progress} = action.payload;
            return {
                ...state,
                uploadProgress: progress
            }
        }
        case ACTIONS.SET_WAITING: {
            const {ticket} = action.payload;
            return {
                ...state,
                status: 'WAITING',
                ticket
            }
        }
        default: {
            return state;
        }
    }
}


export default withRouter(function UploadDocumentModal({file, isOpen, history}) {

    const {t} = useTranslation()
    const globalDispatch = useDispatch();
    const [{uploadProgress, status, ticket}, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {

        async function uploadDocument() {
            const response = await HttpRequest.post(`/browser/pdf/${encodeURIComponent(file.name)}`, 
                file.blob,
                {   
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                    onUploadProgress: function(progressEvent) {
                        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch({
                            type: ACTIONS.SET_PROGRESS,
                            payload: {
                                progress: percentCompleted
                            }
                        })
                    }
                })
            dispatch({
                type: ACTIONS.SET_WAITING,
                payload: {
                    ticket: response.data.ticket
                }
            })
        }

        if(file) {
            uploadDocument();
        }

    }, [file])

    useEffect(() => {
        let timeout = null;

        async function checkDocStatus() {
            const response = await HttpRequest.get(`/document/${ticket}/exists`);
            if(response.data.exists) {
                history.push(`/pdf/${ticket}`)
            } else {
                    timeout = setTimeout(() => {
                    checkDocStatus()
                }, 1000);
            }
        }

        if(status === 'WAITING') {                                                    
            checkDocStatus();        
        }

        return () => {
            if(timeout) {
                clearTimeout(timeout);
            }
        }

    }, [status, ticket, history])

    function handleClick() {
        dispatch(hideAlert());
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <br/>
                <FlexColumn style={{textAlign:'center'}}>
                    {
                        status === 'UPLOADING' ? 
                        <React.Fragment>
                            <p>{t('uploadingDocument')}</p>
                            <Progress value={uploadProgress}></Progress> 
                        </React.Fragment> : null
                    }                    
                    {
                        status === 'WAITING' ?
                        <React.Fragment>
                            <p>{t('preparingDocument')}</p>
                            <FlexRow justifyContent="center">
                                <Spinner show={isOpen}></Spinner>   
                            </FlexRow> 
                        </React.Fragment> : null
                    }
                </FlexColumn>
            </ModalBody>
        </Modal>
    )

})