import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Signature from '../crypto/Signature'
import { css } from 'aphrodite';
import styles from './Sticker.module.css';
import {setActiveSticker, setActiveStickerImage, changeUser, changeUserAndActivateSticker} from '../redux/actions'
import {IS_MOBILE} from '../Consts';
import {setPickingSignatureStyle} from '../redux/actions'
import { checkStickerOrder } from '../utils/toolbox';
import { ImgSticker, usePalette } from './Customizables';
import useEditable from '../hooks/useEditable';
import ParsedCertificate from '../crypto/ParsedCertificate';
import useMaxSignaturesReached from '../hooks/useMaxSignaturesReached';

import { useTranslation } from 'react-i18next';

export default function Sticker({pageHeight, lx, ly, width, height, page, signing, authority, data, line, _id, imageType, order, onClickOwner, rejected, editable, activeSigner, activeSignerFound}) {    

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {ordered, nextSigner} = useSelector(state => state.document.report);
    const session = useSelector(state => state.session);
    const activeSticker = useSelector(state => state.signableStickers.active);  
    const appStatusSigning = useSelector(state => state.status.signing);
    const [imgPos, setImgPos] = useState(null)
    const [tooltipOpen, setTooltipOpen] = useState(false)    
    const [signatureImg, setSignatureImg] = useState(null);
    const maxSignaturesReached = useMaxSignaturesReached();
    const palette = usePalette();
    const [dataLower, setDataLower] = useState('');

    useEffect(() => {
        if(activeSticker && signing) {  
            setSignatureImg(activeSticker.img);
            return
        }
        setSignatureImg(null);
        setDataLower(data.toLowerCase())
    }, [activeSticker, signing])
    

    useEffect(() => {
        if(signatureImg) {
            const signaturePos = Signature.getPosFromSticker(signatureImg, {width, height, lx, ly, page, line});            
            setImgPos({
                width: signaturePos.width,
                height: signaturePos.height,
                top: signaturePos.yDiff,
                left: signaturePos.xDiff
            })
        }
    }, [signatureImg, height, line, page, lx, ly, width])


    async function handleClick() {        
        
        if(activeSigner && activeSigner !== data.toLowerCase()) {
            return
        }

        if(editable) {
            // if the document is editable, we need to show this sticker dialog
            // onClickOwner();
            // return;
        }

        if(maxSignaturesReached) {
            return
        }

        if((!activeSticker || activeSticker.status === 'click') && !signing) {
            for(let u of session.directory) {
                const [stickerData, , signupAuthority] = u.id.split('-');
                if(stickerData !== session.current.x509.data || signupAuthority !== session.current.x509.signupAuthority) {
                    // no es la sesion actual
                    if(authority.uiName === signupAuthority && data.toLowerCase() === stickerData.toLowerCase()) {
                        // se encontro un sticker que se puede firmar en otra cuenta 
                        // if(checkStickerOrder({ordered, nextSigner}, {order})) {
                            dispatch(changeUserAndActivateSticker(u.id, _id, 'click', authority.singleUse));
                            return;
                        // }
                    }    
                }                   
            }
            dispatch(setActiveSticker(_id, 'click', authority.singleUse))
        } else if(signatureImg && imgPos && (!imageType || imageType.includes('any'))) {
            const issuerName = session.current.x509.authority;
            const cert = ParsedCertificate.fromPem(session.current.x509.pem);
            const commonName = cert.getCommonName();
            const imageData = await new Promise((resolve, reject) => {
                dispatch(setPickingSignatureStyle({
                    pickingSignatureStyle: {
                        working: true,
                        data: {
                            issuerName,
                            commonName,
                            stickerId: _id,
                            requestedImageType: imageType,
                            resolve,
                            reject
                        }
                    }
                }))
            })
            dispatch(setPickingSignatureStyle({
                pickingSignatureStyle: {
                    working: false,
                    data: {}
                }
            }))
            if(imageData) {
                dispatch(setActiveStickerImage(imageData))
                // setSignatureImg(imageData)
            }

        }
        
    }

    function handleMouseEnter() {
        setTooltipOpen(true);
    }

    function handleMouseLeave() {
        setTooltipOpen(false)
    }

    return (     
        <React.Fragment>
            <div
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={activeSigner ? activeSigner === dataLower ? styles.container : styles.containerDisabled : styles.container } 
                style={{
                position: 'absolute',
                top:  pageHeight - ly - height,
                left: lx,
                width: width,
                height: height
            }}>
                {
                !authority.singleUse && signatureImg && signatureImg.data && imgPos && !imageType && !appStatusSigning.working ?             
                    <div className={css(palette.styles.Tooltip, palette.styles.TooltipData)} 
                        style={{
                            display: 'flex',
                            // alto del tooltip y alto de la flecha del tooltip
                            top: -90 - 30,
                            height:90,
                            width:200,
                            left: width/2 - 200/2 - 10,
                            zIndex:1
                        }}>
                            {t('clickImage')}
                    </div> 
                    : null
                }
                {
                    !signatureImg ? 
                    <div className={`${styles.background} ${ordered && nextSigner !== order ? styles.disabled : ''}`} style={{
                        width: width - 8,
                        height: height - 8,
                        backgroundColor: rejected ? palette.stickerRejectedBackground : palette.stickerBackground,
                        opacity: activeSigner ? activeSigner === dataLower ? 0.8 : 0.3 : 0.8
                    }}>
                        <ImgSticker activeSigner={activeSigner} activeSignerFound={activeSignerFound} data={dataLower}></ImgSticker>
                    </div> : null
                }
                {
                    signatureImg && imgPos ? 
                        <img src={`data:image/png;base64,${signatureImg.data}`}
                            style={{
                                opacity: activeSigner ? activeSigner === dataLower ? '1' : '0.05' : '1',
                                position:'absolute',                            
                                ...imgPos
                            }}
                            alt="signature-img"
                        /> : null
                }             
                <div className={css(palette.styles.Tooltip, palette.styles.TooltipData)} 
                    style={{
                        display: (!IS_MOBILE && !signatureImg && tooltipOpen) ? 'flex' : 'none',
                        // alto del tooltip y alto de la flecha del tooltip
                        top: -90 - 30,
                        height:90,
                        width:200,
                        left: width/2 - 200/2 - 10,
                        zIndex:1
                    }}>
                        {t(authority.uiName)}<br/>
                         {data}
                         {
                             rejected ? <React.Fragment><br/>({t('rejected')})</React.Fragment> : null
                         }
                </div> 
            </div>                        
        </React.Fragment>                           
    )
}