import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react'
import StickerLayer from './shared/StickerLayer';
import SignatureHtmlImgLayer from './shared/SignatureHtmlImgLayer';
import SignatureImgLayer from './shared/SignatureImgLayer';
import DocumentDownloader from './shared/DocumentDonwloader';
import ViewerControls from './shared/ViewerControls'
import PinchZoomPan from './shared/PinchZoomPan'
import useDocument from './hooks/useDocument'
import Page from './shared/Page'
import {useSelector, useDispatch} from 'react-redux'
import useCurrentPage from './hooks/useCurrentPage'
import FlexColumn from './shared/FlexColumn';
import {IS_MOBILE} from './Consts'
import SignButton from './shared/SignButton'
import SignersReport from './shared/SignersReport';
import DraggableLayer from './shared/DraggableLayer';
import AddSignerModal from './shared/AddSignerModal';
import { loadReport, removeDraggable } from './redux/actions';
import useEditable from './hooks/useEditable';
import ApplyStickersButton from './shared/ApplyStickersButton';
import FlexRow from './shared/FlexRow';
import AcertiaSideBar from './shared/acertia/AcertiaSideBar';
import { useTranslation } from 'react-i18next';
import { useUrlParam } from './hooks/useUrlParam';

const AcertiaDocumentTopBar = React.lazy(() => import('./shared/acertia/AcertiaDocumentTopBar'));
const DocumentTopBar = React.lazy(() => import('./shared/DocumentTopBar'));
// altura de las barras superiores
// const HEIGHT = document.body.clientHeight - MAIN_TOP_BAR_HEIGHT - RENDERER_TOP_BAR_HEIGHT + (IS_MOBILE ? 30 : 0);
const ZOOM_STEP_FACTOR = 0.05;
const body = document.body.getBoundingClientRect();  

const sideBarAcertiaExists = document.getElementById("acertia-sidebar")

const initialState = {
    scale: 1,
    maxScale: 1,
    minScale: 1,
    initialScale: null,
    marginLeft: 0,
    originalSize: {
        width:500, height:600
    },
    pageSize: {
        width:500, height:600
    },
    rendererSize: null,
    renderedPages: []
}


const ACTIONS = {
    PAGE_VISIBLE_CHANGE: 'PAGE_VISIBLE_CHANGE',
    PAGE_VISIBLE_CHANGE_ACERTIA: 'PAGE_VISIBLE_CHANGE_ACERTIA',
    CHANGE_SCALE: 'CHANGE_SCALE',
    CHANGE_SCALE_ACERTIA: 'CHANGE_SCALE_ACERTIA',
    CHANGE_DOCUMENT: 'CHANGE_DOCUMENT'
}

function reducer(state, action) {
    switch(action.type) {
        case ACTIONS.CHANGE_SCALE: {
            const {scale} = action.payload;
            if(scale < state.maxScale && scale > state.minScale) {
                let {marginLeft} = state;
                if(state.rendererSize) {
                    marginLeft = Math.max((state.rendererSize.width - (state.originalSize.width * scale))/2, 0);
                }
                return {...state, scale, marginLeft}
            }            
            return {...state};
        }
        case ACTIONS.CHANGE_SCALE_ACERTIA: {
            const {scale} = action.payload;
            if(scale < state.maxScale && scale > state.minScale) {
                let {marginLeft} = state;
                if(state.rendererSize) {
                    marginLeft = Math.max((state.rendererSize.width - (state.originalSize.width * scale) - 220), 0);
                }
                return {...state, scale, marginLeft}
            }            
            return {...state};
        }
        case ACTIONS.PAGE_VISIBLE_CHANGE: {

            const {i, rect, rendererBoundingClient, updatedAt} = action.payload;
            const newState = {...state};
            const newRenderedPages = [...state.renderedPages];
            newRenderedPages[i] = updatedAt;            
            if(rect && i === 0 && !state.originalSize.set) {
                newState.originalSize = {width:rect.width, height:rect.height, set:true};                
                if(rect.width > body.width) {
                    newState.scale = (body.width / rect.width) - ZOOM_STEP_FACTOR;                    
                }  
                
                newState.maxScale = newState.scale + 0.5;
                newState.minScale = newState.scale - 0.1;
                
                if(rendererBoundingClient) {
                    newState.rendererSize = {
                        width: rendererBoundingClient.width,
                        height: rendererBoundingClient.height
                    }                    
                }                
            } 
            
            newState.pageSize = {
                width: newState.originalSize.width * newState.scale,
                height: newState.originalSize.height * newState.scale
            }

            if( newState.rendererSize) {
                // newState.marginLeft = 0
                newState.marginLeft = Math.max((newState.rendererSize.width - newState.pageSize.width)/2, 0);

            }


            return {
                    ...newState, 
                    renderedPages:newRenderedPages,
                    }
        }
        case ACTIONS.PAGE_VISIBLE_CHANGE_ACERTIA: {

            const {i, rect, rendererBoundingClient, updatedAt} = action.payload;
            const newState = {...state};
            const newRenderedPages = [...state.renderedPages];
            newRenderedPages[i] = updatedAt;            
            if(rect && i === 0 && !state.originalSize.set) {
                newState.originalSize = {width:rect.width, height:rect.height, set:true};                
                if(rect.width > body.width) {
                    newState.scale = (body.width / rect.width) - ZOOM_STEP_FACTOR;                    
                }  
                
                newState.maxScale = newState.scale + 0.5;
                newState.minScale = newState.scale - 0.1;
                
                if(rendererBoundingClient) {
                    newState.rendererSize = {
                        width: rendererBoundingClient.width,
                        height: rendererBoundingClient.height
                    }                    
                }                
            } 
            
            newState.pageSize = {
                width: newState.originalSize.width * newState.scale,
                height: newState.originalSize.height * newState.scale
            }

            if( newState.rendererSize) {
                newState.marginLeft = Math.max((newState.rendererSize.width - newState.pageSize.width - 220), 0);

            }

            return {
                    ...newState, 
                    renderedPages:newRenderedPages,
                    }
        }
        case ACTIONS.CHANGE_DOCUMENT: {
            return initialState
        }
        default: {
            return state;
        }
    }
}


export default function PdfRenderer({ticket, report, signerData, activeSigner, activeSignerFound}) {   

    const {t} = useTranslation()
    const {companyComponents} = useSelector(state => state.customizables);
    const rendererRefContainer = useRef(null); 
    const reduxDispatch = useDispatch();
    const [docStatus, {pages, css}] = useDocument(ticket);
    const [{scale, minScale, maxScale, originalSize, rendererSize, renderedPages, marginLeft}, dispatch] = useReducer(reducer, initialState);
    const [currentPage] = useCurrentPage(rendererRefContainer, originalSize, scale)
    const htmlSignatureImgPages = useSelector(state => state.document.htmlSignatureImgPages)
    const signaturePages = useSelector(state => state.document.report.signaturePages)
    const signableStickers = useSelector(state => state.signableStickers.signableStickers);
    const hasDocument = useSelector(state => !!state.document.document) 
    const editable = useEditable();
    const addingDraggable = useSelector(state => state.status.addingDraggable)
    const {uid} = useSelector(state => state.session.current);
    const [signerParam, clearParam] = useUrlParam('signer')

    const [reportState, setReportState] = useState({
        selectedSigner: null,
        isOpen: false
    });

    const [pdfModals, setPdfModals] = useState({
        sticker: null
    })

    useEffect(() => {
        dispatch({
            type: ACTIONS.CHANGE_DOCUMENT
        })
    }, [ticket])

    useEffect(() => {

        if(signerParam && report.firmas) {

            let signerToGoTo = null;
            for(const signInd of report.firmas) {
                if(signInd.rfc === signerParam || signInd.certificados[0].commonName === signerParam) {
                    signerToGoTo = signInd;
                    break
                }
            }

            if(signerToGoTo) {
                setReportState({
                    isOpen: true,
                    selectedSigner: signerToGoTo
                })
            }   
        }         

    }, [signerParam, report.firmas])

    const handlePageOnVisibleChange = useCallback((i, rect, updatedAt) => {
        IS_MOBILE ?
        dispatch({
            type: ACTIONS.PAGE_VISIBLE_CHANGE,
            payload: {
                i, rect, updatedAt,
                rendererBoundingClient: rendererRefContainer.current ? rendererRefContainer.current.getBoundingClientRect() : null
            }
        }) : companyComponents === "Acertia" ?
            dispatch({
                type: ACTIONS.PAGE_VISIBLE_CHANGE_ACERTIA,
                payload: {
                    i, rect, updatedAt,
                    rendererBoundingClient: rendererRefContainer.current ? rendererRefContainer.current.getBoundingClientRect() : null
                }
            }) :
            dispatch({
                type: ACTIONS.PAGE_VISIBLE_CHANGE,
                payload: {
                    i, rect, updatedAt,
                    rendererBoundingClient: rendererRefContainer.current ? rendererRefContainer.current.getBoundingClientRect() : null
                }
            })
    }, [companyComponents])
    
    function handleChangeScale(newScale) {     
        if(rendererRefContainer.current && rendererRefContainer.current.scrollTo) {
            const {diffX, diffY} = calcScrollDiff(newScale)
            IS_MOBILE ?
            dispatch({
                type:ACTIONS.CHANGE_SCALE,
                payload: {
                    scale: newScale
                }
            }) : companyComponents === "Acertia" ?
                dispatch({
                    type:ACTIONS.CHANGE_SCALE_ACERTIA,
                    payload: {
                        scale: newScale
                    }
                }) :
                dispatch({
                    type:ACTIONS.CHANGE_SCALE,
                    payload: {
                        scale: newScale
                    }
                })
            
                if(IS_MOBILE) {
                    rendererRefContainer.current.scrollTo(rendererRefContainer.current.scrollLeft + diffX, rendererRefContainer.current.scrollTop + diffY);
                }
                
        }           
    }

    function handleClickZoomIn() {
        const newScale = scale + ZOOM_STEP_FACTOR; 
        if(newScale < maxScale) {
            handleChangeScale(newScale);
        }        
    }

    function handleClickZoomOut() {
        const newScale = scale - ZOOM_STEP_FACTOR;
        if(newScale > minScale) {
            handleChangeScale(newScale);
        }        
    }

    function calcScrollDiff(newScale) {
        const diffX = (originalSize.width * newScale) - (originalSize.width * scale); 
        const diffY = (originalSize.height * newScale) - (originalSize.height * scale)
        return {
            diffX, diffY
        }
    }

    function handlePinchScale(newScale) {
        IS_MOBILE ?
        dispatch({
            type:ACTIONS.CHANGE_SCALE,
            payload: {
                scale: newScale
            }
        }) : companyComponents === "Acertia" ?
            dispatch({
                type:ACTIONS.CHANGE_SCALE_ACERTIA,
                payload: {
                    scale: newScale
                }
            }) :
            dispatch({
                type:ACTIONS.CHANGE_SCALE,
                payload: {
                    scale: newScale
                }
            })
    }

    function handlePan(event) {

        if(event.scale > maxScale) {
            return;
        }

        const scrollLeft = rendererRefContainer.current.scrollLeft;
        const scrollTop = rendererRefContainer.current.scrollTop;

        const diffX = scrollLeft + event.x;
        const zoomPointX = diffX / scale;
        const xScaled = zoomPointX * event.scale;

        const diffY = scrollTop + event.y;
        const zoomPointY = diffY / scale;
        const yScaled = zoomPointY * event.scale;

        rendererRefContainer.current.scrollTo((xScaled - event.x), yScaled - event.y);
    }

    function handleClickSigners() {
        setReportState({
            selectedSigner: null,
            isOpen: true
        })
    }

    function handleClickCloseReport() {
        clearParam()
        setReportState({
            ...reportState,
            isOpen: false
        })
        setTimeout(() => {
          setReportState({
            selectedSigner: null,
            isOpen: false
          })  
        }, 500)
    }

    function handleReportOnSignerSelected(signer) {
        setReportState({
            ...reportState,
            selectedSigner: signer
        })
    }

    function handleSignatureClick(signer) {
        return function() {
            setReportState({
                isOpen:true,
                selectedSigner:signer
            })  
        }
    }

    function handlePageContainerClick(event) {
        if(IS_MOBILE) {
            event.preventDefault();
            event.stopPropagation();
            dispatch({
                type: ACTIONS.CHANGE_SCALE,
                payload: {
                    scale: (body.width / originalSize.width) - ZOOM_STEP_FACTOR
                }
            })
        }
    }

    function handleClickOwnerSticker(sticker) {
        setPdfModals({sticker})
    }

    function handleCancelSignerModal() {
        setPdfModals({sticker:null})
        reduxDispatch(removeDraggable())
    }

    function handleCompleteSignerModal() {
        setPdfModals({sticker:null})
        reduxDispatch(removeDraggable())
        reduxDispatch(loadReport(ticket))        
    }

    return (
        <FlexColumn alignItems="center" style={{width:'100%'}}>
            {
                (companyComponents === 'Acertia') ? 
                <React.Suspense fallback={<div></div>}>
                        <AcertiaDocumentTopBar 
                            title={report.originalName}
                            currentPage={currentPage}
                            rendererSize={rendererSize}
                            onOpenSigners={handleClickSigners}
                            handleSignatureClick={handleSignatureClick}
                            editable={editable}
                            onAddSticker={handleClickOwnerSticker}
                            onZoomIn={handleClickZoomIn}
                            onZoomOut={handleClickZoomOut}>
                        </AcertiaDocumentTopBar>
                    </React.Suspense> : 
                    <React.Suspense fallback={<div></div>}>
                        <DocumentTopBar 
                            title={report.originalName}
                            currentPage={currentPage}
                            rendererSize={rendererSize}
                            onOpenSigners={handleClickSigners}
                            editable={editable}
                            onAddSticker={handleClickOwnerSticker}
                            handleSignatureClick={handleSignatureClick}
                            activeSigner={activeSigner}
                            activeSignerFound={activeSignerFound}
                        ></DocumentTopBar>
                    </React.Suspense>
            }            
            <div 
            id="pdf-renderer-container"
            ref={rendererRefContainer}
            style={{
                width: "100%",
                // width: rendererRef.current && (document.body.offsetWidth < rendererRef.current.offsetWidth) ? '100%' : 'auto',
                overflow: "scroll",
                flex: "1 1 0%"
            }}>
            <div id="pdf-renderer" style={{
                    marginLeft: IS_MOBILE ? `${marginLeft}px` : ''}}>
                <DocumentDownloader ticket={ticket}></DocumentDownloader>
                <style dangerouslySetInnerHTML={{
                    __html:css
                }}></style>
                {
                    docStatus.status !== 'CONVERTED' ?
                    <div style={{
                        margin:'auto',         
                        textAlign:'center',
                        fontWeight:'bold',
                        padding:'50px'               
                    }}>
                        <p>
                            {
                                docStatus.status === 'DELETED' ? 
                                t('eliminatedDocument') : 
                                docStatus.status === 'BLOCKED' ?
                                    report?.documentStatus?.blocked?.flowExpired ?
                                    t('blockedDocumentExpired') :
                                t('blockedDocument') :
                                t('obtainDocument')
                            }
                        </p>
                    </div> : null
                }
                <PinchZoomPan 
                    width={rendererSize ? rendererSize.width : null}
                    height={rendererSize ? rendererSize.height : null}
                    scale={scale} 
                    maxScale={maxScale}
                    minScale={minScale}
                    onScaleChange={handlePinchScale}
                    onPan={handlePan}>
                    {(_x,_y, _s) =>
                    <div id="page-container" style={{transform:`scale(${scale})`, transformOrigin: IS_MOBILE ? '' : 'center top', height:'100px', cursor: IS_MOBILE ? 'pointer' : 'default', display: 'block'}}
                        onDoubleClick={handlePageContainerClick}>
                        {
                            pages.map((p) => {                            
                                return (
                                    <Page key={ticket + p.id} 
                                    page={p}
                                    idx={p.idx}
                                    pageSize={originalSize}
                                    onVisibleChange={handlePageOnVisibleChange}></Page>
                                )
                            })
                        }                                    
                    </div>
                    }                
                </PinchZoomPan>
            
                {
                    IS_MOBILE ? null : 
                        companyComponents === "Acertia" ? <AcertiaSideBar title={report.originalName} /> : null
                }
                {
                    // stickers
                    report.stickerPages.map(sp => 
                        <StickerLayer key={sp.page} 
                            pageRendered={renderedPages[sp.page]} 
                            page={sp.page + 1} 
                            stickers={sp.stickers}
                            editable={editable}
                            onClickOwner={handleClickOwnerSticker}
                            activeSigner={activeSigner}
                            activeSignerFound={activeSignerFound}></StickerLayer>)
                }        
                {
                    // firmas recien realizadas (todavia no estan renderizadas en el documento)
                    htmlSignatureImgPages.map(sp => 
                        <SignatureHtmlImgLayer key={sp.page} 
                            pageRendered={renderedPages[sp.page]} 
                            page={sp.page + 1} 
                            htmlSignatures={sp.signatures}></SignatureHtmlImgLayer>)
                }    
                {
                    // overlays para poder dar click a las firmas en el documento
                    signaturePages.map(sp => 
                        <SignatureImgLayer key={sp.page} 
                            pageRendered={renderedPages[sp.page]} 
                            page={sp.page + 1} 
                            onClick={handleSignatureClick}
                            signatures={sp.signatures}></SignatureImgLayer>)
                }            
                {
                    pages.map((p, i) => 
                        <DraggableLayer key={p.id} page={p.idx + 1} pageRendered={renderedPages[p.idx]} scale={scale}></DraggableLayer> )
                }
                {
                    companyComponents === "Acertia" ? null :
                <ViewerControls scale={scale} 
                    onZoomIn={handleClickZoomIn}
                    onZoomOut={handleClickZoomOut}></ViewerControls>
                }
            </div>
            </div>
            {
                IS_MOBILE ?
                <FlexRow>
                    {
                        !addingDraggable.working ?
                        <SignButton
                            rendererSize={rendererSize}
                            currentPage={currentPage}
                            hasDocument={hasDocument}
                            signableStickers={signableStickers}
                            activeSigner={activeSigner}
                            activeSignerFound={activeSignerFound}
                        ></SignButton> : null
                    }                    
                    {/* {
                        editable ?
                        <AddSignerButton
                            onAddSticker={handleClickOwnerSticker}
                            currentPage={currentPage}></AddSignerButton> : null
                    } */}
                </FlexRow> : null                
            }          
            <SignersReport
                isOpen={reportState.isOpen}
                onClose={handleClickCloseReport}
                selectedSigner={reportState.selectedSigner}
                onSignerSelected={handleReportOnSignerSelected}></SignersReport>
            {/* {
                uid ? 
                <React.Fragment>
                    <ApplyStickersButton></ApplyStickersButton>
                    <AddSignerModal
                    isOpen={pdfModals.sticker !== null}
                    initialSticker={pdfModals.sticker}
                    onCancel={handleCancelSignerModal}
                    onComplete={handleCompleteSignerModal}></AddSignerModal>
                </React.Fragment> : <React.Fragment>
                    <ApplyStickersButton></ApplyStickersButton>
                    <AddSignerModal
                    isOpen={pdfModals.sticker !== null}
                    initialSticker={pdfModals.sticker}
                    onCancel={handleCancelSignerModal}
                    onComplete={handleCompleteSignerModal}></AddSignerModal>
                </React.Fragment>
            }             */}
        </FlexColumn>
    )
}
