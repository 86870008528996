    import {SET_SIGNING, SET_DRAWING_SIGNATURE, SET_FUTURE_VERIFYING_USER, SET_VERIFYING_USER, 
        SET_PICKING_SIGNATURE_STYLE,
        SET_WAITING_NOM151_TIMESTAMP, 
        SET_SHOWING_ALERT,
        SET_UPLOADING_DOCUMENT,
        RESET_STATE,
        CHANGE_DOCUMENT,
        SET_ADDING_DRAGGABLE} from '../actionTypes';

const initialState = {
    signing: {
        working: false,
        data: {}
    },
    // popup de seleccion de trazo de firma
    pickingSignatureStyle: {
        working: false,
        data: {}
    },
    drawingSignature: {
        working: false,
        data:{}
    },
    waitingNom151Timestamp: {
        working: false
    },
    showingAlert: {
        working: false,
        children: null
    },
    uploadingDocument: {
        working: false,
        file: null
    },
    addingDraggable: {
        working: false,
        draggable: {
            page: null,
            type: null,
            skin: null,
            size: null
        }
    },
    // el usuario llego a la pantalla de verificacion de correo
    // este estatus se guarda en el localStorage para por si se sale
    // del app, pueda continuar con el proceso despues de entrar
    // nuevamente
    verifyingUser: localStorage.getItem('verifyingUser') ? 
        JSON.parse(localStorage.getItem('verifyingUser')) : {
            working: false,
            data: {}
        }
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_SIGNING: {
            const {signing} = action.payload;
            return {
                ...state,
                signing
            }            
        }
        case SET_DRAWING_SIGNATURE: {
            const {drawingSignature} = action.payload;
            return {
                ...state,
                drawingSignature
            }
        }
        case SET_VERIFYING_USER: {
            const {verifyingUser} = action.payload;
            
            localStorage.setItem('verifyingUser', JSON.stringify(verifyingUser));
            return {
                ...state,
                verifyingUser
            }
        }
        case SET_FUTURE_VERIFYING_USER: {
            const {verifyingUser} = action.payload;
            localStorage.setItem('verifyingUser', JSON.stringify(verifyingUser))
            return state;
        }        
        case SET_PICKING_SIGNATURE_STYLE: {
            const {pickingSignatureStyle} = action.payload;
            return {
                ...state,
                pickingSignatureStyle
            }
        }
        case SET_SHOWING_ALERT: {
            const {children, working} = action.payload;
            return {
                ...state,
                showingAlert: {
                    children, working
                }
            }
        }
        case SET_WAITING_NOM151_TIMESTAMP: {
            const {waitingNom151Timestamp} = action.payload;
            return {
                ...state,
                waitingNom151Timestamp
            }
        }
        case SET_UPLOADING_DOCUMENT: {
            const {working, file} = action.payload;
            return {
                ...state,
                uploadingDocument: {
                    working, file
                }
            }
        }
        case SET_ADDING_DRAGGABLE: {
            const {working, draggable} = action.payload;
            return {
                ...state,
                addingDraggable: {
                    working, draggable
                }
            }
        }
        case CHANGE_DOCUMENT: {
            return {
                ...initialState,
                verifyingUser: {
                            working: false,
                            data: {}
                        }
            };
        }
        case RESET_STATE: {
            return {
                ...initialState,
                verifyingUser: localStorage.getItem('verifyingUser') ? 
                        JSON.parse(localStorage.getItem('verifyingUser')) : {
                            working: false,
                            data: {}
                        }
            };
        }
        default: {
            return state;
        }
    }
}