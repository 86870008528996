import React, { useEffect, useState } from 'react';
import styles from './PickSignatureStyle.module.css'
import FlexColumn from './FlexColumn';
import { Collapse } from 'reactstrap';
import ImageGenerator from '../utils/ImageGenerator'
import {useSelector, useDispatch} from 'react-redux'
import { appendToArray, sha256Hash } from '../utils/toolbox';
import {ChevronRightIcon} from './FirmamexIcons';
import {useSpring, animated} from '@react-spring/web';
import FlexRow from './FlexRow';
import SolidButton from './SolidButton';
import ResponsiveSheetModal from './ResponsiveSheetModal';
import { setSignatureImage } from '../redux/actions';
import HttpRequest from './HttpRequest';
import CustomModalHeader from './CustomModalHeader';
import { usePalette } from './Customizables';

import { useTranslation } from 'react-i18next';


function StyleToggler({ticket, signatureType, customData, commonName, issuerName, title, description, isOpen, signatureStroke,
    onClick, onSelected, requestedImageType}) {

    const {translate} = useSelector(state => state.config)
    const {t} = useTranslation()
    const [img, setImg] = useState(null);

    const arrowProps = useSpring({transform: `rotate(${isOpen ? '90' : '0'}deg)`})
    const palette = usePalette();

    useEffect(() => {
        async function getImage() {
            // debugger
            switch(signatureType) {
                case ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DESC: {           
                    const generated = await ImageGenerator.generateDesc(ticket, requestedImageType);
                    setImg(generated);
                    break;
                }
                case ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_NAME: {
                    const generated = await ImageGenerator.generateName(ticket, requestedImageType);
                    setImg(generated);
                    break;
                }
                case ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_HASH: {        
                    const generated = await ImageGenerator.generateHash(null, ticket, requestedImageType);
                    setImg(generated);
                    break;
                }
                case ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DRAWING: {                    
                    if(!signatureStroke || !signatureStroke.data) {
                        break;
                    }
                    setImg(signatureStroke)
                    break;
                }               
                 case ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_QR: {
                    const generated = await ImageGenerator.generateQR(ticket, requestedImageType);
                    setImg(generated)
                    break;
                }
            }
        }

        if(commonName && isOpen) {
            // debugger
            getImage();
        }       
        
        if(!isOpen) {
        //     debugger
            setImg(null)
        }


    }, [signatureType, commonName, issuerName, customData, signatureStroke, translate, ticket, isOpen])

    function handleClickTitle() {
        onClick(signatureType)
    }

    function handleClickSelected() {
        onSelected(img);
    }

    function handleClickEditDrawing() {
        ImageGenerator.requestSignatureDrawing(requestedImageType, ticket)
    }

    return (
        <div className={styles.styleContainer}>
            <FlexColumn
                onClick={handleClickTitle}>
                <FlexRow flexWrap="nowrap">
                    <p style={{
                        flexGrow:1
                    }}>
                        <span className={styles.styleTitle}>
                            {title}
                        </span>
                        <br/>
                        <span className={styles.styleDescription}>
                            {description}
                        </span>
                    </p>
                    <animated.div style={arrowProps}>
                        <ChevronRightIcon className={styles.styleArrow} style={{color:palette.secondaryColor}}></ChevronRightIcon>
                    </animated.div>
                </FlexRow>                
            </FlexColumn>
            <Collapse isOpen={isOpen}>
                {
                    img ? 
                    <FlexColumn>
                        <img src={`data:image/png;base64,${img.data}`} 
                        style={{
                            maxWidth:'80%',
                            maxHeight:'150px',
                            margin:'auto',
                            background: '#FFFFFF',
                            padding:'10px'
                        }}
                        alt="imagen de firma"></img>    
                        <FlexRow flexWrap="nowrap">
                            {
                                signatureType === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DRAWING ?
                                <SolidButton style={{flexGrow:1, margin:'3px !important'}}
                                    onClick={handleClickEditDrawing}>{t('Editar')}</SolidButton> : null
                            }
                            <SolidButton style={{flexGrow:1, margin:'3px !important'}}
                                onClick={handleClickSelected}>{t('Aceptar')}</SolidButton>
                        </FlexRow>                        
                    </FlexColumn> : null
                }
            </Collapse>
        </div>
    )
}

export default function PickSignatureStyle({stickerId, issuerName, commonName, onDone, onCancel, isOpen, cancelable, requestedImageType}) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const document = useSelector(state => state.document);
    const session = useSelector(state => state.session);

    const [customData, setCustomData] = useState(null);
    const [selected, setSelected] = useState(null);
    

    useEffect(() => {

        if(stickerId) {
            const sticker = document.report.boxes.find(b => b._id === stickerId);
            setCustomData(sticker.customData);
        }

    }, [stickerId, document.report.boxes])


    async function handleClickStyle(styleName) {
        const isComposedSignatureImage = requestedImageType && requestedImageType.includes('+')
        if(styleName === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DRAWING && (!session.current.signatureStrokeImage || isComposedSignatureImage)) {
            const result = await ImageGenerator.requestSignatureDrawing(requestedImageType || 'stroke', document.report.ticket)
            if(result) {
                setSelected(styleName === selected ? null : styleName);
            }
        } else {
            setSelected(styleName === selected ? null : styleName);
        }        
    }

    async function handleClickStyleSelected(imageData) {
        // la guardamos como la imagen de firma seleccionada del usuario
        HttpRequest.post('/browser/img', {
            signatureImage:imageData
        })
        dispatch(setSignatureImage(imageData))
        onDone(imageData)
    }

    return (
        <React.Fragment>
            <ResponsiveSheetModal 
                isOpen={isOpen}
                onClose={onCancel}
                cancelable={cancelable}
                mobile={{
                    height: window.innerHeight - 50,
                    fullScreen: isOpen,
                    header: <span>{t('chooseDrawing')}</span>
                }}
                desktop={{
                    header: (
                        <CustomModalHeader toggle={onCancel} className={styles.header}>
                            {t('chooseDrawing')}
                        </CustomModalHeader>
                    )
                }}>
                <StyleToggler
                    onClick={handleClickStyle}
                    onSelected={handleClickStyleSelected}
                    isOpen={selected === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DESC}
                    signatureType={ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DESC}
                    title={t('descriptive')}
                    description={t('nameAuthorityAnd')}
                    ticket={document.report.ticket}
                    commonName={commonName}
                    issuerName={issuerName}
                    customData={customData}
                    requestedImageType={requestedImageType}
                ></StyleToggler>
                <StyleToggler
                    onClick={handleClickStyle}
                    onSelected={handleClickStyleSelected}
                    isOpen={selected === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DRAWING}
                    signatureType={ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_DRAWING}
                    title={t('drawing')}
                    ticket={document.report.ticket}
                    description={t('allowsDrawing')}
                    commonName={commonName}
                    issuerName={issuerName}
                    customData={customData}
                    signatureStroke={session.current.signatureStrokeImage}
                    requestedImageType={requestedImageType}
                ></StyleToggler>
                <StyleToggler
                    onClick={handleClickStyle}
                    onSelected={handleClickStyleSelected}
                    isOpen={selected === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_NAME}
                    signatureType={ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_NAME}
                    title={t('name')}
                    ticket={document.report.ticket}
                    description={t('cursiveName')}
                    commonName={commonName}
                    issuerName={issuerName}
                    customData={customData}
                    requestedImageType={requestedImageType}
                ></StyleToggler>
                <StyleToggler
                    onClick={handleClickStyle}
                    onSelected={handleClickStyleSelected}
                    isOpen={selected === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_HASH}
                    signatureType={ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_HASH}
                    title={t('digest')}
                    description={t('include256')}
                    ticket={document.report.ticket}
                    commonName={commonName}
                    issuerName={issuerName}
                    customData={customData}
                    requestedImageType={requestedImageType}
                ></StyleToggler>                
                <StyleToggler
                    onClick={handleClickStyle}
                    onSelected={handleClickStyleSelected}
                    isOpen={selected === ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_QR}
                    signatureType={ImageGenerator.IMAGE_TYPES.IMAGE_TYPE_QR}
                    title={t('qr')}
                    description={t('qrSign')}
                    ticket={document.report.ticket}
                    commonName={commonName}
                    issuerName={issuerName}
                    customData={customData}
                    requestedImageType={requestedImageType}
                ></StyleToggler>
            </ResponsiveSheetModal>
        </React.Fragment>
    )

}