import React from 'react';

function IconWrapper({icon, style, width, height, children, ...rest}) {

    const newStyles = style || [];

    return <div style={{
        fontSize:'20px',
        padding:'8px',
        WebkitUserSelect:'none',
        ...newStyles}}
        {...rest}>
            {children}
        </div>
}

export function FirmantesIcon(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 28 19.611">
            <path fill={props.color} fillRule="evenodd" d="M9.80000305 9.80545044c2.70199967 0 4.90000153-2.19921875 4.90000153-4.90274048C14.70000458 2.19921875 12.50200272 0 9.80000305 0 7.0980034 0 4.90000153 2.19921875 4.90000153 4.90270996c0 2.70352173 2.19800186 4.90274048 4.90000152 4.90274048zm13.29999542-4.90274048c0 2.70352173-2.19799804 4.90274048-4.90000152 4.90274048-.7559967 0-1.45600128-.18209839-2.09999848-.4902649C16.98200226 8.06848145 17.5 6.54162599 17.5 4.90270997c0-1.6388855-.51799774-3.16574097-1.40000153-4.41244507C16.74399567.1820984 17.44400024 0 18.19999695 0c2.70200348 0 4.90000152 2.19921875 4.90000152 4.90270996zm-11.19999694 0c0-1.16265869-.93799973-2.10116577-2.1000023-2.10116577-1.16199874 0-2.09999847.93850708-2.09999847 2.10116577 0 1.1626587.93799973 2.10116577 2.09999848 2.10116577 1.16200256 0 2.10000229-.93850708 2.10000229-2.10116577zM0 17.15951538c0-3.26379394 6.52400208-4.90270996 9.80000305-4.90270996 3.27599716 0 9.80000305 1.63891602 9.80000305 4.90270996v2.4513855H0v-2.4513855zm19.65600586-4.81866455c1.62400055 1.17666626 2.7440033 2.74551392 2.7440033 4.81866455v2.4513855h5.59999847v-2.4513855c0-2.82955933-4.90000153-4.4404602-8.34400177-4.81866455zm-9.8560028 2.7175293c-2.50600053 0-5.3480034.93850708-6.52400208 1.75097656h13.04800415c-1.1760025-.81246948-4.01800537-1.75097656-6.52400208-1.75097656z"/>
        </svg> 
    </IconWrapper>
}

export function FirmarIcon(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 28 28">
            <path fill={props.color} fillRule="evenodd" d="M5.33480072 4.15795135c-.47797394-.20244598-1.55726624.79421998-2.63656235 1.89989472L0 3.3948822c.3392067-.42046356.80176163-.88764953 1.35682678-1.4482727C1.7422905 1.55728912 3.51541901 0 5.55065918 0c1.34141159 0 3.8700447 1.07452393 3.8700447 4.45383453 0 2.1179123-.80176162 3.33259583-2.00440597 5.10790253-.6938324 1.02780914-2.31277466 3.78420258-2.8524208 5.48164368-.55506516 1.6974411-.13876725 2.98999023.55506515 2.98999023.66299439 0 1.32599258-.84093475 1.7114563-1.30812073.35462189-.37374878 2.63656235-3.09899902 3.5308342-4.23581695 1.17180633-1.44828034 4.1475792-4.42269135 7.61674118-4.42269135 4.5330429 0 5.9823761 3.97107697 6.21365356 6.54060364H28v3.89321137h-3.7929535C23.59030915 25.92881012 19.48898316 28 16.97576905 28c-2.72907257 0-4.949337-2.16462708-4.949337-4.81201172 0-2.64738464 2.4669609-7.3659668 8.29515457-8.36262512-.16960144-1.21468353-.20043945-2.78754425-2.7136612-2.78754425-1.92730712 0-4.42510604 3.03670502-6.29074478 5.3570633-1.71145248 2.13348388-3.06828308 3.83092498-4.71805954 4.31368255-1.3876686.42046356-2.91410064.1557312-4.07048797-.71634674-1.32598877-.99666596-2.06607819-2.64739228-2.06607819-4.64071656 0-3.76863098 4.02423096-9.17241668 4.40969086-9.82647705.46255493-.76306915 1.23348236-2.05561065.46255493-2.36707306zM15.88105774 23.0945511c0 .71634674.66299438 1.12124633 1.14096832 1.12124633 1.0792923 0 2.82158661-1.23025512 3.28414154-5.4193573-3.29956054.87207795-4.42510986 3.36373902-4.42510986 4.29811097z"/>
        </svg>
    </IconWrapper>
}

export function DownloadOriginal(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 18 18">
            <path fill={props.color} fillRule="evenodd" d="M9 0C4.03199768 0 0 4.03199959 0 9s4.03199768 9 9 9 9-4.03199959 9-9-4.03199768-9-9-9zm0 16.20000076c-3.96899414 0-7.19999695-3.2310009-7.19999695-7.20000076S5.03100585 1.8000002 9 1.8000002c3.9690094 0 7.19999695 3.23099994 7.19999695 7.1999998S12.9690094 16.20000076 9 16.20000076zm-1.7999878-5.24700165l5.93099976-5.93099975 1.2689972 1.27799988L7.2000122 13.5 3.6000061 9.89999962l1.26899719-1.269001 2.3310089 2.3220005z"/>
        </svg>
    </IconWrapper>
}

export function Universal(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 28 28">
            <path fill={props.color} fillRule="evenodd" d="M0 14C0 6.27200317 6.25799942 0 13.98600006 0 21.72799683 0 28 6.27200317 28 14s-6.27200317 14-14.01399994 14C6.25799942 28 0 21.72799683 0 14zM14 2.85598755c1.16200256 1.67999267 2.0719986 3.54199219 2.6740036 5.54400635h-5.34800339C11.92800141 6.39797974 12.83800125 4.53598022 14 2.85598755zM4.31200027 8.3999939h4.13000107c.44799805-1.75 1.09199906-3.4300232 1.9319992-4.9840088-2.5760002.88198853-4.7179985 2.66000367-6.06200027 4.9840088zm15.24599839 0h4.12999725c-1.34399414-2.30999756-3.48600006-4.10202027-6.06199646-4.9840088.83999634 1.5539856 1.48400116 3.2340088 1.9319992 4.9840088zM2.79999924 14c0 .96600342.13999939 1.90402222.36399841 2.80001831h4.73200226C7.7840004 15.87600708 7.70000076 14.95202637 7.70000076 14c0-.95199585.08399964-1.87597656.19599915-2.7999878H3.16399765C2.93999863 12.0960083 2.79999924 13.0340271 2.79999924 14zM10.5 14c0 .95202637.09799957 1.87600708.22399902 2.80001831h6.55200196C17.40200043 15.87600708 17.5 14.95202637 17.5 14c0-.95199585-.09799957-1.88998413-.22399902-2.7999878h-6.55200196C10.59799957 12.11001588 10.5 13.04800416 10.5 14zm9.79999542 0c0 .95202637-.08399963 1.87600708-.19599914 2.80001831h4.73200226c.22399902-.8959961.3639984-1.8340149.3639984-2.80001831 0-.9659729-.13999938-1.9039917-.3639984-2.7999878h-4.73200226c.1119995.92401124.19599914 1.84799195.19599914 2.7999878zM4.31200027 19.6000061h4.13000107c.44799805 1.75 1.09199906 3.42999268 1.9319992 4.9840088-2.5760002-.88201905-4.7179985-2.66000367-6.06200027-4.9840088zM14 25.14401245c-1.16199875-1.67999267-2.0719986-3.54199219-2.67399979-5.54400635h5.3480034C16.0719986 21.60202026 15.16200255 23.46401978 14 25.14401245zm3.62599945-.55999756c.83999634-1.55401611 1.48400116-3.23400879 1.9319992-4.98400879h4.12999726c-1.34399414 2.30999756-3.48600006 4.10198975-6.06199646 4.9840088z"/>
        </svg>
    </IconWrapper>
}

export function NOM151(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 28 28">
            <path fill={props.color} fillRule="evenodd" d="M14 0C6.29999924 0 0 6.2999878 0 14s6.29999924 14 14 14c7.69999695 0 14-6.2999878 14-14S21.69999695 0 14 0zm0 25.19998169C7.84000015 25.19998169 2.79999924 20.15997314 2.79999924 14 2.79999924 7.83999634 7.84000015 2.7999878 14 2.7999878c6.16000366 0 11.19999695 5.04000854 11.19999695 11.2000122 0 6.15997314-5.03999329 11.19998169-11.19999695 11.19998169zM12.59999847 7h2.09999848v7.27999878L21 18.05999756l-1.12000275 1.67999267-7.27999878-4.33999633V7z"/>
        </svg>
    </IconWrapper>
}

export function Imprimir(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 28 25.2">
            <path fill={props.color} fillRule="evenodd" d="M23.80000305 7h-1.40000152V0H5.59999847v7h-1.3999977C1.87599944 7 0 8.87600708 0 11.19999695v8.40000915h5.59999847v5.59999085h16.80000306V19.6000061H28v-8.40000915C28 8.87600708 26.12400055 7 23.80000305 7zM8.40000153 2.80000305h11.19999694V7H8.40000153V2.80000305zm14 14V14H5.59999847v2.80000305H2.79999924v-5.6000061c0-.76998902.63000107-1.3999939 1.40000152-1.3999939h19.6000023c.76999663 0 1.39999389.63000488 1.39999389 1.3999939v5.6000061h-2.79999542zm1.40000152-4.90000915c0-.77267456-.62731933-1.3999939-1.40000152-1.3999939C21.62731933 10.5 21 11.12731934 21 11.8999939c0 .77268982.62731934 1.40000915 1.40000153 1.40000915.77268219 0 1.40000152-.62731933 1.40000152-1.40000915zm-4.20000458 4.90000915H8.40000153v5.6000061h11.19999694v-5.6000061z"/>
        </svg>
    </IconWrapper>
}

export function Share(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 28 30.987">
            <path fill={props.color} fillRule="evenodd" d="M20.28444672 23.1000061c.80888366-.73112487 1.86666107-1.19778442 3.04888916-1.19778442 2.5044403 0 4.54222107 2.03778076 4.54222107 4.54222107 0 2.5044403-2.03778077 4.54222107-4.54222107 4.54222107-2.50444794 0-4.5422287-2.03778076-4.5422287-4.54222107 0-.34222412.046669-.68444824.12445068-1.0111084l-11.0755577-6.47111511C7 19.74000549 5.89555358 20.22221374 4.66666793 20.22221374 2.08444595 20.22221375 0 18.13777162 0 15.55555726c0-2.58222961 2.08444595-4.66667175 4.66666794-4.66667175 1.22888565 0 2.33333206.4822235 3.17333221 1.26000976l10.966671-6.39334106c-.07778168-.35777283-.14000703-.71556091-.14000703-1.08888245C18.66666412 2.08444214 20.7511139 0 23.33333588 0 25.91555786 0 28 2.08444214 28 4.66667175c0 2.58221436-2.08444214 4.6666565-4.66666412 4.6666565-1.22888947 0-2.33333588-.48222351-3.17333222-1.25999451l-10.96667099 6.3933258c.07777786.3577881.1399994.71556092.1399994 1.08889771 0 .3733368-.06222154.73110962-.1399994 1.08888245l11.09111405 6.4555664zm4.6044464-18.43333435c0-.8555603-.70000457-1.55555725-1.55555724-1.55555725-.8555603 0-1.55555725.69999695-1.55555725 1.55555725 0 .8555603.69999694 1.55555725 1.55555725 1.55555725.85555267 0 1.55555725-.69999694 1.55555725-1.55555725zM4.66666795 17.1111145c-.85555649 0-1.55555725-.69999695-1.55555725-1.55555725C3.11111069 14.69999695 3.81111145 14 4.66666794 14c.85555267 0 1.55555343.69999695 1.55555343 1.55555725 0 .8555603-.70000076 1.55555725-1.55555343 1.55555725zm17.11111069 9.36444092c0 .8555603.69999694 1.55555725 1.55555725 1.55555725.85555267 0 1.55555725-.69999695 1.55555725-1.55555725 0-.8555603-.70000458-1.55555725-1.55555725-1.55555725-.8555603 0-1.55555725.69999695-1.55555725 1.55555725z"/>
        </svg>
    </IconWrapper>
}

export function Info(props) {
    return <IconWrapper {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 36 36">
            <path fill={props.color} fillRule="evenodd" d="M18 0C8.09997559 0 0 8.10000038 0 18c0 9.90000153 8.09997559 18 18 18s18-8.09999847 18-18c0-9.89999962-8.09997559-18-18-18zM3.59997559 18c0 7.91999817 6.47998046 14.40000153 14.40002441 14.40000153 7.91992188 0 14.40002441-6.48000336 14.40002441-14.40000153S25.91992187 3.60000038 18 3.60000038C10.07995605 3.60000038 3.59997559 10.08000183 3.59997559 18zm12.59997558-5.40000153V9h3.59997559v3.59999847h-3.59997559zm0 14.40000153V16.20000076h3.59997559V27h-3.59997559z"/>
        </svg>
    </IconWrapper>
}

export function AcertiaIconNoText(props) {
    return <IconWrapper {...props}>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    id="svg48"
                    version="1.1"
                    viewBox="0 0 25.21143 18.729139"
                    height={props.height}
                    width={props.width}
                >
                <g
                    transform="translate(-42.578809,-134.26638)"
                    id="layer1">
                    <path
                    style={{strokeWidth: "0.264583"}}
                    fill="#FFFFFF"
                    d="m 62.672232,143.61772 4.788959,-6.74687 c 0.529166,-0.74084 0.343958,-1.77271 -0.396875,-2.30188 -0.740834,-0.52916 -1.772709,-0.34396 -2.301875,0.39688 l -4.1275,5.82083 z"
                    id="path2" />
                    <path
                    style={{strokeWidth: "0.264583"}}
                    fill="#FFFFFF"
                    d="m 67.487649,150.39106 -5.87375,-8.255 -0.9525,-1.34938 -4.1275,-5.82083 c -0.3175,-0.42334 -0.820208,-0.68792 -1.349375,-0.68792 -0.529167,0 -1.031875,0.26458 -1.349375,0.68792 l -10.95375,15.42521 c -0.529167,0.74083 -0.343959,1.77271 0.396875,2.30187 0.740833,0.52917 1.772708,0.34396 2.301875,-0.39687 l 9.604375,-13.52021 3.466042,4.86833 -3.466042,4.84188 -1.42875,-2.01084 c -0.529167,-0.74083 -1.561042,-0.92604 -2.301875,-0.39687 -0.740834,0.52917 -0.926042,1.56104 -0.396875,2.30187 l 2.778125,3.91584 c 0.3175,0.42333 0.820208,0.68791 1.349375,0.68791 0.529167,0 1.031875,-0.26458 1.349375,-0.68791 l 4.1275,-5.82084 4.1275,5.82084 c 0.3175,0.44979 0.820208,0.68791 1.349375,0.68791 0.3175,0 0.661458,-0.10583 0.9525,-0.29104 0.740833,-0.52916 0.926042,-1.56104 0.396875,-2.30187 z"
                    id="path6" />
                </g>
                </svg>
    </IconWrapper>
}

