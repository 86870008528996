import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { getPageLayer } from '../utils/toolbox';
import SignatureHtmlImg from './SignatureHtmlImg';

export default function SignatureHtmlImgLayer({page, htmlSignatures, pageRendered}) {

    const [container] = useState(document.createElement('div'))
    const [pageHeight, setPageHeight] = useState(0)  

    useEffect(() => {
        if(pageRendered) {
            const pageLayer = getPageLayer(page)
            if(!pageLayer) {
                return;
            }
            setPageHeight(pageLayer.offsetHeight)
            // container.id = `signature-layer-${page}`;
            container.className = 'htmlSignatureLayer'     
            pageLayer.appendChild(container);
            return () => {
                pageLayer.removeChild(container);
            }
        }
    }, [pageRendered, container, page])    

    return ReactDOM.createPortal((
        <div className="images">
            {
                htmlSignatures.map((s, i) => (
                    <SignatureHtmlImg key={i} {...s} pageHeight={pageHeight} 
                        signing={true}/>
                ))
            }            
        </div>), container)
}