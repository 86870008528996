import React, {useState} from 'react';
import {Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import useToggle from '../hooks/useToggle';

export default function InputPassword({style, ...props}) {

    const [isPassword, toggleIsPassword] = useToggle(true)

    return (
        <InputGroup>
            <Input {...props} type={isPassword ? 'password' : 'text'} style={{...style, borderRight:'none'}}></Input>
            <InputGroupAddon addonType="append">
                <InputGroupText style={{background:'#fff', borderLeft:'none'}}>
                    <FontAwesomeIcon icon={isPassword ? faEye : faEyeSlash} onClick={toggleIsPassword}></FontAwesomeIcon>
                </InputGroupText>                
            </InputGroupAddon>
        </InputGroup>        
    )
}