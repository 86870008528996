import { Button } from 'reactstrap';
import {ADD_TASK, UPDATE_TASK, REMOVE_TASK, RESET_STATE} from '../actionTypes'

const initialState = {
    tasks: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADD_TASK: {
            const {id, description, label, component, type} = action.payload;
            return {
                ...state,
                tasks: [
                    ...state.tasks.filter(t => t.id !== id && t.description !== description),
                    {id, description, component, label, type}
                ]
            }
        }
        case UPDATE_TASK: {
            const {id, component} = action.payload;
            return {
                ...state,
                tasks: [
                    ...state.tasks.map(t => {
                        if(t.id === id) {
                            return {...t, component}
                        }
                        return t;
                    })
                ]
            }
        }
        case REMOVE_TASK: {
            const {id} = action.payload;
            return {
                ...state,
                tasks: state.tasks.filter(t => t.id != id)
            }
        }
        case RESET_STATE: {
            return {
                tasks:[]
            }
        }
        default: {
            return state;
        }
    }
}