import React, { useEffect, useReducer, useCallback, useState } from 'react'
import HttpRequest from './HttpRequest';
import { Modal, ModalBody } from 'reactstrap';
import Certificate from '../crypto/Certificate'
import Signature from '../crypto/Signature'
import ParsedCertificate from '../crypto/ParsedCertificate'
import { retryPromiseUntilSuccess, appendToArray } from '../utils/toolbox';
import ImageGenerator from '../utils/ImageGenerator'
import { useDispatch, useSelector } from "react-redux";
import { loadReport, addHtmlSignatureImg, setPickingSignatureStyle, setSigning, addTask, removeTask, setLocalSignaures, clearPin, setPin } from "../redux/actions";
import Spinner from '../shared/Spinner'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axios from 'axios'
import SolidButton from './SolidButton';
import { usePalette, ImgSuccess } from './Customizables';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';
import PinInput from './PinInput';
import useGeoLocate from '../hooks/useGeoLocate';
import GeoLocateOverlay from './GeoLocateOverlay';
import useAttachments from '../hooks/useAttachments';
import FlexColumn from './FlexColumn';
import useHasAccount from '../hooks/useHasAccount';


const ACTIONS = {
    ADD_MSG: 'ADD_MSG',
    SET_STATUS: 'SET_STATUS',
    RESET: 'RESET'
}

const MODAL_STATUS = {
    DEFAULT: 'DEFAULT',
    SIGNING: 'SIGNING',
    SIGNED: 'SIGNED',
    OCSP_ERROR: 'OCSP_ERROR',
    OCSP_INVALID_FIRMAMEX: 'OCSP_INVALID_FIRMAMEX',
    OCSP_INVALID_PSC: 'OCSP_INVALID_PSC',
    DOC_CHANGED: 'DOC_CHANGED',
    ALREADY_SIGNED: 'ALREADY_SIGNED',
    RELOAD_ERROR: 'RELOAD_ERROR',
    REQUEST_PIN: 'REQUEST_PIN',
    OCSP_TIMESTAMP_ERROR: 'OCSP_TIMESTAMP_ERROR',
    OCSP_INVALID: 'OCSP_INVALID'
}

const defaultState = {
    messages: [],
    status: MODAL_STATUS.DEFAULT,
    currentSignatures: 0
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.ADD_MSG: {

            if (state.messages.find(m => m === action.payload.message)) {
                return {
                    ...state
                }
            }

            return {
                ...state,
                messages: [...state.messages, action.payload.message]
            }
        }
        case ACTIONS.SET_STATUS: {
            return {
                ...state,
                messages: action.payload.messages || state.messages,
                status: action.payload.status,
                currentSignatures: action.payload.currentSignatures
            }
        }
        case ACTIONS.RESET: {
            return defaultState;
        }
        default: {
            return state;
        }
    }
}

function loadAxios(jwt) {
    axios.interceptors.request.handlers = [];
    axios.interceptors.request.use(
        (config) => {
            if(!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${jwt}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

function isFirmamexCert(signupAuthority) {
    return signupAuthority !== 'Cecoban' &&
        signupAuthority !== 'SAT' &&
        signupAuthority !== 'SeguriData'
}

function InvalidOCSPMessage({ t, status }) {
    const message = t(status === MODAL_STATUS.OCSP_INVALID_FIRMAMEX ? 'invalidOCSPFirmamex' : 'invalidOCSPPSC');
    return <p>{message}</p>
}


export default function WithSingleUseCertificate({ isOpen, onComplete, onCancel }) {

    const { t } = useTranslation()
    const signableStickers = useSelector(state => state.signableStickers);
    const { translate } = useSelector(state => state.config)
    const { document, localSignatures, report } = useSelector(state => state.document)
    const userSession = useSelector(state => state.session.current);
    const { hideConfirmation } = useSelector(state => state.customizables)
    const [{ messages, status, currentSignatures }, modalDispatch] = useReducer(reducer, defaultState)

    const dispatch = useDispatch();
    const palette = usePalette();

    const [feedbackForm, setFeedbackForm] = useState(null)

    const [requestLocation, locationAllowed] = useGeoLocate()
    const [hasAttachments, attachmentsStatus, attachments, downloadAttachments] = useAttachments();

    const hasAccount = useHasAccount()


    const addToMsgs = useCallback(function addToMsgs(message) {
        if (message === 'Firmado') {
            console.log({hideConfirmation})
            if(hideConfirmation) {
                handleClickOnComplete();
            } else {
                setTimeout(() => {
                    modalDispatch({
                        type: ACTIONS.SET_STATUS,
                        payload: {
                            status: MODAL_STATUS.SIGNED,
                            currentSignatures
                        }
                    })
                    if(hasAttachments && report.boxes.filter(b => !b.signed).length === 1) {
                        downloadAttachments();
                    }
                }, 600);
            }
        } else {
            modalDispatch({
                type: ACTIONS.ADD_MSG,
                payload: {
                    message
                }
            })
        }
    }, [hasAttachments, currentSignatures, hideConfirmation])

    const handleClickCancel = useCallback(function () {
        dispatch(setSigning({
            signing: {
                working: false,
                data: {}
            }
        }))
        onCancel();
    }, [dispatch, onCancel])

    useEffect(() => {

        if (isOpen && signableStickers.active && signableStickers.signableStickers) {
            const sticker = signableStickers.signableStickers.find(s => s.id === signableStickers.active.id).sticker;
            if (status === MODAL_STATUS.DEFAULT && sticker.authority.singleUse) {
                modalDispatch({
                    type: ACTIONS.SET_STATUS,
                    payload: {
                        status: MODAL_STATUS.SIGNING,
                        currentSignatures: report.firmas.length
                    }
                })
            }
        }


    }, [isOpen, signableStickers, status, report.firmas])

    useEffect(() => {

        async function signupFromSticker(sticker) {

            const certType = sticker.authority.dataType === 'PHONE' ? 'phone' : 'email';

            let latLng = {};
            if (sticker.requireLocation) {
                latLng = await requestLocation()
            }

            if (sticker.authority.requirePin) {

                let requestPin = true;
                if (signableStickers.pin) {
                    addToMsgs('Verificando OTP')
                    const response = await HttpRequest.get(`/pin/${signableStickers.pin}/verify/${sticker._id}`)
                    if (response.data.valid) {
                        requestPin = false;
                    }
                }

                if (requestPin) {
                    addToMsgs('Enviando OTP')
                    dispatch(clearPin())
                    modalDispatch({
                        type: ACTIONS.SET_STATUS,
                        payload: {
                            status: MODAL_STATUS.REQUEST_PIN,
                            currentSignatures: report.firmas.length
                        }
                    })
                    return;
                }
            }

            addToMsgs('Generando tu par de llaves')

            const csrData = await retryPromiseUntilSuccess(Certificate.generateCsr, {
                certType,
                name: sticker.data,
                email: sticker.email
            })
            addToMsgs('Firmando solicitud de certificado')

            const signed = await Certificate.signCsr(csrData, 'firmamex-temp',
                sticker.data,
                sticker.authority,
                sticker.authority.dataType,
                null,
                null,
                report.signerLink ? report.signerLink.link : null,
                report.ticket,
                report.setLink ? report.setLink.link : null,
                signableStickers.pin)

            const verificationResult = await Certificate.waitUntilVerified(signed);

            loadAxios(verificationResult.jwt)

            const cert = await Certificate.certificateFromB64(verificationResult.cert);
            addToMsgs('Verificando registro')
            const checkoutResponse = await HttpRequest.post('/signup/checkout', {
                clientData: {},
                customer: {
                    email: sticker.email
                }
            })
            addToMsgs('Obteniendo imagen de firma')
            const parsedCert = ParsedCertificate.fromPem(cert);
            const fullDoc = appendToArray(document, localSignatures);

            let img;
            if (!sticker.imageType || sticker.imageType.includes('any')) {
                img = await new Promise((resolve, reject) => {
                    dispatch(setPickingSignatureStyle({
                        pickingSignatureStyle: {
                            working: true,
                            data: {
                                commonName: parsedCert.getCommonName(),
                                issuerName: parsedCert.getIssuerName(),
                                cancelable: false,
                                requestedImageType: sticker.imageType,
                                stickerId: sticker._id,
                                resolve,
                                reject
                            }
                        }
                    }))
                })
                dispatch(setPickingSignatureStyle({
                    pickingSignatureStyle: {
                        working: false,
                        data: {}
                    }
                }))
            } else {
                img = await ImageGenerator.generateImageForSticker(sticker, report.ticket);
            }

            if (!img) {
                handleClickCancel();
                return;
            }

            await HttpRequest.post('/browser/sticker/img', {
                b64Image: img.data,
                boxId: sticker._id
            })

            const error = await Signature.sign({
                report,
                certData: {
                    chain: checkoutResponse.data.data.certChain,
                    pem: cert
                },
                latLng,
                pkcs8Pem: csrData.pkcs8Pem,
                pass: 'firmamex-temp',
                original: new Blob([fullDoc], { type: 'application/pdf' }),
                sticker: sticker,
                signatureImg: img,
                onProgress: addToMsgs,
                signatureCount: currentSignatures,
                pin: signableStickers.pin
            })

            if (error) {
                switch (error) {
                    case 'ocsp_timestamp_error': {
                        modalDispatch({
                            type: ACTIONS.SET_STATUS,
                            payload: {
                                status: MODAL_STATUS.OCSP_TIMESTAMP_ERROR
                            }
                        })
                        break;
                    }
                    case 'invalid_cert_ocsp': {
                        modalDispatch({
                            type: ACTIONS.SET_STATUS,
                            payload: {
                                status: isFirmamexCert(userSession.x509.signupAuthority) ?
                                    MODAL_STATUS.OCSP_INVALID_FIRMAMEX :
                                    MODAL_STATUS.OCSP_INVALID_PSC,
                                currentSignatures
                            }
                        })
                        break;
                    }
                    case 'ocsp_error': {

                        if(isFirmamexCert(userSession.x509.signupAuthority)) {
                            modalDispatch({
                                type: ACTIONS.SET_STATUS,
                                payload: {
                                    status: MODAL_STATUS.OCSP_INVALID_FIRMAMEX,
                                    currentSignatures
                                }
                            })
                        } else {
                            modalDispatch({
                                type: ACTIONS.SET_STATUS,
                                payload: {
                                    status: MODAL_STATUS.OCSP_ERROR,
                                    currentSignatures
                                }
                            })
                        }                        
                        
                        break;
                    }
                    case 'doc_changed': {
                        modalDispatch({
                            type: ACTIONS.SET_STATUS,
                            payload: {
                                status: MODAL_STATUS.DOC_CHANGED,
                                currentSignatures
                            }
                        })
                        break;
                    }
                    case 'already_signed': {
                        modalDispatch({
                            type: ACTIONS.SET_STATUS,
                            payload: {
                                status: MODAL_STATUS.ALREADY_SIGNED,
                                currentSignatures
                            }
                        })
                        break;
                    }
                    default: {
                        modalDispatch({
                            type: ACTIONS.SET_STATUS,
                            payload: {
                                status: MODAL_STATUS.RELOAD_ERROR,
                                currentSignatures
                            }
                        })
                        break;
                    }
                }
            } else {
                dispatch(addHtmlSignatureImg({ ...sticker, signatureImg: img }))
            }


        }

        if (status === MODAL_STATUS.SIGNING && signableStickers.active) {
            signupFromSticker(signableStickers.signableStickers.find(s => s.id === signableStickers.active.id).sticker)
        }

    }, [status, dispatch, document, localSignatures, handleClickCancel, report,
        signableStickers, userSession.jwt, addToMsgs, translate, currentSignatures, t, modalDispatch])

    useEffect(() => {

        if (!isOpen) {
            modalDispatch({
                type: ACTIONS.RESET
            })
        }

    }, [isOpen])


    async function handleClickOnComplete() {

        const hasRemainingStickers = signableStickers.signableStickers.find(s => s.id !== signableStickers.active?.id);

        if (feedbackForm != null) {
            HttpRequest.post('/feedback', {
                ...feedbackForm, frmxId: report.ticket
            });
            setFeedbackForm(null)
        }

        if (userSession.jwt) {
            loadAxios(userSession.jwt)
        }

        await dispatch(loadReport(report.ticket, report.queryString))
        dispatch(setSigning({
            signing: {
                working: false,
                data: {}
            }
        }))


        const qs = queryString.parse(window.location.search);
        if(!hasRemainingStickers && qs.fromUrl && qs.fromUrl.indexOf('javascript') === -1) {
            let decodedUrl = decodeURIComponent(qs.fromUrl);
            if(!decodedUrl.includes('doc=')) {
                const splitted = decodedUrl.split('?');
                const fromUrlQuery = queryString.parse(splitted[1] || '');
                fromUrlQuery.doc = report.ticket;
                decodedUrl = queryString.stringifyUrl({url: splitted[0], query: fromUrlQuery})
            }
            window.location.href = decodeURIComponent(decodedUrl);
        } else if(qs.callback && window.opener) {
            window.onbeforeunload = undefined;
            window.opener.postMessage(JSON.stringify({
                signed: true,
                doc: report.ticket
            }), '*');
            if(!hasRemainingStickers) {
                window.close();
            }
        }
    }

    function handleChangeFeedback(feedback) {
        setFeedbackForm(feedback)
    }

    function handleOkErrorClick() {
        dispatch(setSigning({
            signing: {
                working: false,
                data: {}
            }
        }))
    }

    async function restartSignature() {
        dispatch(addTask('reloading signatures', 'Actualizando documento'))
        const signatures = await HttpRequest.get(`/pdf/signatures/original/${report.ticket}`, {
            responseType: 'arraybuffer'
        })
        dispatch(setLocalSignaures({
            localSignatures: signatures.data
        }))
        const response = await HttpRequest.get('/browser/report/' + report.ticket + '?t=' + new Date().getTime())

        dispatch(removeTask('reloading signatures'))
        modalDispatch({
            type: ACTIONS.SET_STATUS,
            payload: {
                messages: ['Generando tu par de llaves'],
                status: MODAL_STATUS.SIGNING,
                currentSignatures: response.data.firmas.length
            }
        })

    }

    function handlePinSuccess(pin) {
        dispatch(setPin({ pin }))
        modalDispatch({
            type: ACTIONS.SET_STATUS,
            payload: {
                messages: messages,
                status: MODAL_STATUS.SIGNING,
                currentSignatures
            }
        })
    }

    return (
    <React.Fragment>
            
        <Modal isOpen={isOpen}>
            <ModalBody style={{ textAlign: 'center', paddingTop: '20px' }}>
                {
                    status === MODAL_STATUS.OCSP_TIMESTAMP_ERROR &&
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                {t('errorSigning')}
                            </h3>
                            <br />
                            <p>{t('errorSigningOcspTimestamp')}</p>
                            <SolidButton onClick={() => { window.location.reload() }}>{t('accept')}</SolidButton>
                            <br />
                        </div> 
                }
              {
                    status === MODAL_STATUS.RELOAD_ERROR ?
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                {t('errorSigning')}
                            </h3>
                            <br />
                            <p>{t('errorSigning2')}</p>
                            <p> 
                                {t('errorSigning3')}
                            </p>
                            <p>
                                {t('errorSigning4')}
                            </p>
                            <SolidButton onClick={() => { window.location.reload() }}>
                                {t('Aceptar')}
                            </SolidButton>
                            <br />
                        </div> : null
                }

                {
                    status === MODAL_STATUS.OCSP_ERROR ?
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                {t('errorOCSP')}
                            </h3>
                            <br />
                            <p>{t('errorOCSP2')}</p>
                            <p>{t('errorOCSP3')}</p>
                            <SolidButton onClick={handleOkErrorClick}>{t('accept')}</SolidButton>
                            <br />
                        </div> : null
                }
                {
                        status === MODAL_STATUS.OCSP_INVALID_FIRMAMEX ||
                            status === MODAL_STATUS.OCSP_INVALID_PSC ?
                            <div>
                                <h3 style={{ color: palette.accentText }}>
                                    {t('invalidOCSP')}
                                </h3>
                                <br />
                                <InvalidOCSPMessage t={t} status={status} />
                                <SolidButton onClick={handleOkErrorClick}>{t('accept')}</SolidButton>
                                <br />
                            </div> : null
                }
                {
                    status === MODAL_STATUS.DOC_CHANGED ?
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                {t('documentChanged')}
                            </h3>
                            <br />
                            <p>{t('documentChanged2')}</p>
                            <p>{t('documentChanged3')}</p>
                        </div> : null
                }
                {
                    status === MODAL_STATUS.ALREADY_SIGNED ?
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                
                            </h3>
                            <br />
                            <p>{t('sameSignature2')}</p>
                            <SolidButton onClick={() => { window.location.reload() }}>{t('accept')}</SolidButton>
                            <br />
                        </div> : null
                }
                {
                    status === MODAL_STATUS.SIGNED ?
                        <div>
                            <h3 style={{ color: palette.accentText }}>
                                {t('documentSigned')} <br />{t('successfully')} 
                    </h3>
                            <br />
                            <ImgSuccess></ImgSuccess>
                            <br/>
                            {
                                // tiene attachments y se firmo el ultimo sticker
                                hasAttachments && report.boxes.filter(b => !b.signed).length === 1 ?
                                <div> 
                                    <br/>
                                    <p>{t(attachmentsStatus)}</p>
                                    {
                                        attachments && attachments.length > 0 ?
                                        <div>
                                            <div>
                                                {t('download attachments here')}
                                            </div>
                                            {
                                                attachments.map(f => (
                                                    <div key={f}>
                                                        <a href={`${HttpRequest.backend}/pdf/attachments/${report.ticket}/${f}`}>{f}</a>
                                                    </div>
                                                ))
                                            }
                                        </div> : null
                                    }
                                </div> : null
                            }
                              <br/>                                        
                            <FlexColumn alignItems={'center'}>
                                {
                                    !hasAccount && (window.location.href.includes('docmage') || window.location.href.includes('firmamex.com'))  &&
                                    <>
                                        <SolidButton 
                                        tag="a" href="https://www.firmamex.com/prueba-gratis/" 
                                        target="_blank">{t('Prueba gratis Firmamex')}</SolidButton>
                                    </>
                                }
                                <SolidButton color="secondary" onClick={handleClickOnComplete}>{t('ok')}</SolidButton>
                            </FlexColumn>
                            <br/>
                            {
                                report.inFlow && (!report.allSigned || report.boxes.filter(b => !b.signed) >= 1) ?
                                <p style={{margin:'0 30px'}}>
                                    {t('documentInFlow')}
                                </p> : null
                            }   
                            {
                                // askForFeedback && (signableStickers.signableStickers && signableStickers.signableStickers.length === 1) &&
                                //     (window.location.href.includes('docmage') || window.location.href.includes('firmamex.com') || window.location.href.includes('xpertal')) ? 
                                // <UserFeedbackForm onChange={handleChangeFeedback}></UserFeedbackForm> : null
                            }  
                        </div> : null
                }
                {
                        status === MODAL_STATUS.SIGNING || status === MODAL_STATUS.REQUEST_PIN ?
                        <div className='imageDialog'>
                            <img src={`${process.env.REACT_APP_CDN_PATH}/images/firmando.svg`}></img>
                            <div className="imageDialogSeparator"></div>                    
                            <p className='imageDialogTitle'>
                                {t('signing')}
                            </p>
                            <br/>
                            {
                                messages.length > 0 ?
                                    <div>
                                        {t(messages[messages.length - 1])}
                                    </div>
                                : null
                            }
                        </div>  
                        : null
                }    
                    <br />
                    <TransitionGroup>
                        {
                            status === MODAL_STATUS.REQUEST_PIN ?
                                <CSSTransition timeout={300} classNames="move" unmountOnExit>
                                    <PinInput
                                        ticket={report.ticket}
                                        sticker={signableStickers.signableStickers.find(s => s.id === signableStickers.active.id).sticker}
                                        onSuccess={handlePinSuccess}
                                        onCancel={handleOkErrorClick}></PinInput>
                                </CSSTransition> : null
                        }
                    </TransitionGroup>
                    <br />
                    <Spinner show={status === MODAL_STATUS.SIGNING || status === MODAL_STATUS.REQUEST_PIN}></Spinner>
                </ModalBody>
                {
                    status === MODAL_STATUS.DOC_CHANGED ?
                        <ModalFooter>
                            <SolidButton onClick={restartSignature}>{t('confirm')}</SolidButton>
                            <SolidButton color="secondary" onClick={handleOkErrorClick}>{t('cancel')}</SolidButton>
                        </ModalFooter> : null
                }
            </Modal>
            <GeoLocateOverlay isOpen={locationAllowed === false}></GeoLocateOverlay>
        </React.Fragment>
    )

}
