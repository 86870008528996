import React from 'react';
import styles from './DesktopSideBar.module.css'
import {UncontrolledTooltip} from 'reactstrap'
import {useSelector, useDispatch} from 'react-redux'
import {logout} from '../redux/actions'
import {IS_MOBILE} from '../Consts'
import { usePalette } from './Customizables';

import { useTranslation } from 'react-i18next';
import HttpRequest from "./HttpRequest";

export default function DesktopSideBar() {

    const {t} = useTranslation()
    const jwt = useSelector(state => state.session.current.jwt)
    const dispatch = useDispatch();
    const palette = usePalette();

    async function handleClickLogout() {
        await HttpRequest.post('/user/logout')
        dispatch(logout())
    }

    return (
            jwt && !IS_MOBILE ? 
            <div className={styles.container} style={{background:palette.secondaryColor}}>
                <img src={`${process.env.REACT_APP_CDN_PATH}/images/icon_logout.svg`} alt="logout" id="sidebarLogout"
                    onClick={handleClickLogout}></img>
                <UncontrolledTooltip target="sidebarLogout">
                    {t('logOut')}
                </UncontrolledTooltip>
            </div> : null     
    )
}