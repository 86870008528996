import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HttpRequest from '../shared/HttpRequest';
import axios from 'axios';

export default function useUniversalAvailable() {

    const [available, setAvailable] = useState(false);
    const [universalNom151Available, setUniversalNom151Available] = useState(false)
    const {report} = useSelector(state => state.document)


    useEffect(() => {

        let timeout;
        let source;
        async function fetchUniversalAvailable() {
            source = axios.CancelToken.source();
            const response = await HttpRequest.get(`/document/${report.ticket}/pending`, {cancelToken: source.token});
            if(response.data.pending) {
                timeout = setTimeout(fetchUniversalAvailable, 4000)
            } 
            setAvailable(!response.data.pending && response.data.universalSignatures > 0);
            setUniversalNom151Available(!response.data.pending && response.data.universalNom151Signatures > 0)
        }

        if(report.firmas.length > 0) {              
            timeout = setTimeout(fetchUniversalAvailable, 8000)
        }

        return () => {
            if(source) {
                source.cancel();
            }
            if(timeout) {
                clearTimeout(timeout);
            }
        }

    }, [report.ticket, report.firmas.length])

    return [available, universalNom151Available];

}