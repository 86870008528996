import React from 'react';
import {Button} from 'reactstrap';
import styles from './SolidButton.module.css';
import { usePalette } from './Customizables';

export default function ({forwardRef, className, color, flexGrow, noMargin, style, rechazar, ...rest}) {
    const palette = usePalette();
    
    const background = (rechazar) ? "#FFFFFF" : color && color === 'warning' ? '#dc3545' : color === 'secondary' ? palette.secondaryAction : palette.mainAction;
    const margin = noMargin ? styles.noMargin : styles.margin

    return <Button {...rest} className={`btn ${rechazar ? styles.button2 : styles.button} ${className || ''} ${margin}`}
        innerRef={forwardRef}
        style={{
            background,
            borderColor: background,
            flexGrow: flexGrow ? "1" : "0",
            ...style
        }}
        ></Button>
}