import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { hideAlert } from '../redux/actions';

import { useTranslation } from 'react-i18next';


export default function AlertModal({children, isOpen}) {

    const {t} = useTranslation()
    const dispatch = useDispatch();

    function handleClick() {
        dispatch(hideAlert());
    }


    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" 
                    style={{
                        margin:'auto'
                    }}
                    onClick={handleClick}>{t('confirm')}</Button>
            </ModalFooter>
        </Modal>
    )

}