import {SET_CURRENT_IMAGE} from '../actionTypes';

const initialState = {
    currentImage: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CURRENT_IMAGE: {
            const {data} = action.payload;
            return {
                ...state,
                currentImage: data
            }
        }
        default: {
            return state;
        }
    }
}