import {
    ADD_TASK, REMOVE_TASK, UPDATE_TASK,
    ADD_SIGNABLE_STICKER, SET_ACTIVE_STICKER, SET_SIGNABLE_STICKERS,
    SET_DOCUMENT, SET_REPORT, LOAD_REPORT, ADD_HTML_SIGNATURE_IMG, SET_LOCAL_SIGNATURES,
    SET_CURRENT_IMAGE,    
    RESET_STATE, 
    SET_SIGNING, SET_DRAWING_SIGNATURE, SET_VERIFYING_USER, SET_FUTURE_VERIFYING_USER, SET_PICKING_SIGNATURE_STYLE,
    LOGIN, LOGOUT, GET_PKCS8_DATA, GET_X509_DATA, CLEAR_ACTIVE_STICKER, GO_TO_APP1,
    SET_CLIENT_DATA, LOAD_USER, SET_WAITING_NOM151_TIMESTAMP,
    SET_SIGNATURE_STROKE, SET_SIGNATURE_IMAGE, SAVE_PASSPHRASE, REMOVE_HTML_SIGNATURE_PAGE, SET_SIGNATURE_STROKE_IMAGE, SET_ACTIVE_STICKER_IMAGE, ADD_HTML_UPDATE_PAGE_QUEUE, SET_SHOWING_ALERT, SET_UPLOADING_DOCUMENT, CHANGE_DOCUMENT, SET_ADDING_DRAGGABLE, 
    SET_CUSTOMIZABLES, SET_CUSTOMIZABLES_DEFAULT, CHANGE_ORG, SET_NEXT_SET_DOCUMENT, SET_TRANSLATE, ADD_TRANSLATE, SET_PIN, OAUTH_RELOGIN
} from './actionTypes'

import HttpRequest from '../shared/HttpRequest'
import Stickers from '../utils/Stickers'
import ImageGenerator from '../utils/ImageGenerator'
import {
    appendToArray,
    buildQueryParams,
    checkStickerOrder,
    retryPromiseUntilSuccess,
    wait,
    waitForElem
} from '../utils/toolbox'
import qs from 'qs';
import ParsedCertificate from '../crypto/ParsedCertificate'
import Certificate from '../crypto/Certificate'
import FrmxFirebase from '../utils/FrmxFirebase'
import { Button } from 'reactstrap'

function splitLinkSub(linkData) {
    const symbolIndex = linkData.lastIndexOf('__');
    return [
        linkData.substr(0, symbolIndex),
        linkData.substr(symbolIndex + 2)
    ]
}

function parseQueryString(queryString) {
    let response = {};
    if(queryString) {
        const parsedQueryString = qs.parse(queryString, { ignoreQueryPrefix: true });
        const signerLink = parsedQueryString.signerLink;
        
        if(signerLink) {
            const linkData = splitLinkSub(JSON.parse(atob(signerLink.split('.')[1])).sub)
            response = {...response, signerLink: {...linkData, link: signerLink}, queryString}
        }  
        const setLink = parsedQueryString.setLink;
        if(setLink) {
            const linkData = JSON.parse(atob(setLink.split('.')[1])).sub.split('__');
            response = {...response, setLink: {...linkData, link: setLink}, queryString}
        }  
    }     
    return response;;
}


export const addTask = (id, description, component) => ({
    type: ADD_TASK,
    payload: {
        id,
        description,
        label:'',
        component,
        type: 'task'
    }
})

export const addError = (id, label, description, action) => ({
    type: ADD_TASK,
    payload: {
        id,
        description,
        label,
        type: 'error',
        component: ({ label }) => 
            <div>
                <Button color='primary' onClick={action}>
                    {label}
                </Button>
            </div>
    }
})

export const removeTask = id => ({
    type: REMOVE_TASK,
    payload: {
        id
    }
})

export const updateTask = (id, component) => ({
    type: UPDATE_TASK,
    payload: {
        id,
        component
    }
})

export const setNextSetDocument = (nextSetDocument) => ({
    type: SET_NEXT_SET_DOCUMENT,
    payload: {nextSetDocument}
})

export const setSignableStickers = ({signableStickers, nextSetDocument, activeSignerFound}) => ({
    type: SET_SIGNABLE_STICKERS,
    payload: {signableStickers, nextSetDocument, activeSignerFound}
})

export const addSignableSticker = (id, sticker) => ({
    type: ADD_SIGNABLE_STICKER,
    payload: {id, sticker}
})

export const setActiveStickerWithImage = (id, status, img, singleUse) => ({
    type: SET_ACTIVE_STICKER,
    payload: {id, status, img, singleUse}
})

export const clearActiveSticker = () => ({
    type: CLEAR_ACTIVE_STICKER
})

export const setDocument = ({document}) => ({
    type: SET_DOCUMENT,
    payload: {document}
})

export const setDocStatus = ({status, updatedAt}) => ({
    type: 'SET_DOC_STATUS',
    payload: {status, updatedAt}
})

export const setLocalSignaures = ({localSignatures}) => ({
    type: SET_LOCAL_SIGNATURES,
    payload: {localSignatures}
})

export const setReport = report => ({
    type: SET_REPORT,
    payload: {report}
})

export const setCurrentImage = data => ({
    type: SET_CURRENT_IMAGE,
    payload: {data}
})

export const addHtmlSignatureImg = ({signatureImg, lx, ly, width, height, page, authority, line}) => ({
    type: ADD_HTML_SIGNATURE_IMG,
    payload: {signatureImg, lx, ly, width, height, page, authority, line}
})

export const removeHtmlSignatureImgPage = ({page}) => ({
    type: REMOVE_HTML_SIGNATURE_PAGE,
    payload: {page}
})

export const resetState = () => ({
    type: RESET_STATE,
    payload: {}
})

export const changeDocument = () => ({
    type: CHANGE_DOCUMENT,
    payload: {}
})

export const addDraggable = (draggable) => ({
    type: SET_ADDING_DRAGGABLE,
    payload: {
        working: true,
        draggable
    }
})

export const removeDraggable = () => ({
    type: SET_ADDING_DRAGGABLE,
    payload: {
        working: false,
        draggable: {}
    }
})

export const setSigning = ({signing}) => ({
    type: SET_SIGNING,
    payload: {signing}
})

export const setDrawingSignature = ({drawingSignature}) => ({
    type: SET_DRAWING_SIGNATURE,
    payload: {drawingSignature}
})

export const setPickingSignatureStyle = ({pickingSignatureStyle}) => ({
    type: SET_PICKING_SIGNATURE_STYLE,
    payload: {pickingSignatureStyle}
})

export const uploadDocument = ({file}) => ({
    type: SET_UPLOADING_DOCUMENT,
    payload: {
        working: true,
        file
    }
})

export const hideUploadingDocument = () => ({
    type: SET_UPLOADING_DOCUMENT,
    payload: {
        working: false,
        file: null
    }
})

export const showAlert = ({children}) => ({
    type: SET_SHOWING_ALERT,
    payload: {
        children,
        working: true
    }
})

export const hideAlert = () => ({
    type: SET_SHOWING_ALERT,
    payload: {
        children: null,
        working: false
    }
})

export const setVerifyingUser = ({verifyingUser}) => ({
    type: SET_VERIFYING_USER,
    payload: {verifyingUser}
})

export const setFutureVerifyingUser = ({verifyingUser}) => ({
    type: SET_FUTURE_VERIFYING_USER,
    payload: {verifyingUser}
})

export const savePassphrase = passphrase => ({
    type: SAVE_PASSPHRASE,
    payload: {passphrase}
})

export const login = (userData) => ({
    type: LOGIN,
    payload: userData
})

export const oAuthRelogin = userData => ({
    type: OAUTH_RELOGIN,
    payload: userData
})

export const logout = () => ({
    type: LOGOUT
})

export const goToApp1 = () => ({
    type: GO_TO_APP1
})

export const setClientData = (clientData) => ({
    type: SET_CLIENT_DATA,
    payload: clientData
})

export const setSignatureImage = (signatureImage) => ({
    type: SET_SIGNATURE_IMAGE,
    payload: signatureImage
})

export const setSignatureStroke = (signatureStroke) => ({
    type: SET_SIGNATURE_STROKE,
    payload: signatureStroke
})

export const setSignatureStrokeImage = (signatureStrokeImage) => ({
    type: SET_SIGNATURE_STROKE_IMAGE,
    payload: signatureStrokeImage
})

export const loadUser = (userId) => ({
    type: LOAD_USER,
    payload: userId
});

export const changeOrg = (jwt) => ({
    type: CHANGE_ORG,
    payload: jwt
})


export const setConfTranslate = (translate) => ({
    type: SET_TRANSLATE,
    payload: translate
})

export const addTranslation = translate => ({
    type: ADD_TRANSLATE,
    payload: translate
})

export const setPin = pin => ({
    type: SET_PIN,
    payload: pin
})

export const clearPin = () => ({
    type: SET_PIN,
    payload: {
        pin: null
    }
})


/**
 * Thunk
 */

export function changeUserAndActivateSticker(userId, stickerId, status, singleUse) {
    return async function(dispatch) {
        await dispatch(changeUser(userId))
        dispatch(setActiveSticker(stickerId, status, singleUse))
    }
}

export function changeUser(userId) {
    return async function(dispatch) {
        await dispatch(loadUser(userId));
        await dispatch(evalStickers())
        await dispatch(loadClientData())
        await dispatch(loadSignatureImage())        
        await dispatch(loadSignatureStroke())
    }
}

export function loginAndLoadData(userData) {
    return async function(dispatch, getState) {
        await dispatch(login(userData));
        await dispatch(evalStickers())
        await dispatch(loadClientData());
        // dispatch(loadSignatureImage())        
        dispatch(loadSignatureStroke());        
    }
}


export function changeOrgAndLoadData(jwt) {
    return async function(dispatch, getState) {
        await dispatch(changeOrg(jwt));
        await dispatch(loadClientData());
        await dispatch(loadSignatureStroke());
    }
}

/**
 * Thunk
 */
export function lookForNextSticker() {
    return async function(dispatch, getState) {
        const {session, document} = getState();
        const report = document.report;
    }
}


/**
 * Thunk
 * Carga la informacion del cliente correspondiente
 * al usuario actual
 */
export function loadClientData() {
    return async function(dispatch, getState) {
        const {session} = getState();
        if(session.current && session.current.jwt) {
            await HttpRequest.get('/session/update');
            const response = await HttpRequest.get('/acc/client_data');
            dispatch(setClientData(response.data))
        }        
    }
}

export function loadSignatureImage() {
    return async function(dispatch, getState) {
        const {session} = getState();
        if(session.current && session.current.jwt) {
            const response = await HttpRequest.get('/user/img/existing');
            dispatch(setSignatureImage(response.data))
        }
    }
}

export function loadSignatureStroke() {
    return async function(dispatch, getState) {
        const {session} = getState();
        if(session.current && session.current.jwt) {            
            let image = await HttpRequest.get('/user/img/custom');
            if(image.data && image.data.result === 'success') {
                dispatch(setSignatureStroke({isCustomImage: true, ...image.data}));                
            } else {
                image = await HttpRequest.get('/user/img/stroke');
                dispatch(setSignatureStroke(image.data));         
            }
        }
    }
}


async function scrollToSticker(dispatch, sticker) {
        dispatch(addTask('loading-page', 'Cargando pagina de firma'))
        const elem = await waitForElem(() => {
            return document.getElementById(`page.${sticker.page}`)
        })
        dispatch(removeTask('loading-page'))
        scrollTo({id:'pdf-renderer-container', top:elem.offsetTop + 100, behavior:'smooth'})
}    

function scrollTo({id, top, behavior}) {
    const elem = document.getElementById(id);
    if(elem.scrollTo) {
        elem.scrollTo({top, behavior})
    } else {
        elem.scrollTop = top
    }
}

/**
 * Thunk
 * Marca a un sticker como activo para que muestre
 * el trazo de firma (no significa que ya se esta firmando)
 * @param {*} id 
 * @param {*} status 
 */
export function setActiveSticker(id, status, singleUse, fromSignup) {
    return async function(dispatch, getState) { 

        if(status === 'click') {
            dispatch(setActiveStickerWithImage(id, status, null, singleUse))
            return;
        }
        
        const {session, document, config} = getState()
        const sticker = document.report.boxes.find(b => b._id === id)       
        
        if(fromSignup) {
            await scrollToSticker(dispatch, sticker)
        }
        
        const authority = session.current.x509.authority;
        const cert = ParsedCertificate.fromPem(session.current.x509.pem);
        const fullDoc = appendToArray(document.document, document.localSignatures);
        
        let img = null;
        
        if(!sticker.imageType || sticker.imageType.includes('any')) {
            if(!session.current.signatureImage) {
                const commonName = cert.getCommonName();
                img = await new Promise((resolve, reject) => {
                    dispatch(setPickingSignatureStyle({
                        pickingSignatureStyle: {
                            working: true,
                            data: {
                                commonName,
                                issuerName: authority,
                                cancelable: false,
                                stickerId: id,
                                requestedImageType: sticker.imageType,
                                resolve,
                                reject
                            }
                        }
                    }))
                })
                dispatch(setPickingSignatureStyle({
                    pickingSignatureStyle: {
                        working: false,
                        data: {}
                    }
                }))

                if(!img) {
                    dispatch(clearActiveSticker())
                    return;
                }

            }
        }
        
        if(!img) {
            img = await ImageGenerator.generateImageForSticker(sticker, document.report.ticket, session.current.signatureImage);    
        }

        if(img != null) {
            await HttpRequest.post('/browser/sticker/img', {
                b64Image: img.data,
                boxId: id
            })
            dispatch(setActiveStickerWithImage(id, status, img, singleUse));
        } else {
            dispatch(clearActiveSticker())
        } 
    }
}

export function setActiveStickerImage(img) {
    return async function(dispatch, getState) {
        const {signableStickers} = getState();        
        dispatch({
            type: SET_ACTIVE_STICKER_IMAGE,
            payload: {img}
        })
    }
}

/**
 * Thunk
 * Marca a un sticker como activo (muestra su imagen) y pasa el app al 
 * estado de firma al mismo tiempo. En otras palabras dice:
 * "Se esta firmando este sticker"
 * @param {*} param0 
 */
export function setSigningSticker({fromSignup, signing}) {
    return async function(dispatch, getState) {
        await dispatch(setActiveSticker(signing.data.stickerId, 'signing', false, fromSignup));
        const {signableStickers} = getState();
        
        if(signableStickers.active) {
            dispatch(setSigning({signing}))
        }        
    }
}

export function loadOAuthCertificate(ticket, sticker) {
    return async function(dispatch, getState) {

        const {session} = getState()

        if(!sticker) {
            if(!session?.current?.x509?.pem) {
                return {};
            }
    
            if(!session.current.x509.signupAuthority.includes('Google') &&
                !session.current.x509.signupAuthority.includes('Identity Provider')) {
                return {};
            }

            const existingCert = ParsedCertificate.fromPem(session.current.x509.pem)
            if(!existingCert.isExpired()) {
                return {};
            }
        }        

        dispatch(addTask('/signing/oauth', 'Cargando certificado'));

        const fbUser = JSON.parse(localStorage.getItem('fbUser'))

        let verificationResult = null;
        let newPkcs8 = null;
        const passphrase = crypto.randomUUID();

        while(verificationResult === null) {

            const csrData = await retryPromiseUntilSuccess(Certificate.generateCsr, {
                certType: 'email',
                name: fbUser.email,
                email: fbUser.email
            })


            newPkcs8 = Certificate.changePassphrase(csrData.pkcs8Pem, 'firmamex-temp', passphrase)

            dispatch(addTask('/signing/oauth', 'Firmando certificado'));

            const signed = await Certificate.signCsr({...csrData, pkcs8Pem: newPkcs8}, passphrase,
                fbUser.email,
                {
                    id: null,
                    verificationType: 'FIREBASE'
                },
                'EMAIL',
                null,
                null,
                null,
                ticket,
                null,
                null)

            dispatch(addTask('/signing/oauth', 'Generando token'));

            let token = null;
            try {
                let error = null;
                ({token, error} = await FrmxFirebase.frmxVerify(true));

                if(error) {
                    dispatch(addError('/signing/oauth/error', 'logout and retry','firebase token error', async () => {
                        await HttpRequest.post('/user/logout')
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }))
                    return {error};
                }

            } catch (e) {
                console.error(e);
                await wait(1000);
                continue;
            }


            dispatch(addTask('/signing/oauth', 'Verificando token'));
            verificationResult = await Certificate.waitUntilVerified(signed);
        }


        const certPem = Certificate.certificateFromB64(verificationResult.cert);  
        const cert = ParsedCertificate.fromPem(certPem);  
        

        if(!sticker) {
            const userData = {
                passphrase,
                jwt: verificationResult.jwt,
                x509: {
                    pem: certPem,
                    chain: verificationResult.certChain,
                    data: cert.getCommonName(),
                    authority: cert.getIssuerName(),
                    signupAuthority: 'Vinculada a Correo por Identity Provider'
                },
                pkcs8: {
                    pem: newPkcs8
                }
            }

            await dispatch(oAuthRelogin(userData))

            dispatch(removeTask('/signing/oauth'));

            return { userData };

        } else {         
            
            dispatch(addTask('/signing/oauth', 'Guardando verificación'));
            
            dispatch(login({
                jwt: verificationResult.jwt
            }))

            const checkoutResponse = await HttpRequest.post('/signup/checkout', {
                clientData:{},
                customer: {
                    email: fbUser.email
                }
            })
    
            const existingSignatureStroke = await HttpRequest.get('/user/img/stroke');
    
            await dispatch(loginAndLoadData({
                passphrase,
                jwt: verificationResult.jwt,
                x509: {
                    pem: certPem,
                    data: cert.getCommonName(),
                    authority: cert.getIssuerName(),
                    signupAuthority: 'Vinculada a Correo por Identity Provider',
                    chain: checkoutResponse.data.data.certChain
                },
                pkcs8: {
                    pem: newPkcs8
                },
                signatureStroke: existingSignatureStroke.data
            }))
    
            dispatch(setVerifyingUser({
                verifyingUser: {
                    working: false,
                    data: {}
                }
            }))  
    
            dispatch(removeTask('/signing/oauth'));

            dispatch(setSigningSticker({
                fromSignup: true,
                signing: {
                    working: true,
                    data: {
                        stickerId: sticker._id,
                        password: passphrase
                    }
                }
            }))            
        }

    }
}

/**
 * Thunk
 * Carga el reporte del documento, detecta si hay stickers que
 * se pueden firmar y agrega las firmas a signaturePages para 
 * que se les pueda dar click y abrirlas en el reporte de firmantes
 * @param {*} ticket 
 * @param {*} queryString 
 */
export function loadReport(ticket, queryString) {
    return async function(dispatch, getState) {
        const {document} = getState()

        dispatch(resetState())
        dispatch(addTask('/browser/report', 'Cargando documento'))   
        const response = await HttpRequest.get('/browser/report/' + ticket + '?t=' + new Date().getTime())
       
        let pendingNotifications = {data: { hasPendingNotifications: false }};
        // try {
        //     pendingNotifications = await HttpRequest.get(`/signers/${ticket}/hasPendingNotifications`);
        // } catch(e) {
        //     pendingNotifications = {
        //         data: {
        //             hasPendingNotifications: false
        //         }
        //     }
        // }        
        
        let data = response.data;
        data.stickerPages = [];
        data.signaturePages = [];
        data = {boxes:[], ...data, ...parseQueryString(queryString), hasPendingNotifications: pendingNotifications.data.hasPendingNotifications}

        if(data.boxes) {     
            data.boxes.sort((b1, b2) => b1.page - b2.page)          
            // revisamos si el usuario puede firmar uno de los stickers 
            for(let b of data.boxes) {

                if(b.signed) {
                    // ya esta firmado, lo saltamos
                    continue;
                }

                if(!data.stickerPages.find(p => p.page === b.page)) {
                    data.stickerPages.push({page: b.page, stickers:[]})
                }
                const sp = data.stickerPages.find(p => p.page === b.page);
                sp.stickers.push(b);
            }
        }

        if(data.firmas) {
            for(let f of data.firmas) {
                const page = f.page - 1;
                if(!data.signaturePages.find(p => p.page === page)) {
                    data.signaturePages.push({page, signatures:[]})
                }
                const sp = data.signaturePages.find(p => p.page === page);
                if(sp) {
                    sp.signatures.push({...f, page})
                }                
            }
        }

        if(data.ordered) {
            data.nextSignerSticker = data.boxes.find(b => b.order === data.nextSigner);
            if(data.nextSignerSticker && data.nextSignerSticker.signed) {
                data.nextSigner = data.nextSigner + 1;
                data.nextSignerSticker = data.boxes.find(b => b.order === (data.nextSigner));
            }
        }

        await dispatch(setReport(data))
        await dispatch(evalStickers())
        dispatch(removeTask('/browser/report'))

        if(!document.report.dirty) {
            // no es la primera vez que se carga el reporte
            const lastPages = document.report.signaturePages;
            const updatePages = [];
            for(let sp of data.signaturePages) {
                const sc = lastPages.find(p => p.page === sp.page);
                if(!sc || (sc.signatures.length !== sp.signatures.length)) {
                    updatePages.push(sp.page)
                } 
            }
            if(updatePages.length > 0) {
                await dispatch({
                    type: ADD_HTML_UPDATE_PAGE_QUEUE,
                    payload: {
                        update: {
                            idx: new Date().getTime(),
                            pages: updatePages
                        }
                    }
                })
            }
        }

        if(data.nom151TimestampStatus === 'waiting') {
            // hay que actualizar cuando este lista la estampilla
            await dispatch({
                type: SET_WAITING_NOM151_TIMESTAMP, 
                payload: {
                    waitingNom151Timestamp: {
                        working: true
                    }
                }
            })
        } else {
            await dispatch({
                type: SET_WAITING_NOM151_TIMESTAMP, 
                payload: {
                    waitingNom151Timestamp: {
                        working: false
                    }
                }
            })   
        }
    }
}

export function fetchCustomizables(ticket) {
    return async function (dispatch, getState) {    

        try {

            const response = await HttpRequest.get(`/app2/view/${ticket || 'default'}`);

            const supportConfig = response.data.supportConfig ?
                JSON.parse(response.data.supportConfig)
                : { show: false}

            if(response.data && supportConfig.show && window.loadJiraHelpdesk) {
                window.loadJiraHelpdesk(supportConfig.key)
            }

            if(response.data.translate) {
                await dispatch(addTranslation(response.data.translate))
            }

            if(response.data.customStyle && response.data.palette) {
                await dispatch({
                    type: SET_CUSTOMIZABLES,
                    payload: {...response.data}
                })
            } else {
                dispatch({
                    type: SET_CUSTOMIZABLES_DEFAULT,
                    payload: {
                        hideConfirmation: response.data.hideConfirmation
                    }
                })    
            }       

        } catch(e) {
            console.error(e)
            dispatch({
                type: SET_CUSTOMIZABLES_DEFAULT,
            })
        }        
        
    }
}

export function evalStickers() {
    return async function (dispatch, getState) {

        await dispatch(resetState())

        const {session, document} = getState();
        const report = document.report;

        let signableStickers = [];
        let nextSetDocument = null;
        let activeSignerPresent = false;
        let activeSigner = '';
        let activeSignerFound = false;

        if(report.boxes) {     

            const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            if(parsedQueryString.activeSigner) {
                let activeSignerLower = parsedQueryString.activeSigner.toLocaleLowerCase();
                if(activeSignerLower) {
                    activeSigner  = activeSignerLower;
                    activeSignerPresent = true;
                }
            }
            // revisamos si el usuario puede firmar uno de los stickers 
            // primero buscamos los stickers por liga        
            let stickerFound = false;    
            // stickers por liga
            const singleUse = report.signerLink && report.boxes.filter(b => !b.signed && b.authority.singleUse && b.data.toLowerCase() === report.signerLink[0].toLowerCase());            
            if(singleUse) {
                for(let b of singleUse) {          
                    // dispatch(addSignableSticker(b._id, b));
                    signableStickers.push({id: b._id, sticker: b})
                    stickerFound = true;
                }
            }

            // stickers por liga dentro del expediente
            const singleUseSet = report.setLink && report.boxes.filter(b => !b.signed && b.authority.singleUse && b.data.toLowerCase() === report.setLink[0].toLowerCase());
            if(singleUseSet) {
                for(let b of singleUseSet) {                    
                    signableStickers.push({id: b._id, sticker: b})
                    // dispatch(addSignableSticker(b._id, b));
                    stickerFound = true;
                }
            }


            // despues buscamos alguno que corresponda
            // al usuario
            const noSingleUse = report.boxes.filter(b => !b.signed && !b.authority.singleUse);
            for(let b of noSingleUse) {
                if(activeSignerPresent) {
                    let stickerDataLower = b.data.toLocaleLowerCase();
                    if(stickerDataLower === activeSigner) {
                        activeSignerFound = true;
                    }
                }

                if(session.current.jwt && Stickers.validate(b, session.current)) {
                    if(activeSignerPresent) {
                        if(b.data.toLocaleLowerCase() === activeSigner) {
                            signableStickers.push({id: b._id, sticker: b})
                            stickerFound = true;
                        }
                    } else {
                        signableStickers.push({id: b._id, sticker: b})
                        stickerFound = true;
                    }
                    // sesion iniciada y sticker valido para la sesion
                    // if(checkStickerOrder(report, b)) {
                        //signableStickers.push({id: b._id, sticker: b})
                        // dispatch(addSignableSticker(b._id, b));
                        //stickerFound = true;
                    // } 
                }
            }
            
            if(report.setLink && !stickerFound) {
                // si no se encontro ningun sticker y el documento
                // esta en un expediente, revisamos si hay otro documento
                // en el expediente que pueda firmar el usuario
                
                // si el usuario tiene sesion abierta, primero buscamos stickers que pertenezcan a la sesion
                let docSetResponse = {
                    data: []
                };
            
                if(session.current.jwt) {
                    const {data} = session.current.x509;
                    docSetResponse = await HttpRequest.get(`/set/${report.documentSet}/${data}`)
                } 
                
                if(docSetResponse.data.length === 0) {
                    // si no se encontro ningun documento por la sesion del usuario, se busca por el correo
                    // en la liga
                    docSetResponse = await HttpRequest.get(`/set/${report.documentSet}/${report.setLink[0]}`)
                }
                
                const signableSetDocuments = docSetResponse.data.find(d => !d.signed && d.firmamexId !== report.ticket);
                
                if(signableSetDocuments) {
                    nextSetDocument = `${signableSetDocuments.firmamexId}?setLink=${report.setLink.link}`;
                    // dispatch(setNextSetDocument(`${signableSetDocuments.firmamexId}?setLink=${report.setLink.link}`))
                    // window.location.href = `/pdf/${docSetResponse.data[0]}?setLink=${report.setLink.link}`
                } else {
                    // nextSetDocument = null;
                    // dispatch(setNextSetDocument(null))
                }
            }

            if(!stickerFound) {
                const withPin = report.boxes.filter(b => !b.signed && b.authority.requirePin);
                for(let b of withPin) {
                    signableStickers.push({id: b._id, sticker: b})
                }
            }
        }


        dispatch(setSignableStickers({signableStickers, nextSetDocument, activeSignerFound}))

    }
}


export function loadConfig() {
    return async function(dispatch, getState) {

        const response = await HttpRequest.get('/translate/default');
        dispatch(setConfTranslate(response.data));

    }
}
