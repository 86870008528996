import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SignButton from '../SignButton';
import Expediente from '../Expediente';
import {ellipsisTrimmer} from '../../utils/toolbox';
import styles from './AcertiaSideBar.module.css';
import { useTranslation } from 'react-i18next';

export default withRouter(function AcertiaSideBar({rendererSize, currentPage, title, history}) {

    const {t} = useTranslation()
    const [modal, setModal] = useState(false);
    const hasDocument = useSelector(state => !!state.document.document);
    const report = useSelector(state => state.document.report)
    const signableStickers = useSelector(state => state.signableStickers.signableStickers);

    const toggle = () => setModal(!modal);

    return(
        <div className={styles.container} id="acertia-sidebar">
            <div className={styles.doctoName}>
                {title && ellipsisTrimmer(title, 27)}.pdf
            </div>
            <div className={styles.actionsDiv}>
                <SignButton 
                    rendererSize={rendererSize}
                    hasDocument={hasDocument} 
                    signableStickers={signableStickers}
                    currentPage={currentPage}
                    acertiaSideBar={true}
                >
                </SignButton> 
            </div>
            {
                (report.document && report.document.createdAt) ? <div className={styles.docInfoContainer}>
                    <p className={styles.infoTitle}>{t('documentData')}</p>
                    <div className={styles.divDocumentInfo}>
                        <p><strong>{t('title')}:</strong></p>
                        <p className={styles.space}>{title && ellipsisTrimmer(title, 35)}.pdf</p>
                    </div>
                    <div className={styles.divDocumentInfo}>
                        <p><strong>{t('author')}:</strong></p>
                        <p className={styles.space} style={{maxWidth: "180px"}}>{report.document.author}</p>
                    </div>
                    <div className={styles.divDocumentInfo}>
                        <p><strong>{t('createdOn')}:</strong></p>
                        <p className={styles.space} style={{maxWidth: "155px"}}>{new Date(report.document.createdAt).toLocaleString()}</p>
                    </div>
                    <div className={styles.divDocumentInfo}>
                        <p><strong>{t('modifiedOn')}:</strong></p>
                        <p className={styles.space} style={{maxWidth: "109px"}}>{new Date(report.document.updatedAt).toLocaleString()}</p>
                    </div>
                    {
                        report.documentSetName ? <div className={styles.divDocumentInfo}>
                            <p><strong>{t('file')}:</strong></p>
                            <p className={styles.space} style={{maxWidth: "154px"}}>{report.documentSetName}</p>
                        </div> : null
                    }
                    {
                        report.documentSet ? <div className={styles.buttonDiv}>
                            <button className={styles.button} onClick={toggle}>{t('seeFile')}</button>
                        </div> : null
                    }
                </div> : null
            }
            <div className={styles.soporteCenter}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="#AA1F72" d="M11 23.59v-3.6c-5.01-.26-9-4.42-9-9.49C2 5.26 6.26 1 11.5 1S21 5.26 21 10.5c0 4.95-3.44 9.93-8.57 12.4l-1.43.69zM11.5 3C7.36 3 4 6.36 4 10.5S7.36 18 11.5 18H13v2.3c3.64-2.3 6-6.08 6-9.8C19 6.36 15.64 3 11.5 3zm-1 11.5h2v2h-2zm2-1.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z"/></svg>
                <div className={styles.soporteContainer}>
                    <p className={styles.soporteEmail}>soporte@acertia.mx</p>
                </div>
            </div>
            
            <Expediente 
                isOpen={modal} 
                toggle={toggle} 
                onDocumentClick={(firmamexId) => history.push(`/pdf/${firmamexId}/${report.setLink ? `?setLink=${report.setLink.link}` : ''}`)} 
            />
        </div>)
})