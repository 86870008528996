import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useSelector} from 'react-redux';
import { getPageLayer } from '../utils/toolbox';
import AbstractDraggable from './AbstractDraggable';

export default function DraggableLayer({page, pageRendered, scale}) {

    const [container] = useState(document.createElement('div'))
    const [pageSize, setPageSize] = useState(null)
    const addingDraggable = useSelector(state => state.status.addingDraggable)
    const [draggable, setDraggable] = useState(null)

    useEffect(() => {
        if(pageRendered) {
            const pageLayer = getPageLayer(page)
            if(!pageLayer) {
                return;
            }
            setPageSize({
                width: pageLayer.offsetWidth,
                height: pageLayer.offsetHeight
            })
            container.id = `draggable-layer-${page}`;
            container.className = 'draggableLayer'     
            pageLayer.appendChild(container);
            return () => {
                pageLayer.removeChild(container);
            }
        }
    }, [pageRendered, container, page])    

    useEffect(() => {
        if(addingDraggable.working && addingDraggable.draggable.page === page) {
            setDraggable({})
        }

    }, [addingDraggable, page])
    
    return ReactDOM.createPortal((
        <div className="draggable">
            {
                draggable && addingDraggable.working ?
                <AbstractDraggable 
                    {...addingDraggable.draggable}
                    pageSize={pageSize}
                    scale={scale}
                    ></AbstractDraggable> : <div></div>
            }
        </div>), container)

}