

export function validate(sticker, userData) {
    // authority es la autoridad del certificado
    // data es el dato que se tiene que validar para 
    // poder firmar, puede ser RFC, telefono, correo, etc
    const {authority, data, signupAuthority} = userData.x509;

    let certData = data.toLowerCase();        
    if(signupAuthority === 'SAT' && certData.includes('/')) {
        certData = certData.split('/')[0].trim();
    }

    return  sticker.data.toLowerCase() === certData && sticker.authority.authorities.includes(authority);
}

export default {
    validate
}