import React, { Fragment, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {IS_MOBILE} from '../../Consts' 
import CustomModalHeader from '../CustomModalHeader';
import ResponsiveModal from '../ResponsiveModal';
import { Info, FirmarIcon, Imprimir, Share, DownloadOriginal, Universal, NOM151, FirmantesIcon } from '../acertia/AcertiaIcons';
import styles from "./Info.module.css"
import { useTranslation } from 'react-i18next';

function GuiaItem ({ title, instructions, children }) {
    return <Fragment>
        <div className={(IS_MOBILE) ? styles.guiaItemContainerRes : styles.guiaItemContainer}>
            <div className={(IS_MOBILE) ? styles.iconStyleRes : styles.iconStyle}>{children}</div>
            <div>
                <div className={(IS_MOBILE) ? styles.itemNameRes : styles.itemName}><strong>{title}</strong></div>
                <div className={(IS_MOBILE) ? styles.itemDescriptionRes : styles.itemDescription}>{instructions}</div>
            </div>
        </div>
        
    </Fragment>
}
export default function AcertiaInfo() {

    const {t} = useTranslation()
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    }

    return <div style={{ cursor: "pointer"}}>
        {
            IS_MOBILE ? <Info color="#FFFFFF" width="15" height="15" onClick={toggle}/> :
                        <Info color="#FFFFFF" width="36" height="36" onClick={toggle}/>
        }
        <ResponsiveModal isOpen={modal} toggle={toggle} title="GUIA RAPIDA" onCancel={toggle} desktopTitle="GUIA RAPIDA">
            <GuiaItem
                title="Firmar:"
                instructions={t('pressButton1')}
            >
                <FirmarIcon 
                    color="#B70677" 
                    width={(IS_MOBILE) ? "22" : "30"} 
                    height={(IS_MOBILE) ? "22" : "30"}  
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
            <GuiaItem
                title="Imprimir:"
                instructions={t('pressButton2')}
            >
                <Imprimir 
                    color="#B70677" 
                    width={(IS_MOBILE) ? "22" : "30"}
                    height={(IS_MOBILE) ? "22" : "30"}
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
            <GuiaItem
                title="Compartir:"
                instructions={t('pressButton3')}
            >
                <Share 
                    color="#B70677" 
                    width={(IS_MOBILE) ? "22" : "30"}
                    height={(IS_MOBILE) ? "22" : "30"}
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
            <GuiaItem
                title="Original:"
                instructions={t('pressButton4')}
                
            >
                <DownloadOriginal 
                    color="#B70677" 
                    width={(IS_MOBILE) ? "22" : "30"} 
                    height={(IS_MOBILE) ? "22" : "30"} 
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
            <GuiaItem
                title="Uninversal:"
                instructions={t('pressButton5')}
            >
                <Universal 
                    color="#B70677"
                    width={(IS_MOBILE) ? "22" : "30"} 
                    height={(IS_MOBILE) ? "22" : "30"}
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
            <GuiaItem
                title="NOM 151:"
                instructions={t('pressButton6')}
            >
                <NOM151 
                    color="#B70677"
                    width={(IS_MOBILE) ? "22" : "30"} 
                    height={(IS_MOBILE) ? "22" : "30"}
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
            <GuiaItem
                title="Firmantes:"
                instructions={t('pressButton7')}
            >
                <FirmantesIcon 
                    color="#B70677" 
                    width={(IS_MOBILE) ? "23" : "32"} 
                    height={(IS_MOBILE) ? "24" : "32"} 
                    style={(IS_MOBILE) ? { padding: "3px 5px 0 7px" } : null }
                />
            </GuiaItem>
        {/* </ModalBody> */}
      </ResponsiveModal>
    </div>
}