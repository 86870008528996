import React from 'react';
import qs from 'qs';

export default function ErrorScreen({location}) {

    const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})
    let title = ''
    let description = '';
    
    switch(queryParams.type) {
        case 'drive_error': {
            title = `No podemos alcanzar el almacenamiento de ${queryParams.drive}`
            break;
        }
        default: {
            title = 'Ha sucedido un error';
        }
    }


    return (
        <div style={{margin:'auto', textAlign:'center'}}>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )

}