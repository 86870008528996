import React, { useEffect, useReducer } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import {clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./SignersReportMobile.module.css";
import { usePalette } from "./Customizables";


const SLIDER_STATUS = {
  HIDDEN: "HIDDEN",
  FULL: "FULL"
};

const initialSliderState = {
  sliderStatus: SLIDER_STATUS.HIDDEN,
  rendered: false,
  canceled: false
};

function sliderReducer(state, action) {
  switch (action.type) {
    case "CHANGE_STATUS": {
      const { status, canceled } = action.payload;
      return {
        sliderStatus: status,
        rendered: false,
        canceled
      };
    }
    case "RENDERED": {
      return {
        ...state,
        rendered: true,
        canceled: false
      };
    }
    default: {
      return state;
    }
  }
}

export default function BottomSheet({
  isOpen,
  onClose,
  children,
  cancelable,
  menuAcertia,
  mobile: {height, header},
}) {

  const [{ y }, api] = useSpring(() => ({
    y: height
  }));

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({ y: 0, immediate: false, config: canceled ? config.wobbly : config.stiff })
  }
  const close = (velocity = 0) => {
    api.start({ y: height, immediate: false, config: { ...config.stiff, velocity } })    
  }

  const [{ sliderStatus, rendered, canceled }, sliderDispatch] = useReducer(
    sliderReducer,
    initialSliderState
  );
  const palette = usePalette()

  useEffect(() => {
    // el estado de abierto o cerrado lo controla el padre
    // de este componente
    if (isOpen) {
      if (menuAcertia) {
        sliderDispatch({
          type: "CHANGE_STATUS",
          payload: {
            status: SLIDER_STATUS.HIDDEN
          }
        });
      } else {
        sliderDispatch({
          type: "CHANGE_STATUS",
          payload: {
            status: SLIDER_STATUS.FULL
          }
        });
      }
    } else {
      close()
      sliderDispatch({
        type: "CHANGE_STATUS",
        payload: {
          status: SLIDER_STATUS.HIDDEN
        }
      });
    }
  }, [isOpen]);

  useEffect(() => {
    // si ya se marco como renderizado el estado se ignora
    // este cambio
    // se utiliza esta variable para poder pedir que vuelva a
    // renderizar un estado, por ejemplo:
    // el slider esta en FULL y el usuario lo mueve solo un poco hacia abajo
    // en ese caso se debe volver a llevar al estado FULL
    if (rendered) {
      return;
    }    
    // es un cambio de estado sin renderizar
    switch (sliderStatus) {
      case SLIDER_STATUS.HIDDEN: {
        // se elimina el scroll lock ya que se esconde el slider
        clearAllBodyScrollLocks();
        sliderDispatch({
          type: "RENDERED"
        });
        break;
      }
      case SLIDER_STATUS.FULL: {

        open({});
        sliderDispatch({
          type: "RENDERED"
        });
        break;
      }
    }
  }, [sliderStatus, rendered, height, canceled]);

  const bind = useDrag(
    ({ last, velocity: [, vy], direction: [, _dy], movement: [, my], cancel, canceled }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < -70) cancel()

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        if (my > height * 0.5) 
        {
          close(vy);
          setTimeout(() => {
            onClose();
          }, 700)
        } else {
          open({ canceled })
        }
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else api.start({ y: my, immediate: true })
    },
    { from: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
  )



  function handleClickClose() {
    if(cancelable === false) {
      return;
    }
    close()
    setTimeout(() => {
      onClose();
    }, 700)
  }

  const overlayBackground = y.interpolate(
    [0, height],
    ["#00000060", "#00000000"],
    "clamp"
  );

  const display = y.to((py) => (py < height ? 'block' : 'none'))


  return (
    <React.Fragment>
      <animated.div
        className={styles.overlay}
        style={{
          display,
          background: overlayBackground
        }}
        onClick={handleClickClose}
      />
      <animated.div
        className={ menuAcertia ? styles.sheetMenuAcertia : styles.sheet }
        {...bind()}
        style={{
          display,
          bottom: `calc(-100vh + ${height - 100}px)`,
          y
        }}
      >
        { menuAcertia ? null :
          <div className={styles.handlerContainer} >
            <div className={styles.handler} style={{background: palette.secondaryColor}}>                
            </div>
          </div>
        }
        <div className={styles.header} style={{color: palette.accentText}}>
            {header}
        </div>        
        {children}
      </animated.div>
    </React.Fragment>
  );
}
