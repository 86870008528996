import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export function useUrlParam(name) {

    const { report } = useSelector(state => state.document)

    const [value, setValue] = useState(null);
    
    useEffect(() => {

        if(name && report.ticket) {
            const urlParams = new URLSearchParams(window.location.search);
            const param = urlParams.get(name);
            setValue(param);
        }


    }, [name, report.ticket]);

    function clear() {
        setValue(null);
    }
    
    return [value, clear];

}