import SolidButton from "./SolidButton";
import styles from './PulseButton.module.css';

export default function({...props}) {

    

    return (
        <SolidButton 
            {...props}
            className={styles.pulseButton}
        ></SolidButton>
    )

}