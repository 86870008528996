import React, { useEffect } from 'react'
import HttpRequest from './HttpRequest'
import { setReport } from '../redux/actions';
import i18n from '../i18n';


export default function DocumentSet({match, history}) {

    useEffect(() => {

        async function fetchDocumentSet() {

            const setLink = match.params.setLink;
            const setResponse = await HttpRequest.get(`/set/${setLink}`)
            
            if(!setResponse.data) {
                return;
            }

            const linkData = JSON.parse(atob(setLink.split('.')[1])).sub.split('__');  

            const docSetResponse = await HttpRequest.get(`/set/${setResponse.data.uuid}/${linkData[0]}`)

            const unsigned = docSetResponse.data.find(d => !d.signed);
            const doc = unsigned || docSetResponse.data[0];
            
            if(doc) {
                history.push(`/pdf/${doc.firmamexId}?setLink=${setLink}${setResponse.data.queryParams || ''}`)
                i18n.reloadResources()
                return
            }
            
            if(setResponse.data && setResponse.data.documents) {
                const allowedDoc = setResponse.data.documents.find(d => !!d.firmamexId)
                history.push(`/pdf/${allowedDoc.firmamexId}?setLink=${setLink}${setResponse.data.queryParams || ''}`)   
                i18n.reloadResources()
            }
        }

        if(match.params.setLink) {
            fetchDocumentSet();
        }

    }, [match.params.setLink, history]) 


    return null;

}