

export default function buildStickerTooltipStyles({ background }) {
    return {
        Tooltip: {
            ':before': {
                top: '100%',
                left: '50%',
                border: 'solid transparent',
                content: '" "',
                height: '0',
                width: '0',
                position: 'absolute',
                pointerEvents: 'none'
            },
            ':after': {
                top: '100%',
                left: '50%',
                border: 'solid transparent',
                content: '" "',
                height: '0',
                width: '0',
                position: 'absolute',
                pointerEvents: 'none',
                borderTopColor: background,
                borderWidth: '15px',
                marginLeft: '-15px'
            },
            background,
            // width: '250px',
            // height: '60px',
            minWidth:'200px',
            position: 'relative',
            // top: '-120px',
            color: 'white',
            padding: '10px',
            fontSize: '13px',
            // left: '-30px',
            pointerEvents: 'none',
        },
        TooltipData: {
            // top: '-100px',
            textAlign: 'center',
            fontSize: '12px',                        
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
}