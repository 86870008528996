import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import Main from './Main';
import { Route } from "react-router-dom";
import TopBar from './shared/TopBar'
import LoadingTasks from './shared/LoadingTasks';
import TaskModal from './shared/TaskModal';
import FormRedirect from './pages/FormRedirect';
import FlexRow from './shared/FlexRow';
import DesktopSideBar from './shared/DesktopSideBar';
import { loadClientData, loadSignatureStroke, loadSignatureImage } from './redux/actions'
import { useDispatch, useSelector } from 'react-redux';
import DocumentSet from './shared/DocumentSet';
import ErrorScreen from './ErrorScreen';
import { useTranslation } from 'react-i18next';
import useTracking from './hooks/useTracking';

const Signup = React.lazy(() => import('./Signup'));
const Forms = React.lazy(() => import('./pages/Forms'));


export default function FirmamexApp(props) {

    useTracking('G-B27Y3FT90W')
    const {t} = useTranslation()    
    const globalConfig = useSelector(state => state.config);
    const customStyle = useSelector(state => state.customizables.customStyle)
    const [styleReady, setStyleReady] = useState(window.location.href.includes(process.env.REACT_APP_FRMX));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadClientData())
        dispatch(loadSignatureStroke())
        dispatch(loadSignatureImage())
    }, [dispatch])

    useEffect(() => {
        if(customStyle) {
            setStyleReady(true)
        }
    }, [customStyle])

    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('noOverflow')
        document.getElementsByTagName('body')[0].classList.add('noOverflow')

        return function() {
            document.getElementsByTagName('html')[0].classList.remove('noOverflow')
            document.getElementsByTagName('body')[0].classList.remove('noOverflow')
        }
    }, []) 

    return (
        <React.Fragment>
            {
                globalConfig.loaded ? 
                <React.Fragment>
                    <div className="App" style={{visibility: styleReady ? 'initial' : 'hidden'}}>
                        <FlexRow style={{height:'100%'}} alignItems='stretch' flexWrap='nowrap'>
                            <DesktopSideBar></DesktopSideBar>
                            <div style={{ flexGrow: 1, maxWidth: '100%', display: 'flex', flexDirection:'column' }}>
                                <TopBar></TopBar>
                                <Route exact path="/error" component={ErrorScreen}></Route>
                                <Route path="/pdf/:ticket" component={Main}/>
                            </div>
                        </FlexRow>
                    </div>                    
                    <Suspense fallback={<div>{t('Cargando')}</div>}>
                        <Route path="/pdf/:ticket/signup" component={Signup}></Route>
                    </Suspense>
                    <Route exact path="/set/:setLink" component={DocumentSet}></Route>
                    <Route exact path="/form/:uuid" component={FormRedirect}></Route>
                    <Suspense fallback={<div>{t('Cargando')}</div>}>
                        <Route path="/form/i/:ticket" component={Forms}></Route>
                    </Suspense>
                </React.Fragment> : null
            }            
            <Suspense fallback={<div></div>}>
                <LoadingTasks></LoadingTasks>
            </Suspense>
            <TaskModal></TaskModal>
        </React.Fragment>
    )

}