import React from 'react';
import {IS_MOBILE} from '../Consts'
import BottomSheet from './BottomSheet';
import styles from './SignersReportDesktop.module.css';
import {Modal, ModalBody} from 'reactstrap'

export default function ResponsiveSheetModal(props) {
    return (
        <React.Fragment>
            {
                IS_MOBILE ? 
                <BottomSheet {...props}>
                </BottomSheet>
                : 
                <Modal isOpen={props.isOpen} 
                    toggle={props.onClose} 
                    contentClassName={styles.modalContent}>
                    {props.desktop.header}
                    <ModalBody className={styles.body}>
                        {props.children}
                    </ModalBody>
                </Modal>
            }
        </React.Fragment>        
    )

}