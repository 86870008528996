import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HttpRequest from "../shared/HttpRequest";


export default function useHasAccount() {

    const userSession = useSelector(state => state.session.current)
    const [hasAccount, setHasAccount] = useState(false);

    useEffect(() => {

        async function fetchData() {
            try {
                const response = await HttpRequest.get(`/acc/user/hasAccount`);
                setHasAccount(response.data.hasAccount)
            } catch(e) {
                setHasAccount(false)
            }
        }

        fetchData()

    }, [userSession?.x509])

    return hasAccount

}