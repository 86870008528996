import HttpRequest from '../shared/HttpRequest';
import forge from './forge'
import {b64ToBinary, arrayBufferToB64, blobToArrayBuffer, b64ToBlob, blobToB64, logAndReturn} from '../utils/toolbox'
import FrmxFirebase from '../utils/FrmxFirebase';
import { firstValueFrom, mergeMap } from 'rxjs';
import * as Asn1Parser from './Asn1Parser'


async function sign({report, certData, pkcs8Pem, pass, sticker, pos, original, signatureImg, onProgress, signatureCount, pin, latLng}) {
    
    if(!onProgress) {
        onProgress = function(){};
    }
    onProgress('Iniciando firma')
        
    const pk = forge.pki.decryptRsaPrivateKey(pkcs8Pem, pass)
    const cert = forge.pki.certificateFromPem(certData.pem);
    onProgress('Obteniendo OCSP');

    let ocspResponse, thisUpdate;
    try {
        const ocspRes = await requestOcsp(certData.pem);
        if(ocspRes !== null) {
            ocspResponse = ocspRes.ocspResponse;
            thisUpdate = ocspRes.thisUpdate;
        }
    } catch(e) {        
        return e.message
    }
    
    const signFieldIndex = getSignFieldIndex(sticker);
    const signFieldName = signFieldIndex;
    onProgress('Firmando');

    if(sticker) {
        pos = getPosFromSticker(signatureImg, sticker);
    }    

    if(sticker.authority.verificationType === 'FIREBASE') {
        const addClaimResult = await FrmxFirebase.addClaim(report.ticket)
        if(addClaimResult.error) {
            return 'firebase_session_error'
        }
    }    

    const {b64DummyAddendum, sigDataId, error} = await fetchDummySignature(report, pos, signFieldName, sticker, signatureCount, latLng, pin);    

    if(error) {
        return typeof error === 'string'
            ? error.trim()
            : error;
    }
    
    const falseAddendum = b64ToBinary(b64DummyAddendum);
    const byteRange = getByteRange(falseAddendum);

    let signedStart = [];
    signedStart.push(original);
    let temp = falseAddendum.slice(0, parseInt(byteRange[1]) - original.size);
    signedStart.push(temp);
    signedStart = new Blob(signedStart, {
        type: 'application/pdf'
    });
    let signedEnd = [];
    temp = falseAddendum.slice(parseInt(byteRange[2]) - original.size,
        falseAddendum.byteLength);
    signedEnd.push(temp);
    signedEnd = new Blob(signedEnd, {
        type: 'application/pdf'
    });

    let full = [];
    full.push(signedStart);
    full.push(signedEnd);

    full = new Blob(full, {
        type: 'application/pdf'
    });
    const ab = await blobToArrayBuffer(full);
    let b64 = atob(arrayBufferToB64(ab))

    let p7 = forge.pkcs7.createSignedData();
    p7.content = forge.util.createBuffer(b64, 'binary');
    
    p7 = addChain(certData, p7);
    // asn1.create(asn1.Class.UNIVERSAL, asn1.Type.OCTETSTRING, false, issuerNameHash);
    const authenticatedAttributes = [{
        type: forge.pki.oids.contentType,
        value: forge.pki.oids.data
    }, {
        type: forge.pki.oids.messageDigest
            // value will be auto-populated at signing time
    }, {
        type: forge.pki.oids.signingTime,
        // value can also be auto-populated at signing time
        value: new Date()
    }];

    if(ocspResponse) {
        authenticatedAttributes.push({
            type: '1.3.6.1.5.5.7.48.1.1',
            rawValue: ocspResponse
        });
    }

    const unauthenticatedAttributes = [];
    
    p7.addSigner({
        key: pk,
        certificate: cert,
        digestAlgorithm: forge.pki.oids.sha256,
        authenticatedAttributes: authenticatedAttributes,
        unauthenticatedAttributes: unauthenticatedAttributes
    });    

    await p7.sign({detached: true, timestamp:`https://${process.env.REACT_APP_HOST}/tsp/${report.ticket}`})

    if(p7.signers.length === 0 || p7.signers[0].unauthenticatedAttributes.length === 0) {
        return 'bad signature';
    }

    const timestampAttr = p7.signers[0].unauthenticatedAttributes
        .find(attr => attr.type === Asn1Parser.TIME_STAMP_TOKEN);

    if(!timestampAttr) {
        return 'no timestamp';
    }

    try {
        const timestampDate = await firstValueFrom(
            Asn1Parser.AdobeTimeStampToken(timestampAttr.rawValue)
            .pipe(
                mergeMap(aaTst => Asn1Parser.TimeStampToken(aaTst)),
                mergeMap(tsToken => Asn1Parser.TSTInfo(tsToken)),
                mergeMap(tstInfo => Asn1Parser.GeneralizedTime(tstInfo))
            )
        )
        
        
        const dateDiff = Math.abs(new Date(thisUpdate) - timestampDate);
        const minutesDiff = dateDiff/(1000 * 60)

        if(minutesDiff > 4) {                                                                
            return 'ocsp_timestamp_error'
        }

    } catch(e) {
        return 'invalid timestamp';
    }

    const s = p7.toAsn1();
    let hexed = forge.util.bytesToHex(forge.asn1.toDer(s));
    const signatureSize = parseInt(byteRange[2]) - parseInt(byteRange[1]) - 2;	
    while (hexed.length < signatureSize) {
        hexed += '0';
    }
    hexed = '<' + hexed + '>';	
    const signature = b64ToBlob(btoa(hexed));
    full = [];
    full.push(signedStart);
    full.push(signature);
    full.push(signedEnd);
    full = new Blob(full, {
        type: 'application/pdf'
    });
    let finalAddendum = full.slice(original.size);    
    onProgress('Guardando');
    const b64Final = await blobToB64(finalAddendum);

    try {
        await HttpRequest.post('/sign/local', {
            signerLink: report.signerLink ? report.signerLink.link : null,
            setLink: report.setLink ? report.setLink.link : null,
            addendum: b64Final,
            sigDataId,
            signatureCount,
            pin
        })
    } catch(e) {
        if(e && e.response && e.response.status === 400) {
            if(e.response.data && e.response.data.status) {
                return e.response.data.status
            }
        }
    }
    
    onProgress('Firmado');
}

function addChain(certData, p7) {
    if(certData.chain) {
        for(let i = 0; i < certData.chain.length; i++) {
            const certPem = forge.util.decode64(certData.chain[i]);
            const cert = forge.pki.certificateFromPem(certPem)
            p7.addCertificate(cert);
        }                                                

    } else {
        const cert = forge.pki.certificateFromPem(certData.pem);
        p7.addCertificate(cert);
    }

    return p7;
}

function getByteRange(data) {
    const falseAddendum = new TextDecoder('ascii').decode(new Uint8Array(data));
    let byteRangeIdx = falseAddendum.indexOf('ByteRange');
    byteRangeIdx = falseAddendum.indexOf('[', byteRangeIdx) + 1;
    let byteRangeEndIdx = falseAddendum.indexOf(']', byteRangeIdx);
    let byteRangeData = falseAddendum.slice(byteRangeIdx, byteRangeEndIdx);
    
    const byteRange = byteRangeData.trim().split(' ');
    return byteRange;
}

async function fetchDummySignature(report, pos, signFieldName, sticker, signatureCount, latLng, pin) {

    let token;
    if(sticker.authority.verificationType === 'FIREBASE') {
        const getTokenResult = await FrmxFirebase.getToken(true)
        if(getTokenResult.error) {
            return {
                error: 'firebase_session_error'
            }
        }        
        token = getTokenResult.token;    
    }        

    try {
        const response = await HttpRequest.post('/sign/dummyV2', {
            ticket:report.ticket,
            x1:pos.x1 + 4,
            x2:pos.x2 + 4,
            y1:pos.y1 - 4,
            y2:pos.y2 - 4,
            signerLink: report.signerLink?.link,
            setLink: report.setLink ? report.setLink.link : null,
            ...latLng,
            page: pos.page,
            signFieldName,
            boxId: sticker._id,
            signatureCount: signatureCount,
            languageTag: 'es', //navigator.language,
            viewerRotation: 0,
            simpleSignatureData: null,
            oAuthToken: token,
            pin
        })
        
        if(response.data.status === 'ok') {
            return {
                b64DummyAddendum: response.data.addendum,
                sigDataId: response.data.sigDataId
            }
        }
        
    } catch(e) {
        if(e && e.response && e.response.status === 400) {
            if(e.response.data && e.response.data.status) {
                return {
                    error: e.response.data.status
                }
            }
        }
    }
    return {
        error: 'error'
    };
    
    
}

function getPosFromSticker(signatureImg, sticker) {
    const {width, height, diffY} = adjustToSize(signatureImg, sticker.width, sticker.height)
    const xDiff = (sticker.width - width) / 2;
    const yDiff = sticker.line ? diffY : ((sticker.height - height) / 2);

    let x1 = sticker.lx + xDiff;
    let x2 = x1 + width;
    // corrige la posicion para mandar al servidor
    // en el sistema de coordenadas pdf la y comienza en 0 desde abajo
    // por lo que hay convertir la diferencia html (0 arriba) al sistema de coordenadas pdf (0 abajo)
    // sticker.ly = posicion de la parte inferior del sticker
    // sticker.height = tamano del sticker
    // height = tamano de la imagen
    // yDiff = diferencia en html        
    // y1 = posicion pdf de la imagen de firma
    let y1 = sticker.ly + sticker.height - height - yDiff
    // if(y1 > (sticker.ly + 50)) {
    //     let diff = y1 - sticker.ly + 40;
    //     y1 = y1 - diff
    // }

    let y2 = y1 + height;

    return logAndReturn({
        x1, x2,
        xDiff,
        y1, y2,
        yDiff,
        page: sticker.page + 1,
        width,
        height
    })
}

function adjustToSize(signatureImg, maxInitWidth, maxInitHeight) { 
    
    let {width, height, diffY} = signatureImg;        
    let ratio = width / height;

    while(width > maxInitWidth || height > maxInitHeight) {                                                                    

        if (ratio >= 4) {
            if (width > maxInitWidth) {
                height = (maxInitWidth * height) / width;        
                diffY = diffY * (maxInitWidth / width);
                width = maxInitWidth;
            }
        } 

        if (height > maxInitHeight) {
            const newWidth = (maxInitHeight * width) / height;                                                                                                            
            diffY = diffY * (maxInitHeight / height);
            height = maxInitHeight;                                    
            width = newWidth;
        }
        ratio++;
            
    }  

    if(!diffY) {
        // si no hay diffY significa que la imagen no es de trazo
        // por lo que diffY tendria que ser para alinear la imagen con la 
        // linea inferior del sticker
        diffY = maxInitHeight - height
    }

    return {
        width, height, diffY
    }
        
}

function getSignFieldIndex(sticker) {
    if(!sticker) {
        //deberia ser el numero siguiente de firma?
        return  new Date().getTime()
    } else {
        return  sticker._id;
    }
}

function getSignFieldName(sticker) {
    if(!sticker) {
        //deberia ser el numero siguiente de firma?
        return 'firmamex' + new Date().getTime()
    } else {
        return 'firmamex' + sticker._id;
    }
}

async function requestOcsp(certPem) {
    const blob = new Blob([certPem], {
        type: 'application/x-x509-user-cert'
    })
    const response = await HttpRequest.post('/ocsp', blob, {
        headers: {
            'Content-Type': 'application/x-x509-user-cert'
        }
    })

    const {ocspResponse, status, thisUpdate} = response.data;
    if(status === 'good') {
        //se obtuvo respuesta del ocsp diciendo que el
        //certificado es valido    
        const dec = forge.util.decode64(ocspResponse)
        const s1 = forge.asn1.fromDer(dec)
        return {ocspResponse:s1, thisUpdate};

    } else if(status === 'none') {
        //no se obtuvo respuesta del ocsp
        return null;
    } else if(status === 'error') {
        throw new Error('ocsp_error');
    } else {
        //se obtuvo respuesta del ocsp diciendo que el
        //certificado NO es valido
        throw new Error('invalid_cert_ocsp');
    }  
}

export default {
    sign, getPosFromSticker
}