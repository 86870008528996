import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ResponsiveModal from './ResponsiveModal';
import {IS_MOBILE} from '../Consts';
import {isEven} from '../utils/toolbox';
import HttpRequest from './HttpRequest';
import styles from './Expediente.module.css';

import { useTranslation } from 'react-i18next';

function Document({left, id, color = "#B70577", onClick, title, clickable, toggle, firmado, lastSignatureDate}) {

    const {t} = useTranslation()    
    const infoDiv = IS_MOBILE ? styles.infoDivMobile : styles.infoDiv;
    const documentDiv = styles.documentDiv + ' ' + (IS_MOBILE ? styles.documentDivMobile : null);
    const documentCase = styles.documentCase + ' ' + (IS_MOBILE ? styles.documentCaseMobile : null);

    let documentCaseStyle = (clickable) ? {cursor: 'pointer', color: `?${firmado ? null : "color: '#898885'"}`} : (!firmado ? {color: '#898885'} : null);
    let circle = styles['circle' + (left ? 'Left' : 'Right')] + ' ' + (IS_MOBILE ? left ? null : styles.circleRightMobile : null);
    let position = IS_MOBILE ? styles['containerDocMobile' + (left ? 'Left' : 'Right')] : styles['containerDoc' + (left ? 'Left' : 'Right')];
    let imgDiv = styles['imgDiv' + (left ? 'Left' : 'Right')];
    let border = left ? {borderLeft: `2px solid ${color}`} : {borderRight: `2px solid ${color}`};

    function click() {
        if (clickable && onClick) onClick();
    }

    function DocIcon({color}) {
        const size = IS_MOBILE ? 16 : 40;
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width={`${size}px`} height={`${size}px`}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/></svg>
    }

    return(
        <div className={position} onClick={click} style={documentCaseStyle}>
            <div className={circle} style={{backgroundColor: `${color}`}}></div>
            <div className={styles.topBorder} style={border}></div>
            <div className={documentCase} style={border}>
                <div className={imgDiv}>
                    {
                        (firmado) ? <DocIcon color={"#AA1F72"} /> : <DocIcon color={"#898885"} />
                    }
                </div>
                <div className={infoDiv}>
                    <div className={styles.documentDiv}>
                        {
                            IS_MOBILE ? null : <p><strong>{t('title')}:</strong></p>

                        }
                        <p className={styles.titlePDF}>{title}.pdf</p>
                    </div>
                    <div className={documentDiv}>
                        <p className={IS_MOBILE ? styles.fechaTextMobile : styles.fechaText}><strong>{t('signatureDate')}:</strong></p>
                        <p className={styles.space}>
                            {   
                                lastSignatureDate ? new Date(1593747939000).toLocaleString() : <span>{t('pending')}</span>
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function Expediente({isOpen, toggle, onDocumentClick}) {

    const {t} = useTranslation()
    const report = useSelector(state => state.document.report);
    const [documentSet, setDocumentSet] = useState({documents:[]});

    const maxWidth = IS_MOBILE ? null : 930;
    const containerDiv = styles.container + ' ' + (IS_MOBILE ? styles.containerMobile : null);
    const expedientesDiv = styles.expedientesDiv + ' ' + (IS_MOBILE ? styles.expedientesDivMobile : null);
    const centerMobile = IS_MOBILE ? styles.centerMobile : null

    useEffect(() => {

        async function fetchDocumentSet() {            
            const response = await HttpRequest.get(`/set/documents/${report.documentSet}${report.setLink ? `?setLink=${report.setLink.link}` : ''}`);
            setDocumentSet(response.data)

        }

        if(isOpen && report.documentSet) {
            fetchDocumentSet();
        }

    }, [isOpen, report.documentSet, report.setLink])

    function handleDocumentClick(firmamexId) {
        return function(e) {
            onDocumentClick(firmamexId);
            toggle();
        }
    }

    return(
        <ResponsiveModal 
            isOpen={isOpen} 
            toggle={toggle} 
            title='fileCaps'
            onCancel={toggle} 
            desktopTitle='fileCaps'
            maxWidth={maxWidth}
        >
            <div className={centerMobile}>
                <div className={containerDiv}>
                    <div>
                        <p className={styles.expediente}>{report.documentSetName}</p>
                    </div>
                    <div className={expedientesDiv}>
                        {
                            documentSet.documents.map((d, i) => (
                                <Document key={i}
                                    left={!isEven(i)}
                                    clickable={!!d.firmamexId}
                                    onClick={handleDocumentClick(d.firmamexId)}
                                    title={d.originalName}
                                    firmado={d.completed}
                                    lastSignatureDate={d.lastSignatureDate}/>
                            ))
                        }
                    </div>
                    {/* <div className={expedientesDiv}>
                        {
                            documentSet.documents.map((d, i) => 
                                d.firmamexId ? 
                                    isEven(i) ? <Document 
                                        id={i} 
                                        clickable={true} 
                                        onClick={handleDocumentClick(d.firmamexId)}
                                        title={d.originalName}
                                        toggle={toggle}
                                        firmado={d.completed}
                                        lastSignatureDate={d.lastSignatureDate} 
                                    /> : <Document 
                                        left={true} 
                                        id={i} 
                                        clickable={true} 
                                        onClick={handleDocumentClick(d.firmamexId)}
                                        title={d.originalName}
                                        toggle={toggle}
                                        firmado={d.completed}
                                        lastSignatureDate={d.lastSignatureDate}   
                                    /> :
                                    isEven(i) ? <Document 
                                        id={i} 
                                        title={d.originalName}
                                        firmado={d.completed}
                                        lastSignatureDate={d.lastSignatureDate}  
                                    /> : <Document 
                                        left={true} 
                                        id={i} 
                                        title={d.originalName}
                                        firmado={d.completed}
                                        lastSignatureDate={d.lastSignatureDate}  
                                    />
                            )
                        }
                    </div> */}
                </div>
            </div>
        </ResponsiveModal>

    )
}