import React from 'react';

export default function FlexColumn({children, style, justifyContent, alignItems, flexGrow, ...rest}) {
    return (
        <div style={{display:'flex', 
            flexDirection:'column', 
            alignItems: alignItems || 'left', 
            justifyContent:justifyContent || 'start',
            flexGrow: flexGrow || 1,
             ...style}}
            {...rest}>
            {children}
        </div>
    )
}
