import React, {useState, useEffect, useReducer, useCallback} from 'react';
import WithSingleUseCertificate from './WithSingleUseCertificate';
import useToggle from '../hooks/useToggle';
import {withRouter} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { setActiveSticker, clearActiveSticker, setSigning, showAlert, addTask, removeTask, setPin } from '../redux/actions';
import ConfirmCancel from './ConfirmCancel';
import {waitForElem, checkStickerOrder, buildQueryParams} from '../utils/toolbox'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SolidButton from './SolidButton';
import CustomModalHeader from './CustomModalHeader';
import {IS_MOBILE} from '../Consts';
import useNextSigningSticker from '../hooks/useNextSigningSticker';
import STATUS from './STATUS';
import { useTranslation } from 'react-i18next';

const SignButtonFirmamex = React.lazy(() => import('./SignButtonFirmamex'));
const SignButtonAcertia = React.lazy(() => import('./acertia/SignButtonAcertia'));
const ChangeAccountModal = React.lazy(() => import('./ChangeAccountModal.js'));
const SelectStickerModal = React.lazy(() => import('./SelectStickerModal'))
const RejectStickerModal = React.lazy(() => import('./RejectStickerModal'));

function SignButton({hasDocument, signableStickers, currentPage, rendererSize, 
history, match, location, acertiaSideBar, activeSigner, activeSignerFound }) {

    const {t} = useTranslation();
    const {companyComponents} = useSelector(state => state.customizables)
    const [status, setStatus] = useState(STATUS.DEFAULT)
    const [showSelectSigner, toggleSelectSignerOpen] = useToggle(false)    
    // const [nextSigningSticker, setNextSigningSticker] = useState(null)
    const [nextSigningSticker, cancelNextSigningSticker] = useNextSigningSticker(match.params.ticket);
    const [showRejectStickerModal, toggleRejectStickerModal] = useToggle(false);


    const signing = useSelector(state => state.status.signing)
    const allUnsigned = useSelector(state => state.document.report.boxes.filter(b => !b.signed));
    const {ordered, nextSigner, nextSignerSticker, signerLink} = useSelector(state => state.document.report);
    const session = useSelector(state => state.session)
    const activeSticker = useSelector(state => state.signableStickers.active);
    
    const [changeAccountModal, setChangeAccountModal] = useState({
        stickerData: null,
        accountId: null
    })
    const dispatch = useDispatch();

    let unsignedStickers = allUnsigned.filter(b => !b.authority.singleUse)
    /*if(activeSigner) {
        unsignedStickers = unsignedStickers.filter(b => b.data.toLowerCase() === activeSigner)
    }*/
    // const unsignedStickers = useSelector(state => state.document.report.boxes.filter(b => !b.signed && !b.authority.singleUse));
    const userSession = session.current;

    useEffect(() => {

        if(!activeSticker) {
            // no hay ningun sticker seleccionado
            // nos devolvemos al status default
            setStatus(STATUS.DEFAULT);
            return;
        }

        cancelNextSigningSticker();

        if(activeSticker && activeSticker.status === 'click') {
            // se selecciono un sticker ya sea dando click en el
            // boton de firmar (selecciona el primero disponible) 
            // o dando click al sticker directamente
            // 
            // lo primero que tenemos que hacer es verificar
            // si el usuario puede firmar el sticker seleccionado
            const signableSticker = signableStickers.find(s => s.id === activeSticker.id);
            if(!signableSticker) {
                // el sticker seleccionado NO se puede firmar con los datos
                // del usuario actual
                if(activeSticker.singleUse) {
                    // se dio click a un sticker de un solo uso pero sin estar
                    // en la liga correcta, por el momento no hacemos nada
                    dispatch(clearActiveSticker())
                } else {
                    // el sticker no es de un solo uso pero el usuario no esta registado con los
                    // datos necesarios, lo pasamos a la pantalla de registro
                    setStatus(STATUS.DEFAULT)   
                    history.push(`/pdf/${match.params.ticket}/signup${buildQueryParams({
                        stickerId: activeSticker.id
                    })}`)    
                }                
                return
            }

            const sticker = signableSticker.sticker;
            if(sticker.authority.singleUse) {
                // el sticker que se quiere firmar es de un solo uso
                // en ese caso mostramos directamente el popup de confirmacion
                // ya que no podemos "activar" al sticker para mostrar la imagen
                // de firma ya que se creara la sesion al momento de firmar
                // el sticker
                setStatus(STATUS.CONFIRM_SIGNATURE)
            } else if(userSession.jwt) {
                // activamos el sticker y mostramos su imagen de firma
                dispatch(setActiveSticker(sticker._id, 'signing', false))
            }
        }

        if(activeSticker && activeSticker.status === 'signing') {
            // ya se confirmo que el sticker se PUEDE firmar, pasamos
            // a pedirle al usuario que confirme que lo QUIERE firmar
            setStatus(status => {
                if(status === STATUS.DEFAULT) {
                    return STATUS.CONFIRM_SIGNATURE;
                }
                return status
            })
        }

    }, [activeSticker, userSession.jwt, signableStickers, match.params.ticket, history, dispatch, cancelNextSigningSticker])


    // useEffect(() => {

    //     if(!showSingleUseSignup && !signing.working && signing.data.justSigned && signableStickers.length > 0) {
    //         const signable = signableStickers[0];
    //         if(!signable.sticker.authority.singleUse) {
    //             // setNextSigningSticker(signable)
    //         }
    //     }

    // }, [signableStickers, signing, showSingleUseSignup])
    function scrollTo({id, top, behavior}) {
        const elem = document.getElementById(id);
        if(elem.scrollTo) {
            elem.scrollTo({top, behavior})
        } else {
            elem.scrollTop = top
        }
    }


    /**
     * se dio click en FIRMAR cuando hay un sticker
     * autorizado de un solo uso
     */
    async function handleClickSingleUseSticker() {
        const sticker = signableStickers[0].sticker;
        if(currentPage !== sticker.page + 1) {       
            dispatch(addTask('loading-page', 'Cargando pagina de firma'))
            const elem = await waitForElem(() => {
                return document.getElementById(`page.${sticker.page}`)
            })
            dispatch(removeTask('loading-page'))
            scrollTo({id:'pdf-renderer-container', top:elem.offsetTop + 100, behavior:'smooth'})
        }
        dispatch(setActiveSticker(sticker._id, 'click', true))
    }

    /**
     * se dio click en FIRMAR cuando hay un sticker que el 
     * usuario puede firmar con su sesion actual
     * @param {*} event 
     */
    async function handleClickSignSticker(event) {
        const sticker = signableStickers[0].sticker;
        if(currentPage !== sticker.page + 1) {       
            dispatch(addTask('loading-page', 'Cargando pagina de firma'))
            const elem = await waitForElem(() => {
                return document.getElementById(`page.${sticker.page}`)
            })
            dispatch(removeTask('loading-page'))
            scrollTo({id:'pdf-renderer-container', top:elem.offsetTop + 100, behavior:'smooth'})
        }
        dispatch(setActiveSticker(sticker._id, 'click', false))
    }        

    /**
     * Se dio en click en Confirmar en el popup de confirmacion
     * de firma de un solo uso
     */
    function handleClickConfirmSingleUseSignature() {
        // setShowSingleUseSignup(true);
        dispatch(setSigning({
            signing: {
                working: true,
                data: {
                    type: 'singleUse'
                }
            }
        }))
    }

    /**
     * Se dio click en FIRMAR pero el usuario no tiene sesion iniciada
     */
    function handleClickSignNoStickerNoUser() {
        
        cancelNextSigningSticker();

        if(activeSigner) {
            for(let sticker of unsignedStickers) {
                if(sticker.data.toLowerCase() === activeSigner) {
                    history.push(`/pdf/${match.params.ticket}/signup${buildQueryParams({
                        stickerId: sticker._id
                    })}`)
                    return
                }
            }
        }

        if(unsignedStickers.length === 1) {
            // si hay un solo sticker sin firmar en el documento asumimos
            // que ese es el sticker que quiere firmar el usuario
            dispatch(setActiveSticker(unsignedStickers[0]._id, 'click', false))
            return;
        }

        if(ordered) {
            // si hay mas de un sticker y es un flujo ordenado
            // seleccionamos al siguiente sticker en el flujo
            const nextSticker = unsignedStickers.find(s => s.order === nextSigner);
            if(nextSticker) {
                dispatch(setActiveSticker(nextSticker._id, 'click', false))
            } else {
                // si no encontramos al siguiente entre los stickers sin firmar
                // significa que el siguiente sticker es un sticker por liga
                // y el usuario entro sin el token
                dispatch(showAlert({
                    children: (
                        <div>
                            <p>
                                {t('flowNotice1')} {nextSignerSticker.data}. {t('flowNotice2')} 
                            </p>
                        </div>
                    )
                }))
            }
        } else {
            // si hay mas de un sticker y no es un flujo ordenado, 
            // mostramos el popup de seleccion de sticker
            toggleSelectSignerOpen()
        }
    }


    /**
     * Se dio click en FIRMAR pero no hay ningun sticker que el usuario
     * actual pueda firmar
     */
    function handleClickSignNoStickerForUser() {
        cancelNextSigningSticker();
        // buscamos si hay un sticker que pueda firmar el usuario si se cambia de cuenta
        for(let u of session.directory) {
            const [data, , signupAuthority] = u.id.split('-');
            for(let s of unsignedStickers) {
                if(s.authority.uiName === signupAuthority && s.data.toLowerCase() === data.toLowerCase()) {
                    // se encontro un sticker que se puede firmar en otra cuenta 
                    // if(checkStickerOrder({ordered, nextSigner}, s)) {
                        setChangeAccountModal({
                            stickerData: s.data,
                            accountId: u.id
                        })
                        return;
                    // }
                }
            }       
        }
        // si no encontramos una cuenta valida para ninguno de los stickers, 
        // nos comportamos como si el usuario no tuviera sesion iniciada
        // y le pedimos que se registre
        handleClickSignNoStickerNoUser();
    }

    /**
     * Se selecciono un sticker en el popup de seleccion de sticker
     * @param {*} stickerId 
     */
    function handleSelectStickerModal(stickerId) {
        // escondemos el modal
        toggleSelectSignerOpen();
        // pasamos al registro para ese sticker
        history.push(`/pdf/${match.params.ticket}/signup${buildQueryParams({
            stickerId: stickerId
        })}`)
    }

    // se cancelo la firma, desactivamos el sticker y volvemos al status DEFAULT
    function handleClickCancelConfirmSignature() {
        setStatus(STATUS.DEFAULT);
        dispatch(clearActiveSticker())
    }

    /**
     * Se confirmo la firma, iniciamos el proceso de firma
     */
    function handleClickConfirmConfirmSignature() {    
        const sticker = signableStickers.find(signable => signable.id === activeSticker.id).sticker;
        dispatch(setSigning({
            signing: {
                working: true,
                data: {
                    type: 'certified',
                    stickerId: sticker._id,
                    password: signing && signing.data && signing.data.password
                }
            }
        }))
        setStatus(STATUS.DEFAULT)        
    }

    
    function handleCloseChangeAccountModal() {
        setChangeAccountModal({
            accountId: null,
            stickerData: null
        })
    }    

    function completeSingleUseModal(){}

    // const completeSingleUseModal = useCallback(function() {
    //     setShowSingleUseSignup(false);
    //     if(signableStickers.length > 0) {
    //         const signable = signableStickers[0].sticker;
    //         if(signable.authority.singleUse) {
    //             // setNextSigningSticker(signableStickers[0])
    //         }
    //     }
    // }, [signableStickers])

    const cancelSingleUseModal = useCallback(function() {        
        setStatus(STATUS.DEFAULT);
        dispatch(clearActiveSticker())
    }, [dispatch])

    function handleClickOkContinueSigning() {
        cancelNextSigningSticker();
        if(nextSigningSticker.sticker.authority.singleUse) {
            // setShowSingleUseSignup(true);
            handleClickSingleUseSticker()
        } else {
            handleClickSignSticker();
        }
        // setNextSigningSticker(null);
    }

    function handleClickCancelContinueSigning() {
        // setNextSigningSticker(null)
        dispatch(setPin({pin:null}))
        cancelNextSigningSticker();
    }

    function handleClickRejectSticker() {
        toggleRejectStickerModal();
    }

    function ButtonComponent(props) {
        return companyComponents === 'Acertia' ?
            <React.Suspense fallback={<div></div>}>
                <SignButtonAcertia {...props}></SignButtonAcertia>
            </React.Suspense> :
            <React.Suspense fallback={<div></div>}>
                <SignButtonFirmamex {...props}></SignButtonFirmamex>
            </React.Suspense>
    }

    return (
        <React.Fragment>

            {
                hasDocument ? 
                    <ButtonComponent 
                    handleClickSingleUseSticker={handleClickSingleUseSticker}
                    handleClickRejectSticker={handleClickRejectSticker}
                    status={status}
                    unsignedStickers={unsignedStickers}
                    handleClickSignNoStickerNoUser={handleClickSignNoStickerNoUser}
                    handleClickSignSticker={handleClickSignSticker}
                    handleClickSignNoStickerForUser={handleClickSignNoStickerForUser}
                    handleClickConfirmConfirmSignature={handleClickConfirmConfirmSignature}
                    handleClickCancelConfirmSignature={handleClickCancelConfirmSignature}
                    acertiaSideBar={acertiaSideBar}
                    activeSigner={activeSigner}
                    activeSignerFound={activeSignerFound}
                /> : null
            }

            
            {
                 activeSticker && status === STATUS.CONFIRM_SIGNATURE ?
                    !activeSticker.singleUse ?
                    // !signableStickers[0].sticker.authority.singleUse ?
                    <ConfirmCancel confirmLabel={t('confirm')}
                        onConfirm={handleClickConfirmConfirmSignature}
                        onCancel={handleClickCancelConfirmSignature}></ConfirmCancel> :
                    <Modal isOpen={!signing.working}>
                        <CustomModalHeader>{t('signDocument')}</CustomModalHeader>
                        <ModalBody>                            
                          {t('noticeCustom1', {stickerData: signableStickers[0].sticker.data})}
                        </ModalBody>
                        <ModalFooter>
                          <SolidButton noMargin onClick={handleClickConfirmSingleUseSignature}>{t('accept')}</SolidButton>{' '}
                          <SolidButton noMargin color="secondary" onClick={handleClickCancelConfirmSignature}>{t('cancel')}</SolidButton>
                        </ModalFooter>
                    </Modal> : null
            }
            {
                acertiaSideBar ? null :
                <React.Fragment>
                    <Modal isOpen={!!nextSigningSticker}>
                        <CustomModalHeader>{t('signDocument')}</CustomModalHeader>
                        <ModalBody>
                            {t('noticeCustom3', {stickerData: nextSigningSticker ? nextSigningSticker.sticker.data : ''})}
                        </ModalBody>
                        <ModalFooter>
                            <SolidButton color="primary" noMargin onClick={handleClickOkContinueSigning}>{t('accept')}</SolidButton>{' '}
                            <SolidButton color="secondary" noMargin onClick={handleClickCancelContinueSigning}>{t('cancel')}</SolidButton>
                        </ModalFooter>
                    </Modal>
                    <WithSingleUseCertificate 
                        isOpen={signing.working && signing.data.type === 'singleUse'} 
                        onComplete={completeSingleUseModal}
                        onCancel={cancelSingleUseModal}></WithSingleUseCertificate>       
                </React.Fragment>
                
            }
            <React.Suspense fallback={<div></div>}>
                <SelectStickerModal isOpen={showSelectSigner} 
                    onComplete={handleSelectStickerModal}
                    onCancel={toggleSelectSignerOpen}
                    activeSigner={activeSigner}></SelectStickerModal>
            </React.Suspense>
            <React.Suspense fallback={<div></div>}>
                <ChangeAccountModal {...changeAccountModal}
                    onComplete={handleCloseChangeAccountModal}
                    onCancel={handleCloseChangeAccountModal}></ChangeAccountModal>
            </React.Suspense>
            <React.Suspense fallback={<div></div>}>
                <RejectStickerModal
                    ticket={match.params.ticket}
                    signerLink={signerLink}
                    isOpen={showRejectStickerModal}
                    onComplete={toggleRejectStickerModal}
                    onCancel={toggleRejectStickerModal}
                    sticker={signableStickers.length > 0 ? signableStickers[0].sticker : null}
                ></RejectStickerModal>
            </React.Suspense>
        </React.Fragment>
    )
}

export default withRouter(SignButton)