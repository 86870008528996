import React from 'react';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import {IS_MOBILE} from '../Consts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './ResponsiveModal.module.css';
import CustomModalHeader from './CustomModalHeader';
import { useTranslation } from 'react-i18next';

export default function ResponsiveModal({isOpen, children, onCancel, title, desktopTitle, maxWidth}) {

    const {t} = useTranslation()
    const modalWidth = maxWidth ? `${maxWidth}px` : null; 

    return (
        IS_MOBILE ? 
        <Modal isOpen={isOpen}
            className={styles.modal} 
            contentClassName={styles.modalContent}
            backdrop={false}
            zIndex={10050}
            style={{maxWidth: "100%"}}
            >                
            <CustomModalHeader className={styles.modalHeader}>
                {
                    onCancel ?
                    <div className={styles.backIcon} onClick={onCancel}>
                        <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                    </div> : null
                }                
                <div>{t(title)}</div>
            </CustomModalHeader>
            <ModalBody>
                {children}           
            </ModalBody>
        </Modal> :
        <Modal isOpen={isOpen} style={{maxWidth: modalWidth}}>
            {
                desktopTitle ? <CustomModalHeader toggle={onCancel}>{t(desktopTitle)}</CustomModalHeader> : null
            }
            <ModalBody>
                {children}
            </ModalBody>  
        </Modal>
    )
}