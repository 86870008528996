import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Input } from 'reactstrap';


const errorStyle = { border: '1px solid red' };

const initialState = {
    currentLabel: '',
    inputValue: ''
}

function reducer(state, action) {
    switch(action.type) {
        case 'SET_INPUT_VALUE': {
            return {...state, inputValue: action.payload}
        }
        case 'SET_LABEL': {
            return {
                currentLabel: action.payload.label,
                inputValue: action.payload.value
            }
        }
        default: {
            return state;
        }
    }
}

export default function RegexInput({label, regex, onChange, required, transform, value}) {

    const {t} = useTranslation()
    const [{inputValue, currentLabel}, dispatch] = useReducer(reducer, initialState)
    const [style, setStyle] = useState(required ? errorStyle : {})

    useEffect(() => {
        if(label !== currentLabel) {
            dispatch({
                type: 'SET_LABEL',
                payload: {label, value}
            })
        }
    }, [label, value, currentLabel])

    function setInputValue(val) {
        dispatch({
            type: 'SET_INPUT_VALUE',
            payload: val
        })
    }
    
    useEffect(() => {
        
        if(!currentLabel) {
            return;
        }

        if(!regex) {
            setStyle({})
            onChange(inputValue)
            return;
        }

        if(inputValue && inputValue.match(regex)) {
            setStyle({})
            onChange(inputValue)
        } else {
            if(inputValue !== '' || required) {
                setStyle(errorStyle)
            }            
            onChange('')
        }
    }, [regex, onChange, inputValue, required, currentLabel])

    function handleChange(e) {
        if(transform) {
            setInputValue(transform(e.target.value))
        } else {
            setInputValue(e.target.value)
        }        
    }

    return (
        <FormGroup>
            <Label>{`${t(label)} ${required ? '*' : ''}`}</Label>
            <Input type="text"
                style={style}
                value={inputValue}
                onChange={handleChange}></Input>
        </FormGroup>
    )

}