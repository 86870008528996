
//  const self = this;

const workerCode = () => {
    /* eslint-disable-next-line no-restricted-globals */    
    self.addEventListener('message', function(e) {

        var data = JSON.parse(e.data);

        switch(data.action) {
            case 'ask': {
                keepAsking = true;
                ask(data.verificationId, data.keyTest, function(response) {
                    /* eslint-disable-next-line no-restricted-globals */
                    self.postMessage(JSON.stringify(response));
                });
                break;
            }
            case 'cancel': {
                keepAsking = false;
                break;
            }
            default: {}
        }        

    });

    var keepAsking = true;


    var ask = function(verificationId, keyTest, callback) {

        var xhr = new XMLHttpRequest();
        xhr.open('POST', `https://${process.env.REACT_APP_HOST}/signup/verification_status/${verificationId}`, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = 'json';

        xhr.onload = function(e) {
            if(xhr.status === 200 && xhr.response.data.verified) {
                return callback(xhr.response.data)
            } else {
                if(keepAsking) {
                    setTimeout(function() {
                        ask(verificationId, keyTest, callback);
                    }, 2000);
                } else {
                    callback({error:'stopped'});
                }               
            }
        }

        xhr.onerror = function(e) {
            if(keepAsking) {
                setTimeout(function() {
                    ask(verificationId, keyTest, callback);
                }, 2000);
            }  else {
                callback({error:'stopped'});
            }       
        }

        xhr.send(JSON.stringify({
            keyTestString: keyTest
        }))
    }
}

let code = workerCode.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;