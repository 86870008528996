
export const ADD_TASK = "ADD_TASK";
export const REMOVE_TASK = "REMOVE_TASK";
export const UPDATE_TASK = 'UPDATE_TASK';

export const ADD_SIGNABLE_STICKER = 'ADD_SIGNABLE_STICKER';
export const SET_SIGNABLE_STICKERS = 'SET_SIGNABLE_STICKERS';
export const SET_ACTIVE_STICKER = 'SET_ACTIVE_STICKER';
export const SET_ACTIVE_STICKER_IMAGE = 'SET_ACTIVE_STICKER_IMAGE';
export const CLEAR_ACTIVE_STICKER = 'CLEAR_ACTIVE_STICKER';
export const SET_NEXT_SET_DOCUMENT = 'SET_NEXT_SET_DOCUMENT';
export const STICKERS_LOADED = 'STICKERS_LOADED';

export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_REPORT = 'SET_REPORT';
export const LOAD_REPORT = 'LOAD_REPORT';
export const ADD_HTML_SIGNATURE_IMG = 'ADD_HTML_SIGNATURE_IMG'
export const SET_LOCAL_SIGNATURES = 'SET_LOCAL_SIGNATURES';
export const REMOVE_HTML_SIGNATURE_PAGE = 'REMOVE_HTML_SIGNATURE_PAGE';
export const ADD_HTML_UPDATE_PAGE_QUEUE = 'ADD_HTML_UPDATE_PAGE_QUEUE';
export const REMOVE_HTML_UPDATE_PAGE_QUEUE = 'REMOVE_HTML_UPDATE_PAGE_QUEUE';

export const SET_CURRENT_IMAGE = 'SET_CURRENT_IMAGE';

export const RESET_STATE = 'RESET_STATE'
export const CHANGE_DOCUMENT = 'CHANGE_DOCUMENT'

export const SET_SIGNING = 'SET_SIGNING';
export const SET_DRAWING_SIGNATURE = 'SET_DRAWING_SIGNATURE';
export const SET_VERIFYING_USER = 'SET_VERIFYING_USER';
export const SET_FUTURE_VERIFYING_USER = 'SET_FUTURE_VERIFYING_USER';
export const SET_PICKING_SIGNATURE_STYLE = 'SET_PICKING_SIGNATURE_STYLE';
export const SET_WAITING_NOM151_TIMESTAMP = 'SET_WAITING_NOM151_TIMESTAMP';
export const SET_SHOWING_ALERT = 'SET_SHOWING_ALERT';

export const SET_UPLOADING_DOCUMENT = 'SET_UPLOADING_DOCUMENT';
export const SET_ADDING_DRAGGABLE = 'SET_ADDING_DRAGGABLE';

// session
export const LOGIN = 'LOGIN';
export const OAUTH_RELOGIN = 'OAUTH_RELOGIN';
export const LOGOUT = 'LOGOUT';
export const GO_TO_APP1 = 'GO_TO_APP1';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const LOAD_USER = 'LOAD_USER';
export const SET_SIGNATURE_STROKE = 'SET_SIGNATURE_STROKE'
export const SET_SIGNATURE_STROKE_IMAGE = 'SET_SIGNATURE_STROKE_IMAGE';
export const SET_SIGNATURE_IMAGE = 'SET_SIGNATURE_IMAGE';
export const SAVE_PASSPHRASE = 'SAVE_PASSPHRASE'

export const SET_CUSTOMIZABLES = 'SET_CUSTOMIZABLES';
export const SET_CUSTOMIZABLES_DEFAULT = 'SET_CUSTOMIZABLES_DEFAULT';

export const CHANGE_ORG = 'CHANGE_ORG';


// config
export const SET_TRANSLATE = 'SET_TRANSLATE';
export const ADD_TRANSLATE = 'ADD_TRANSLATE';

// pin
export const SET_PIN = 'SET_PIN'
