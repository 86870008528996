import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite';
import { usePalette } from './Customizables';
import { FormGroup, Label, Input } from 'reactstrap';

export default function RadioButton({label, checked, onChange, name, value, style}) {

    const palette = usePalette();

    return (
        <FormGroup check style={style}>
            <Label check className={css(palette.styles.RadioContainer)}>
                <Input type="radio" className={css(palette.styles.RadioButton)} 
                    checked={checked}
                    name={name}
                    value={value}
                    onChange={onChange}></Input>{' '}
                {label}
                <span className={css(palette.styles.StyledRadioButton, checked && palette.styles.CheckedRadioButton)}></span>
            </Label>
        </FormGroup>
    )

}