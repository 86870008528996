import forge from './forge'
import HttpRequest from '../shared/HttpRequest';
import VerificationPoller from '../verificationPoller.worker.js'
import PrimeWorker from '../forge.prime.worker.js';

const verificationPoller = new Worker(VerificationPoller);
const primeWorker = PrimeWorker;

function getKeyFormat(keyData) {
	var epkIndex = keyData.indexOf('BEGIN RSA PRIVATE KEY');
	var bepkIndex = keyData.indexOf('-----BEGIN ENCRYPTED PRIVATE KEY-----');
    var pkIndex = keyData.indexOf('PRIVATE KEY');
    var format = null;

    if(epkIndex != -1 || bepkIndex != -1) {
        format = 'PEM';
	} else if(pkIndex === -1) {
        format = 'DER';            
    } 
    //si es private key sin encriptar regresa null como formato
    return format;
}

function privateKeyFromB64(b64, pass) {

    const keyb64 = b64.split('base64,')[1];
    let key = null; 
    let keyData = forge.util.decode64(keyb64);
    const keyFormat = getKeyFormat(keyData);
    
    if(keyFormat === null) {
        return null;
    }

    if(keyFormat === 'DER') {
        keyData = forge.pem.encode({type:'ENCRYPTED PRIVATE KEY',body:keyData});                
    }

    try {
        key = forge.pki.decryptRsaPrivateKey(keyData, pass);   			
        if(key) {

            return {
                decryptedKey:key,
                pem:keyData
            }
        } else {
            return {
                error: 'could not open private key'
            };
        }   
    } catch(e) {
        return {
            error: 'could not open private key'
        }
    }   	
}

function changePassphrase(pkcs8, currentPass, newPass) {

    const key = forge.pki.decryptRsaPrivateKey(pkcs8, currentPass);

    if(!key) {
        return;
    }

    // convert a Forge private key to an ASN.1 RSAPrivateKey
    var rsaPrivateKey = forge.pki.privateKeyToAsn1(key);
    // wrap an RSAPrivateKey ASN.1 object in a PKCS#8 ASN.1 PrivateKeyInfo
    var privateKeyInfo = forge.pki.wrapRsaPrivateKey(rsaPrivateKey);
    var encryptedPrivateKeyInfo = forge.pki.encryptPrivateKeyInfo(
        privateKeyInfo, newPass, {
            algorithm: '3des', // 'aes128', 'aes192', 'aes256', '3des'
        });

    return forge.pki.encryptedPrivateKeyToPem(encryptedPrivateKeyInfo);    
}

function openKeystoreWithPassphrase(b64Keystore, pass){
    const certs = [];
    let bags;
    let key;
    
    const p12Der = forge.util.decode64(b64Keystore.split('base64,')[1]);
    const p12Asn1 = forge.asn1.fromDer(p12Der);		
    let p12;
    
    try {
        p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, false, pass);
    } catch(e) {
        //contraseña incorrecta
        return null;
    }			
    
    let certBags = p12.getBags({bagType: forge.pki.oids.certBag});
    
    let i = 0;
    certBags[forge.pki.oids.certBag].forEach(function(entry){
        certs[i++] = entry.cert;
    });
    
    bags = p12.getBags({bagType: forge.pki.oids.pkcs8ShroudedKeyBag});
    if(bags){
        bags[forge.pki.oids.pkcs8ShroudedKeyBag].forEach(function(element){
            key = element.key;
        });
        const pem = forge.pki.privateKeyToPem(key);
        const lsKey = forge.pki.decryptRsaPrivateKey(pem, pass);
        if(lsKey) {
            localStorage.setItem('pkcs8', forge.pki.encryptRsaPrivateKey(lsKey, pass));
        }
        
        return {
            certs, key, pem
        }
    }else{
        bags = p12.getBags({bagType: forge.pki.oids.keyBag});
        if(bags[forge.pki.oids.keyBag]){
            bags[forge.pki.oids.keyBag].forEach(function(element){						
                key = element.key;
            });
            const pem = forge.pki.privateKeyToPem(key);
            const lsKey = forge.pki.decryptRsaPrivateKey(pem, pass);
            if(lsKey) {
                localStorage.setItem('pkcs8', forge.pki.encryptRsaPrivateKey(lsKey, pass));
            }

            return {
                certs, key, pem
            }
        }	
    }	
}

function generateKeyTestString(key) {
    const md = forge.md.sha256.create();
    md.update('signmage', 'utf8');
    const signed = key.sign(md);
    return forge.util.encode64(signed);
}

function cancelVerification() {

    verificationPoller.postMessage(JSON.stringify({
        action: 'cancel'
    }))
}

function waitUntilVerified({keyTestString, verificationId}) {
    return new Promise((resolve, reject) => {

        verificationPoller.postMessage(JSON.stringify({
            action: 'ask',
            keyTest: keyTestString,
            verificationId: verificationId
        }))

        verificationPoller.addEventListener('message', message => {            
            const response = JSON.parse(message.data);
            if(response.error) {
                reject();
            } else {
                resolve(response)
            }            
        });
    })    
}

function certificateFromB64(b64) {
    let certb64 = b64;
    if(b64.indexOf('base64,') !== -1) {
        certb64 = b64.split('base64,')[1];
    }

    let certData = forge.util.decode64(certb64);    
    let pem = null;        
    
    if(getCertFormat(certData) === 'DER') {
        certData = forge.asn1.fromDer(certData);
        const cert = forge.pki.certificateFromAsn1(certData);
        pem = forge.pki.certificateToPem(cert);
    } else {
        pem = certData;
    }
    
    return pem;
}

function cerToPem(cert) {
    return forge.pki.certificateToPem(cert);
}

function privateKeyToPem(keyData) {
    forge.pki.privateKeyToPem(keyData)
}

function privateToBase64(keyData) {
    forge.util.encode64(keyData);
}

function openP12(pfx, passphrase) {
    var p12Der = forge.util.decode64(pfx.split('base64,')[1]);
    var p12Asn1 = forge.asn1.fromDer(p12Der);

    var p12 = null;
    try {
        p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, passphrase);
    } catch (e) {
        p12 = null;
    }
    return p12;
}

function getCertFormat(certData) {
    return certData.indexOf('BEGIN CERTIFICATE') !== -1 ? 'PEM' : 'DER';
}

async function signCsr(csrData, passphrase, stickerData, signupAuthority, dataType, forExistingUser, from, signerLink, ticket, setLink, pin) {
    const {csrPem, pkcs8Pem} = csrData;
    const decryptedKey = forge.pki.decryptRsaPrivateKey(pkcs8Pem, passphrase);
    const keyTestString = generateKeyTestString(decryptedKey);    

    const request = {
        keyTestString: keyTestString,
        invited: true,
        email: null,
        phone: null,
        signupAuthority: signupAuthority?.id,
        dataType: dataType,				
        csrPem: csrPem,
        token: null,
        from: from ? `https://${window.location.host}/pdf/${from}/` : null,
        signerLink,
        ticket,
        setLink,
        pin
    }

    request[dataType.toLowerCase()] = stickerData;

    if(signupAuthority.verificationType === 'FIREBASE') {

        const response = await HttpRequest.post('/signup/signer/oauth/csr', request, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('utoken')}`
            }
        })    
        return {...response.data, keyTestString};

    } else {
        const response = await HttpRequest.post(forExistingUser ? '/signup/signer/user/csr' : `/signup/signer/csr`, request)    
        return {...response.data.data, keyTestString};
    }    
}

async function signerFromCert(pemCert, keyTestString, forExistingUser) {
    
    const response = await HttpRequest.post(forExistingUser ? '/signup/signer/user/cert' : `/signup/signer/cert`, {
        cert: pemCert,
        token: null,
        keyTestString,
        invited: false,
        email: null,
        phone: null
    })

    const data = response.data.data;
    return {
        type: data.verificationType,
        fingerprint: data.fingerprint,
        verificationId: data.verificationId,
        waitingSpei: false
    }

}

function generateCsr(csrRequest) {
    return new Promise((resolve, reject) => {
        forge.pki.rsa.generateKeyPair({bits:2048, workers:-1, workerScript:primeWorker}, (err, keypair) => {

            if(err) {
                localStorage.setItem('key_err', err);
                return reject();
            }

            if(!csrRequest.passphrase) {
                csrRequest.passphrase = 'firmamex-temp';
            }

            const csr = forge.pki.createCertificationRequest()            
            csr.publicKey = keypair.publicKey;

            const distinguishedNames = [{
                name: 'commonName',
                value: csrRequest[csrRequest.certType] || csrRequest.name
            }];
            if(csrRequest.email) {
                distinguishedNames.push({
                    shortName: 'E',
                    value: csrRequest.email,
                    valueTagClass: forge.asn1.Type.IA5STRING
                })
            }

            csr.setSubject(distinguishedNames);
            csr.sign(keypair.privateKey);
            // convert certification request to PEM-format
            const csrPem = forge.pki.certificationRequestToPem(csr);

            // convert a Forge private key to an ASN.1 RSAPrivateKey
            const rsaPrivateKey = forge.pki.privateKeyToAsn1(keypair.privateKey);
            // wrap an RSAPrivateKey ASN.1 object in a PKCS#8 ASN.1 PrivateKeyInfo
            const privateKeyInfo = forge.pki.wrapRsaPrivateKey(rsaPrivateKey);

            const encryptedPrivateKeyInfo = forge.pki.encryptPrivateKeyInfo(
            privateKeyInfo, csrRequest.passphrase, {
                algorithm: '3des', // 'aes128', 'aes192', 'aes256', '3des'
            });

            const pkcs8Pem = forge.pki.encryptedPrivateKeyToPem(encryptedPrivateKeyInfo);

            resolve({
                csrPem, pkcs8Pem
            })

        });
    })
}

export default {
    generateCsr,
    signCsr,
    waitUntilVerified,
    cancelVerification,
    certificateFromB64,
    privateKeyFromB64,
    signerFromCert,
    changePassphrase,
    openP12,
    cerToPem,
    openKeystoreWithPassphrase,
    privateKeyToPem,
    privateToBase64
}