import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * The user can only add stickers to "editable" documents
 * 
 * editable is true when the "userId" in the document report and the uid
 * in the user session jwt are the same (and not undefined)
 */
export default function useEditable() {

    const {userId, allSigned} = useSelector(state => state.document.report);
    const {uid} = useSelector(state => state.session.current);
    const [editable, setEditable] = useState(false)

    useEffect(() => {
        setEditable(userId && uid && userId === uid && !allSigned)
    }, [userId, uid, allSigned])

    return editable;

}