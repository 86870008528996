
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import HttpRequest from './HttpRequest';
import {setDocument, setLocalSignaures, addTask, removeTask, updateTask, loadReport} from '../redux/actions'
import { useHistory } from 'react-router-dom';
import ResponsiveModal from './ResponsiveModal';
import { Progress } from 'reactstrap';


export default function DocumentDownloader({ticket}) {

    const history = useHistory();
    const {report} = useSelector(state => state.document)
    const dispatch = useDispatch();

    useEffect(() => {

        async function fetchDocument() {            
            const ticket = report.ticket;
            
            try {

                dispatch(addTask('downloading-document', 'downloading document', () =>
                    <div>
                        <Progress value={0}></Progress>
                    </div>))

                const doc = await HttpRequest.get(`/pdf/simple/${ticket}`, {
                    responseType: 'arraybuffer',
                    onDownloadProgress: (data) => {
                        dispatch(updateTask('downloading-document', () => <div>
                            <Progress value={(data.loaded / data.total) * 100}></Progress>
                        </div>))
                    }
                })

                dispatch(setDocument({
                    document: doc.data                
                })) 
                dispatch(removeTask('downloading-document'))
            } catch(e) {
                if(e.response && e.response.data) {
                    if(e.response.data.byteLength > 0) {
                        const errorDesc = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(e.response.data)))
                        if(errorDesc && errorDesc.error === 'drive_error') {
                            history.push(`/error?type=${errorDesc.error}&message=${errorDesc.message}&drive=${errorDesc.drive}`)
                            // alert(errorDesc.message)
                        }
                    } else if(e.response.status === 404) {
                        dispatch(loadReport(report.ticket))
                    }
                }
                dispatch(removeTask('downloading-document'))
            }                
        }

        if(report.ticket && !report.deleted && !(report.blockDownload && report.allSigned)) {
            fetchDocument();            
        }
    }, [report.ticket, dispatch, history, report.deleted, report.blockDownload, report.allSigned])    

    function showProgress() {

        if(report.blockDownload && report.allSigned) {
            return;
        }

        dispatch(addTask('downloading-document-signatures', 'downloading document signatures', () =>
        <div>
            <Progress value={0}></Progress>
        </div>))
    }

    function updateProgress(progress) {

        if(report.blockDownload && report.allSigned) {
            return;
        }

        dispatch(updateTask('downloading-document-signatures', () => <div>
                        <Progress value={progress}></Progress>
                    </div>))
    }

    function hideProgress() {

        if(report.blockDownload && report.allSigned) {
            return;
        }

        dispatch(removeTask('downloading-document-signatures'))
    }

    useEffect(() => {

        async function fetchSignatures() {
    
            // debugger
            showProgress()

            const signatures = await HttpRequest.get(`/pdf/signatures/original/${report.ticket}`, {
                responseType: 'arraybuffer',
                onDownloadProgress: (data) => {
                    updateProgress((data.loaded / data.total) * 100)    
                }
            })
            dispatch(setLocalSignaures({
                localSignatures:signatures.data
            }))

            hideProgress()
        }

        if(report.firmas.length > 0) {
            fetchSignatures();
        } else {
            dispatch(setLocalSignaures({
                localSignatures:null
            }))
        }

    }, [report.ticket, report.firmas.length, dispatch])

    function handleDownloadProgress(progressEvent) {
        const total = Number(progressEvent.target.getResponseHeader('X-Actual-Content-Length'))
    }

    return (
        <div></div>
    )

}