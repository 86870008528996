import React from 'react';

export default function Contact() {

    function contact() {
        // window.open("https://api.whatsapp.com/send?phone=526621370163", "_blank"); 
    }

    return(
        <div onClick={contact}>
            {/* <img alt="contacto" src={Whatsapp} id="contacto" style={{width: "37px", cursor: "pointer", marginRight: "25px" }}/> */}
            {/* <UncontrolledTooltip target="contacto">
                Contacto
            </UncontrolledTooltip> */}
        </div>
    )
}