import forge from './forge'
import HttpRequest from '../shared/HttpRequest'
import moment from 'moment'

function fromPem(certPem) {
    return ParsedCertificate(forge.pki.certificateFromPem(certPem))
}

function fromCert(forgeCert) {
    return ParsedCertificate(forgeCert);
}

function isAllowedForNewUser(forgeCert) {
    return async function() {
        const blob = new Blob([forge.pki.certificateToPem(forgeCert)], {
            type: 'application/x-x509-user-cert'
        });
        const response = await HttpRequest.post('/signup/validate_cert', blob, {
            headers: {
                'Content-Type': 'application/x-x509-user-cert'
            }
        })
        return response.data === true;
    }
}


function ParsedCertificate(forgeCert) {    

    let _issuerName = null;
    let _uid = null;
    let _commonName = null;

    function verifySticker(sticker) {
        const authorities = sticker.authority.authorities;
        if(authorities.find(a => a === getIssuerName())) {
            let uid = getUid().toLowerCase();        
            if(uid.includes('/')) {
                uid = uid.split('/')[0].trim();
            }
            const isDataEqual = sticker.data.toLowerCase() === uid;
            
            if(sticker.authority.uiName === 'SAT') {
                return isDataEqual && isSello();
            }

            return isDataEqual;
        }
        return false;
    }

    const getIssuerName = () => {
        if(_issuerName) {
            return _issuerName;
        }

        if(forgeCert.issuer.getField('CN')) {
            _issuerName = forgeCert.issuer.getField('CN').value;
        } else if(forgeCert.issuer.getField('E')) {
            _issuerName = forgeCert.issuer.getField('E').value;
        }

        try {
            var decoded = decodeURIComponent(escape(_issuerName));    
            _issuerName = decoded;
        } catch(e) {

        }

        return _issuerName;
    }

    function getCommonName() {
        if(_commonName) {
            return _commonName;
        }

        if(forgeCert.subject.getField('CN')) {
            _commonName = forgeCert.subject.getField('CN').value;
        } else if(forgeCert.subject.getField('E')) {
            _commonName = forgeCert.subject.getField('E').value;
        }

        try {
            const decoded = decodeURIComponent(escape(_commonName));    
            _commonName = decoded;
        } catch(e) {

        }

        return _commonName;
    }

    function getUid() {
        if(_uid) {
            return _uid;
        }

        const attributes = forgeCert.subject.attributes;
        const uidOID = '2.5.4.45';
        for(let attr of attributes) {
            if(attr.type === uidOID) {
                _uid = attr.value;
                break;
            }
        }

        if(!_uid) {
            const extensions = forgeCert.extensions;
            const uidExt = "2.5.29.17";
            for(let ext of extensions) {
                if(ext.id === uidExt) {
                    const altName = ext.altNames;
                    _uid = altName[0].value.toLowerCase();
                    break;
                }
            }
        }

        return _uid;
    }

    function isSello() {

            var u = forgeCert.getExtension('keyUsage');
            if(!u.cRLSign && !u.critical && !u.decipherOnly && !u.encipherOnly &&
                !u.keyCertSign && !u.keyEncipherment) {

                if(u.dataEncipherment && u.keyAgreement && u.nonRepudiation 
                    && u.digitalSignature) {
                    return true;
                }
            }
            return false;            
    }

    function getNotAfter() {
        return moment(forgeCert.validity.notAfter).format('DD/MM/YYYY');
    }

    function isExpired() {
        return new moment().isAfter(forgeCert.validity.notAfter);
    }

    function matchesKey(key) {
        return forgeCert.publicKey.n.equals(key.n);
    }

    function keyTestString(key) {
        const md = forge.md.sha256.create();
        md.update('signmage', 'utf8');
        const signed = key.sign(md);
        return forge.util.encode64(signed);
    }

    return {
        getIssuerName,
        getUid,
        verifySticker,
        isAllowedForNewUser: isAllowedForNewUser(forgeCert),
        isExpired,
        getNotAfter,
        matchesKey,
        keyTestString,
        getCommonName
    }

}

export default {
    fromPem, fromCert
}