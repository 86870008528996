import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider, sendSignInLinkToEmail,
    isSignInWithEmailLink, signInWithEmailLink
} from 'firebase/auth'
import HttpRequest from '../shared/HttpRequest';

const firebaseConfig = {
    apiKey: "AIzaSyBIZn1_CCHZtzXiC8ycbz28vQjRRXIEdeM",
    authDomain: "app2-firmamex.firebaseapp.com",
    databaseURL: "https://app2-firmamex.firebaseio.com",
    projectId: "app2-firmamex",
    storageBucket: "app2-firmamex.appspot.com",
    messagingSenderId: "11150395071",
    appId: "1:11150395071:web:44ff59590a6ac6b24b280a",
    measurementId: "G-T181J1PHM0"
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
// connectAuthEmulator(firebaseAuth, 'http://localhost:9099');

function GmailAuth(hint) {

    const provider = new GoogleAuthProvider();

    const start = async () => {
        provider.setCustomParameters({
            login_hint: hint
        })
        return signInWithPopup(firebaseAuth, provider)
    }

    return {
        start
    }

}

function MicrosoftAuth(hint) {
    const provider = new OAuthProvider('microsoft.com');
    const start = async () => {
        provider.setCustomParameters({
            login_hint: hint
        })
        return signInWithPopup(firebaseAuth, provider)
    }

    return {
        start
    }

}

function LinkAuth(hint) {
    const auth = getAuth();
    
    const start = async() => {
        try {
            await sendSignInLinkToEmail(auth, hint, {
                url: `${window.location.href}&signupFromLink=true`,
                handleCodeInApp: true           
            })    
            localStorage.setItem('signupFromLink', hint)
            return {
                user: {
                    email: hint
                }
            };
        } catch(e) {
            
        }        
    }

    const complete = async () => {
        // await signInWithEmailLink(auth, window.location.href);       
        const email = localStorage.getItem('signupFromLink');
        if(!email)  {
            return null;
        }
        const signInResult = await signInWithEmailLink(auth, email, window.location.href);
        localStorage.removeItem('signupFromLink');
        return signInResult.user;
    }

    return {
        start, complete
    }
}

const sessionHandler = async (login, data) => {
    
    try {
        const session = await login.start();
        return {
            resolveSignIn: mfa => {
                return Promise.resolve(session);
            }
        }
    } catch(e) {
        console.error(e)
    }

}

const auth = async (login) => {

    const session = await sessionHandler(login);

    return async (verificationCode) => {
        if(!session) {
            return null;
        }
        const mfs = await session.resolveSignIn(null)
        return mfs.user;
    }

}

const getToken = async (forceRefresh) => {    
    try {
        
        if(!firebaseAuth.currentUser) {
            return {
                error: 'firebase_logged_out'
            }
        }

        const token = await firebaseAuth.currentUser.getIdToken(forceRefresh)
        return {
            token
        }
    } catch(error) {
        console.error(error);
        return {
            error: 'firebase_cant_get_token'
        }
    }
    
}

const frmxVerify = async (forceRefresh) => {
    const {token, error} = await getToken(forceRefresh);
    
    if(error) {
        return {error}
    }

    if(!forceRefresh && token) {
        return {token}
    }

    await HttpRequest.post(`/verification/oauth`, {
        token,
        frmxId: 'none'
    }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('utoken')}`
        }
    })

    return {token}
}

const addClaim = async frmxId => {
    try {
        const {token, error} = await getToken();
        if(error) {
            return {error}
        }

        return HttpRequest.post(`/signup/oauthToken/claims`, {
            token,
            frmxId
        })
    } catch(error) {
        return {error}
    }
    
}

function getAuthByLoginType(type) {
    switch(type) {
        case 'gmail': return GmailAuth;
        case 'microsoft': return MicrosoftAuth;
        default: return LinkAuth;
    }
}

const FrmxFirebase = {
    auth, getToken, GmailAuth, frmxVerify, addClaim, MicrosoftAuth, getAuthByLoginType, LinkAuth
}

export default FrmxFirebase