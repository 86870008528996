import { EMPTY, of, mergeMap} from "rxjs";
import forge from 'node-forge'



export const TIME_STAMP_TOKEN = '1.2.840.113549.1.9.16.1.4'
const AA_TIME_STAMP_TOKEN = '1.2.840.113549.1.9.16.2.14'
const SIGNED_DATA = '1.2.840.113549.1.7.2'

export class UniversalTags {

    static BER = 0;
    static BOOLEAN = 1;
    static OCTET_STRING = 4;
    static OBJECT_IDENTIFIER = 6;
    static SEQUENCE = 16;
    static SET = 17;
    static GENERALIZED_TIME = 24;    

}


export const Oid = (node) => {
    return forge.asn1.derToOid(node.value)
}

export const DerSet = (node) => {
    return of({
        ...node
    })
}

export const Sequence = (node) => {
    
    if(node.type === UniversalTags.SEQUENCE) {
        return of({
            ...node,
            value: node.value
        })
    }

    return EMPTY;    
}

export const AdobeTimeStampToken = (seq) => {

    if(seq.value?.length > 0 && seq.value[0].type === UniversalTags.OBJECT_IDENTIFIER && Oid(seq.value[0]) === AA_TIME_STAMP_TOKEN) {
        if(seq.value[1].type === UniversalTags.SET)  {
            return of(seq)
        }
    }
    return EMPTY;
}

export const SignedData = (contentInfo) => {

    return contentInfo.value[0].type === UniversalTags.OBJECT_IDENTIFIER && Oid(contentInfo.value[0]) === SIGNED_DATA ?
        of(contentInfo.value[1].value[0]) 
        : EMPTY;

}

export const TimeStampToken = (adobeTimeStampToken)  => {

    const derSet = DerSet(adobeTimeStampToken.value[1])
    const contentInfo = derSet.pipe(mergeMap(d => Sequence(d.value[0])));
    const signedData = contentInfo.pipe(mergeMap(ci => SignedData(ci)))
    const encapContentInfo = signedData.pipe(mergeMap(sd => Sequence(sd.value[2])))

    return encapContentInfo.pipe(
        mergeMap(eci => eci.value[0].type === UniversalTags.OBJECT_IDENTIFIER && Oid(eci.value[0]) === TIME_STAMP_TOKEN ?
            of(eci)
            : EMPTY
        )
    )

}
export const TSTInfo = (timestampToken) => {
    return timestampToken.value[1].value[0].type === UniversalTags.OCTET_STRING ?
        of(
            forge.asn1.fromDer(timestampToken.value[1].value[0].value)
        ) 
        : EMPTY

}

export const GeneralizedTime = (tstInfo)  => {
    return of(forge.asn1.generalizedTimeToDate(tstInfo.value[4].value))
}