import React, {useReducer, useState, useEffect, useRef, useCallback} from 'react';
import {fabric} from 'fabric';
import styles from './SignatureCanvas.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faTrash, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import FlexRow from './FlexRow';
import SolidButton from './SolidButton';
import {IS_MOBILE} from '../Consts'
import HorizontalSlider from './HorizontalSlider'
import FlexColumn from './FlexColumn'
import {useSelector, useDispatch} from 'react-redux'
import {setSignatureStroke, setSignatureStrokeImage} from '../redux/actions'
import UploadImageModal from './UploadImageModal';
import HttpRequest from './HttpRequest';
import TextButton from './TextButton';
import { Button } from 'reactstrap';

import { useTranslation } from 'react-i18next';


const CANVAS_WIDTH = IS_MOBILE ? document.body.clientWidth - 34 - 30 : 450

function getEventData(e) {
    var data = {};
    if (e.type && e.type.indexOf('touch') !== -1) {
        data.pageX = e.touches[0].pageX;
        data.pageY = e.touches[0].pageY;
        data.clientX = e.touches[0].clientX;
        data.clientY = e.touches[0].clientY;
    } else {
        data.pageX = e.pageX;
        data.pageY = e.pageY;
        data.clientX = e.clientX;
        data.clientY = e.clientY;
    }
    data.type = e.type;
    return data;
};

function getPoint(e) {
    const eventData = getEventData(e);    
    const {x, y} = e.target.getBoundingClientRect();
    return {
        x: (eventData.pageX - x),
        y: (eventData.pageY - y)
    }
}

function parseLimitState(limit) {
    if(limit.state) {
        return {
            minX: limit.state.minX,
            minY: limit.state.minY,
            maxX: limit.state.minX + limit.state.width,
            maxY: limit.state.minY + limit.state.height
        }
    } else {
        return {
            minX: limit.limits.tx,
            minY: limit.limits.ty,
            maxX: limit.limits.lx,
            maxY: limit.limits.ly
        }
    }
}

const actions = {
    SET_CANVAS: 'SET_CANVAS',
    SET_FIRST_LIMITS:'SET_FIRST_LIMITS',
    SET_CURRENT_LIMITS: 'SET_CURRENT_LIMITS',
    ADD_LIMIT:'ADD_LIMIT',
    UNDO: 'UNDO',
    SET_BRUSH_WIDTH: 'SET_BRUSH_WIDTH',
    CLEAR: 'CLEAR',
    SET_BRUSH_COLOR: 'SET_BRUSH_COLOR',
    SET_TOUCHING: 'SET_TOUCHING'
}

const COLORS = {
    BLACK:'black',
    BLUE: '#1569c6'
}

const canvasInitialState = {
    canvas: null,
    limits: [],
    currentLimits: null,
    brushWidth: 3,
    brushColor: COLORS.BLACK,
    touching: false
}

function canvasReducer(state, action) {
    switch(action.type) {
        case actions.SET_TOUCHING: {
            const canvas = state.canvas;
            if(!action.payload && canvas && canvas.freeDrawingBrush._points && canvas.freeDrawingBrush._points.length > 0) {
                canvas.freeDrawingBrush.onMouseUp({e:{}});                
            }
            return {
                ...state,
                touching: action.payload
            }
        }
        case actions.SET_CANVAS: {                    
            // const {canvas, brushWidth, limits, currentLimits} = action.payload;
            // canvas.freeDrawingBrush.width = brushWidth;
            // return {...state, canvas, brushWidth, limits, currentLimits}
            const {canvas, brushWidth, brushColor} = state;
            const {canvasElem, existingStroke} = action.payload;
            
            const fabricCanvas = canvas || new fabric.Canvas(canvasElem, {
                isDrawingMode: true
            });     

            let currentLimits = null;
            let limits = [];
            let width = brushWidth;
            let color = brushColor;
            if(existingStroke && existingStroke.strokeData) {
                fabricCanvas.loadFromJSON(existingStroke.strokeData);
                currentLimits = existingStroke.limits;
                for(let p of fabricCanvas._objects) {
                    limits.push({
                        state: p,
                        limits: currentLimits,
                        undoable: false
                    })
                }
                width = existingStroke.width;      
                color = existingStroke.color;                      
            }
            fabricCanvas.freeDrawingBrush.width = width;
            fabricCanvas.freeDrawingBrush.color = color;
            fabricCanvas.freeDrawingBrush.limitedToCanvasSize = true;

            return {...state, 
                canvas: fabricCanvas,
                brushWidth: width,
                brushColor: color,
                currentLimits,
                limits    
            }

        }
        case actions.SET_FIRST_LIMITS: {
            const {currentLimits, limits} = action.payload;
            return {...state, currentLimits, limits}
        }
        case actions.SET_CURRENT_LIMITS: {
            const {currentLimits} = action.payload;
            return {...state, currentLimits, touching: true};
        }
        case actions.ADD_LIMIT: {
            const {currentLimits} = state;
            const {path} = action.payload;
            const limit = {
                state: path,
                limits: currentLimits,
                undoable: true
            }
            return {...state, limits:[...state.limits, limit]}
        }
        case actions.SET_BRUSH_COLOR: {
            const {color} = action.payload;
            const {limits, canvas} = state;

            const newLimits = limits.map((canvasState) => {
                if(canvasState.state) {
                    canvasState.state.set({
                        stroke: color
                    })
                }
                return canvasState;
            })


            canvas.freeDrawingBrush.color = color;
            canvas.renderAll();
            return {...state, limits:newLimits, canvas, brushColor: color}
        }
        case actions.SET_BRUSH_WIDTH: {
            const {width} = action.payload;
            const {limits, canvas} = state;
            const newLimits = limits.map((canvasState) => {
                if(canvasState.state) {
                    canvasState.state.set({
                        strokeWidth: width
                    })
                }
                return canvasState;
            })
            canvas.freeDrawingBrush.width = width;
            canvas.renderAll();
            return {...state, limits:newLimits, canvas, brushWidth: width}
        }
        case actions.UNDO: {            
            const {canvas, limits, currentLimits} = state;
            if(limits.length > 0 && currentLimits) {

                const toDelete = limits[limits.length - 1];
                if(toDelete.undoable) {
                    const newLimits = limits.slice(0, limits.length - 1)
                    canvas.remove(toDelete.state);

                    let newCurrentLimits;
                    if(newLimits.length > 0) {
                        newCurrentLimits = newLimits[newLimits.length - 1].limits;
                    } else {
                        newCurrentLimits = null;
                    }
                    return {...state, limits: newLimits, currentLimits: newCurrentLimits}
                }
            }
            return state;
        }
        case actions.CLEAR: {
            const {canvas} = state;
            canvas.clear();
            return {...state, currentLimits: null, limits: []}
        }
        default: {
            return state;
        }
    }
}

function ColorPicker({color, onChange, className}) {   
    
    return (
        <div className={`${styles.colorPicker} ${className}`}
            onClick={onChange}
            style={{background: color}}></div>
    )
}

export default function SignatureCanvas({imageType, singleUse, onDone, onCancel}) {

    const {t} = useTranslation()
    const canvasRef = useRef();
    const imageRef = useRef(); 
    const [customImage, setCustomImage] = useState({
        selecting: false,
        data: null
    });
    const [error, setError] = useState(null);
    const [working, setWorking] = useState(false)

    const [{canvas, currentLimits, brushWidth, brushColor, touching}, dispatch] = useReducer(canvasReducer, canvasInitialState);
    const userSession = useSelector(state => state.session.current);
    const globalDispatch = useDispatch();

    useEffect(() => {

        if(imageType !== 'stroke_only' && userSession.signatureStroke && userSession.signatureStroke.isCustomImage) {
            // es img si se esta cargando ya formateada del servidor
            // es data si se esta cargando como base64 directamente del input
            const img = userSession.signatureStroke.img || `data:image/png;base64,${userSession.signatureStroke.data}`
            setCustomImage({selecting: false, data: img})
        }

        if(singleUse && imageType !== 'stroke_only') {
            async function fetchCustomStrokeForSingleUse() {
                const response = await HttpRequest.get('/user/img/custom');
                if(response.data && response.data.result === 'success') {
                    setCustomImage({selecting: false, data: response.data.img})               
                }
            }
            fetchCustomStrokeForSingleUse()
        }

    }, [userSession.signatureStroke, imageType, singleUse])

    useEffect(() => {

        async function fetchExistingStroke() {

            let image = null;
            
            if(!singleUse) {
                image = userSession.signatureStroke;
            } else {
                const response = await HttpRequest.get('/user/img/stroke');
                image = response.data;
            }
            dispatch({
                type: actions.SET_CANVAS,
                payload: {
                    canvasElem: canvasRef.current,
                    existingStroke: image,
                }
            })
        }
                  
        if(canvasRef.current) {
            fetchExistingStroke();
        } 

    }, [canvasRef, userSession.signatureStroke, singleUse])

    useEffect(() => {

        if(canvas) {
            canvas.on('path:created', (e) => {   
                dispatch({
                    type: actions.ADD_LIMIT,
                    payload: {
                        path:e.path
                    }
                })           
            })
        }

    }, [canvas])

    const calcNewLimits = useCallback(function(e) {
        const newPoint = getPoint(e);

        if(newPoint.y <= 0 || newPoint.y >= 300) {
            return;
        }

        if(newPoint.x <= 0 || newPoint.x >= 450) {
            return;
        }

        if(!currentLimits) {
            dispatch({
                type: actions.SET_FIRST_LIMITS,
                payload: {
                    currentLimits: {
                        tx: newPoint.x,
                        ty: newPoint.y,
                        lx: newPoint.x,
                        ly: newPoint.y
                    },
                    limits: [{
                        state: null,
                        limits: {
                            tx: newPoint.x,
                            ty: newPoint.y,
                            lx: newPoint.x,
                            ly: newPoint.y
                        },
                        undoable: false
                    }]
                }
            })
            return;
        }   

        const newCurrentLimits = {...currentLimits};
        if(newPoint.x < newCurrentLimits.tx && newPoint.x > 0) {
            newCurrentLimits.tx = newPoint.x;
        } else if(newPoint.x > newCurrentLimits.lx) {
            newCurrentLimits.lx = newPoint.x;
        }

        if(newPoint.y < newCurrentLimits.ty && newPoint.y > 0) {
            newCurrentLimits.ty = newPoint.y;
        } else if(newPoint.y > newCurrentLimits.ly) {
            newCurrentLimits.ly = newPoint.y;
        }

        dispatch({
            type: actions.SET_CURRENT_LIMITS,
            payload: {
                currentLimits:newCurrentLimits
            }
        })
    }, [currentLimits])

    const handleTouchmove = useCallback(function(e) {

        if(!touching) {
            e.nativeEvent.stopImmediatePropagation();
            // e.stopPropagation();
            e.preventDefault();
            return;
        }

        if(!touching && e.type !== 'touchmove') {
            return;
        }

        if(e.target.nodeName !== 'CANVAS') {
            return;
        }      

        if(e.touches && e.touches.length > 1) {
            return;
        }

        calcNewLimits(e);

    }, [touching, calcNewLimits])


    function handleMouseDown(e) {
        dispatch({
            type: actions.SET_TOUCHING,
            payload: true
        })
        calcNewLimits(e);
    }


    function handleMouseUp(e) {
        dispatch({
            type: actions.SET_TOUCHING,
            payload: false
        })
    }

    function handleClickDelete(e) {
        dispatch({
            type: actions.CLEAR,
            payload: {}
        })
    }

    function handleClickUndo(e) {
       dispatch({
           type:actions.UNDO,
           payload: {}
       })
    }

    const handleBrushWidthChange = useCallback(function(value) {
        dispatch({
            type: actions.SET_BRUSH_WIDTH,
            payload: {
                width:value
            }
        })
    }, []);


    function toggleColor() {
        dispatch({
            type: actions.SET_BRUSH_COLOR,
            payload: {
                color: brushColor === COLORS.BLACK ? COLORS.BLUE : COLORS.BLACK
            }
        })
    }

    function handleClickUploadImage() {
        setCustomImage({...customImage, selecting: true})
    }

    function handleClickCancelImage() {
        setCustomImage({...customImage, selecting: false})
    }

    function handleImageSelected(imageData) {
        setCustomImage({data: imageData, selecting: false})
    }

    async function handleClickRemoveImage() {
        setCustomImage({data: null, selecting: false})
    }

    async function handleClickOk() {


        //se subio una imagen propia
        setWorking(true);
        setError(null)
        if(customImage.data) {
            
            const {width, height} = imageRef.current;
            const data = customImage.data.replace('data:image/png;base64,', '');
            const differenceY = (80 - 16) * (300 / 320);

            const result = {
                width, 
                height, 
                data,
                imageType: 'stroke',
                diffY: differenceY                            
            }

            // if(!singleUse) {
            //     globalDispatch(setSignatureStroke({isCustomImage: true, ...result}))
            //     globalDispatch(setSignatureStrokeImage(result))            
            // }            

            onDone({
                strokeType: 'custom',
                image: result
            })


        } else {

            if(!currentLimits || canvas.isEmpty()) {
                setError(<span>{t('error dibuja firma')}</span>)
                setWorking(false)
                return;
            }

            // se dibujo un trazo de firma
            // const finalLimits = currentLimits;
            const finalLimits = canvas.getObjects().reduce((canvasLimits, path) => {

                const rect = path.getBoundingRect();
                if(!canvasLimits.tx || canvasLimits.tx > rect.left) canvasLimits.tx = rect.left;
                if(!canvasLimits.ty || canvasLimits.ty > rect.top) canvasLimits.ty = rect.top;

                const rectLx = rect.width + rect.left 
                const rectLy = rect.height + rect.top 
                if(!canvasLimits.lx || canvasLimits.lx < rectLx) canvasLimits.lx = rectLx;
                if(!canvasLimits.ly || canvasLimits.ly < rectLy) canvasLimits.ly = rectLy;

                return canvasLimits;

            }, {})

            // finalLimits.lx = finalLimits.lx + finalLimits.tx;
            // finalLimits.ly = finalLimits.ly + finalLimits.ty;
        
            const x = (finalLimits.tx - brushWidth);
            const y = (finalLimits.ty - 8 - brushWidth);
            const width = (finalLimits.lx - finalLimits.tx + 5 + brushWidth);
            const height = (finalLimits.ly - finalLimits.ty + 16 + brushWidth);

            if(width < 40 || height < 40 || canvas.freeDrawingBrush.width < 3) {
                
                if(canvas.freeDrawingBrush.width < 3) {
                    dispatch({
                        type: actions.SET_BRUSH_WIDTH,
                        payload: {
                            width:3
                        }
                    })
                }

                setError(<span>{t('tooBig')}</span>)
                setWorking(false)
                return;
            }

            const refLineBottom = 220;

            const scale = finalLimits.ly / 300;
            const differenceY = (finalLimits.ly - refLineBottom) * scale;                    

            const signatureStroke = {
                limits: finalLimits,
                strokeData: canvas.toJSON(),
                width: brushWidth,
                color: brushColor
            }

            const signatureStrokeImage = {
                tx: finalLimits.tx,
                ty: finalLimits.ty,
                width: width,
                height: height,
                imageType: 'stroke',
                diffY: differenceY,                
            }  

            // if(!singleUse) {
            //     globalDispatch(setSignatureStroke({isCustomImage:false, ...signatureStroke}))
            //     globalDispatch(setSignatureStrokeImage(signatureStrokeImage))
            // }


            onDone({
                strokeType: 'canvas',
                server: {isCustomImage: imageType === 'stroke_only' && userSession.signatureStroke.isCustomImage ? 
                            userSession.signatureStroke.isCustomImage : false, ...signatureStroke},
                image: signatureStrokeImage
            })
            
        }
    }

    return (
        <div className={styles.container}>    
                <p className="imageDialogTitle" style={{textAlign:'center'}}>
                    {t('dibuja tu firma')}
                </p>          
                <FlexRow flexWrap="nowrap" style={{display: customImage.data ? 'none' : 'flex'}}>
                    <FlexColumn className={styles.options}>
                        <FontAwesomeIcon 
                            icon={faUndo} 
                            onClick={handleClickUndo}
                            className={styles.option}></FontAwesomeIcon>
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={handleClickDelete}
                            className={styles.option}></FontAwesomeIcon>
                        <ColorPicker 
                            color={brushColor === COLORS.BLACK ? COLORS.BLUE : COLORS.BLACK}
                            onChange={toggleColor}
                            className={styles.option}></ColorPicker>                        
                        {   
                            imageType !== 'stroke_only' ?
                            <FontAwesomeIcon
                            icon={faCloudUploadAlt}
                            onClick={handleClickUploadImage}
                            className={styles.option}></FontAwesomeIcon> : null
                        }
                    </FlexColumn>
                    <div className={styles.canvasWrapper}
                        onTouchMoveCapture={handleTouchmove}
                        onMouseMoveCapture={handleTouchmove}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}                        
                        style={{width:CANVAS_WIDTH + 'px'}}>                                
                        <canvas ref={canvasRef} 
                            height="300"
                            width={CANVAS_WIDTH}></canvas>
                        <div className={styles.lineSeparator}></div>    
                    </div>   
                </FlexRow>
                <br/>
                <HorizontalSlider
                    style={{display: customImage.data ? 'none' : 'flex'}}
                    width={CANVAS_WIDTH}
                    minValue={3}
                    maxValue={13}
                    value={brushWidth}
                    onChange={handleBrushWidthChange}></HorizontalSlider>                
                <FlexColumn style={{display: !customImage.data ? 'none' : 'flex'}}>
                    <h3>{t('Imagen propia')}</h3>
                    <br/>
                    <img alt="imagen de firma" 
                        ref={imageRef} 
                        src={customImage.data} 
                        style={{maxWidth:'100%'}}></img>
                    <br/>
                    <TextButton style={{textAlign:'center'}} onClick={handleClickUploadImage}>
                        {t('Cambiar imagen')}
                    </TextButton>
                    <br/>
                    <TextButton style={{textAlign:'center'}} onClick={handleClickRemoveImage}>
                        {t('Dibujar mi trazo')}
                    </TextButton>
                    <br/>
                </FlexColumn>      
                <br/>
                {
                    error ? 
                    <div style={{color: 'red', textAlign:'center'}}>{error}</div> : null
                }
            {
                working ?
                <div style={{textAlign:'center'}}>{t('loading')}</div> : 
                <FlexRow justifyContent="flex-end" flexWrap="nowrap">
                    <SolidButton onClick={handleClickOk}>{t('accept')}</SolidButton>
                    <SolidButton color="secondary" onClick={onCancel}>{t('cancel')}</SolidButton>                
                </FlexRow>         
            }            
            <UploadImageModal isOpen={imageType !== 'stroke_only' && customImage.selecting}
                onCancel={handleClickCancelImage}
                onImageSelected={handleImageSelected}></UploadImageModal>
        </div>
    )
}
