import store from '../redux/store';
import {setDrawingSignature, setSignatureStroke, setSignatureStrokeImage} from '../redux/actions'
import HttpRequest from '../shared/HttpRequest';

const FONT_FAMILY = "'Allura', cursive"
const IMAGE_TYPE_DESC = 'desc';
const IMAGE_TYPE_NAME = 'name';
const IMAGE_TYPE_HASH = 'hash';
const IMAGE_TYPE_DRAWING = 'stroke';
const IMAGE_TYPE_QR = 'qr';

function mergeRequestedImage(sessionSignatureImage, stickerImageType) {

    const userImageType = sessionSignatureImage ? sessionSignatureImage.imageType : null;

    if(!stickerImageType) {
        return userImageType || 'any'
    }

    if(!stickerImageType.includes('any')) {
        return stickerImageType;
    }

    if(stickerImageType.includes('+') && userImageType) {
        return stickerImageType.replace('any', userImageType)
    }

    return userImageType || 'any';

}

async function generateImageForSticker({_id, imageType, authority}, ticket, sessionSignatureImage) {

    const parsedImageType = mergeRequestedImage(sessionSignatureImage, imageType);
    
    return parsedImageType.includes('hash') ?
            generateHash(_id, ticket, parsedImageType)
            : parsedImageType.includes('name') ?
            generateName(ticket, parsedImageType) 
            : parsedImageType.includes('stroke') ?
            requestSignatureDrawing(parsedImageType, ticket, authority.singleUse)
            : parsedImageType.includes('qr') ?
            generateQR(ticket, parsedImageType) 
            : generateDesc(ticket, parsedImageType)
}

async function requestQRimage(imageType, singleUse = false) {

    let result = null;

    try {
        result = await new Promise((resolve, reject) => {
            store.dispatch(setDrawingSignature({
                drawingSignature: {
                    working: true,
                    data: {
                        imageType,
                        singleUse,
                        resolve, reject
                    }
                }
            }));
        });

        await HttpRequest.post('/user/img/custom', {...result.image})
        
    } catch(e) {

    }    

    store.dispatch(setDrawingSignature({
        drawingSignature: {
            working: false,
            data: null
        }
    }));
    return result;
}

function parseImageType(imageType, requestedImageType) {
    return requestedImageType ? 
        requestedImageType.replace('stroke_only', 'stroke').replace('any', imageType)
        : imageType
}

async function requestSignatureDrawing(imageType, ticket, singleUse = false) {

    let result = null;


    try {
        result = await new Promise((resolve, reject) => {
            store.dispatch(setDrawingSignature({
                drawingSignature: {
                    working: true,
                    data: {
                        imageType,
                        singleUse,
                        resolve, reject
                    }
                }
            }));
        });

    
        const reqResponse = await HttpRequest.post('/user/image', {
            frmxId: ticket,
            singleUse,
            imageType: parseImageType('stroke', imageType),
            canvasData: result.server ? {
                ...result.server,
                limits: JSON.stringify(result.server.limits),
                strokeData: result.server.strokeData ? JSON.stringify(result.server.strokeData) : null                
            } : null,
            strokeType: result.strokeType,
            imageData: {               
                ...result.image, 
                data: result.image.data
            }
        })

        const imgData = reqResponse.data;

        if(!singleUse) {
            if(result.strokeType === 'custom') {
                store.dispatch(setSignatureStroke({isCustomImage: true, ...result.image}))
                store.dispatch(setSignatureStrokeImage({
                    ...result.image,
                    data: imgData.data
                }))                
            } else {
                store.dispatch(setSignatureStroke({...result.server, isCustomImage:false}))
                store.dispatch(setSignatureStrokeImage({
                    ...result.image,
                    data: imgData.data
                }))                
            }            
        }

        result = imgData;

        // if(result.strokeType === 'custom') {
        //     await HttpRequest.post('/user/img/custom', {...result.image})
        // } else if(result.strokeType === 'canvas') {
        //     await HttpRequest.post('/user/img/stroke', {...result.server})
        // }

    } catch(e) {
        store.dispatch(setDrawingSignature({
            drawingSignature: {
                working: false,
                data: null
            }
        }));
        return null;
    }    
    

    store.dispatch(setDrawingSignature({
        drawingSignature: {
            working: false,
            data: null
        }
    }));
    return result;
}

async function generateHash(_id, ticket, imageType) {

    const response = await HttpRequest.post('/user/image', {
        frmxId: ticket,
        imageType: parseImageType('hash', imageType)
    })
    
    // const response = await HttpRequest.get(`/user/image/${ticket}/hash`)
    const imgData = response.data.data;
    const size = {
        width: response.data.width,
        height: response.data.height
    }
    
    // if(imgData) {
    if(atob(imgData).length < 1500) {
        alert('Error generando hash de firma')
        throw new Error('error generating hash for signature ')
    }

    return {
        width: size.width + 10,
        height: size.height + 4,
        data: imgData,
        imageType: 'hash'
    };

}

async function generateDesc(ticket, imageType) {

    
    const response = await HttpRequest.post('/user/image', {
        frmxId: ticket,
        imageType: parseImageType('desc', imageType)
    })

    // const response = await HttpRequest.get(`/user/image/${ticket}/desc`)
    const imgData = response.data.data;
    const size = {
        width: response.data.width,
        height: response.data.height
    }
    
    // if(imgData) {
    if(atob(imgData).length < 1500) {
        alert('Error generando hash de firma')
        throw new Error('error generating hash for signature ')
    }

    return {
        width: size.width + 10,
        height: size.height + 4,
        data: imgData,
        imageType: 'desc'
    };

}

async function generateQR(ticket, imageType) {
    
    const response = await HttpRequest.post('/user/image', {
        frmxId: ticket,
        imageType: parseImageType('qr', imageType)
    })

    const size = {
        width: response.data.width,
        height: response.data.height
    }    

    return {
        width: size.width + 10,
        height: size.height + 4,
        data: response.data.data,
        imageType: 'qr'
    };
}

async function generateName(ticket, imageType) {

    
    const response = await HttpRequest.post('/user/image', {
        frmxId: ticket,
        imageType: parseImageType('name', imageType)
    })

    // const response = await HttpRequest.get(`/user/image/${ticket}/desc`)
    const imgData = response.data.data;
    const size = {
        width: response.data.width,
        height: response.data.height
    }
    
    // if(imgData) {
    if(atob(imgData).length < 1500) {
        alert('Error generando hash de firma')
        throw new Error('error generating hash for signature ')
    }

    return {
        width: size.width + 10,
        height: size.height + 4,
        data: imgData,
        imageType: 'name'
    };

}


function generateName_(data, issuerName) {
    const body = document.body;
    const canvas = document.createElement('canvas');
    canvas.width = 550;
    canvas.height = 500;
    body.append(canvas);
    
    const ctx = canvas.getContext('2d');
    const nameSigSize = fitText(canvas, ctx, formatName(data));
    const nameImageData = canvasToImage(ctx, 0, 0, nameSigSize.width, nameSigSize.height);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    body.removeChild(canvas);

    return {
        id: `name-${data}-${issuerName}`,
        width: (nameSigSize.width + 10),
        height: (nameSigSize.height),
        data: nameImageData,
        imageType: IMAGE_TYPE_NAME
    }
}

// function getDescAuthorityText(issuerName, translate) {
    
//     if(issuerName.toLowerCase().includes('por liga a correo')) {
//         return translate.es.SIG_EMAIL_LINK;
//     } else if(issuerName === 'AUTORIDAD CERTIFICADORA' || 
//             issuerName.toLowerCase().includes('a.c. del servicio de administración tributaria')) {
//         return 'EMITIDO POR EL SAT PARA:'
//     } else if(issuerName.toLowerCase().includes('correo')) {
//         return translate.es.SIG_EMAIL_2;
//     } else if(issuerName.toLowerCase().includes('phone') || issuerName.toLowerCase().includes('sms')) {
//         if(issuerName.toLowerCase().includes('por liga')) {
//             return translate.es.SIG_PHONE_LINK;
//         }
//         return translate.es.SIG_PHONE_2;
//     }
//     return "";
// }


// function generateDesc(data, issuerName, translate) {
//     const body = document.body;
//     const canvas = document.createElement('canvas')
//     canvas.width = 500;
//     canvas.height = 500;
//     body.append(canvas);
//     const ctx = canvas.getContext('2d');

//     const lines = [
//         "FIRMA REALIZADA CON UN CERTIFICADO",
//          getDescAuthorityText(issuerName, translate),
//         data
//     ]

//     const size = fitMultipleLines(canvas, ctx, lines);
//     const imgData = canvasToImage(ctx, 0, 0, size.width, size.height);
//     body.removeChild(canvas);

//     return {
//         id: `desc-${data}-${issuerName}`,
//         width: size.width + 10,
//         height: size.height,
//         data: imgData,
//         imageType: IMAGE_TYPE_DESC
//     };

// }

function canvasToImage(context, x, y, width, height) {

    const body = document.body;
    const imageData = context.getImageData(x, y, width, height);
    const canvas = document.createElement('canvas')
    canvas.width = width + 10;
    canvas.height = height + 1;
    canvas.style.visibility = 'hidden';
    body.append(canvas);

    const c = canvas.getContext('2d');
    c.putImageData(imageData, 5, 0);

    const resultData = canvas.toDataURL("image/png").split('data:image/png;base64,')[1];
    body.removeChild(canvas);

    return resultData;
}

function fitMultipleLines(canvas, ctx, lines) { 

    let fontSize = 22;
    // let maxLength :string = '';
    const maxLength = lines.reduce((prev, curr) => prev.length > curr.length ? prev : curr);
    // maxLength = lines.filter(line => line.length > maxLength.length)[0];

    ctx.font = `bold ${fontSize}px Arial`;
    let textSize = ctx.measureText(maxLength);

    while(textSize.width > canvas.width) {            
        ctx.font = `bold ${--fontSize}px Arial`;
        textSize = ctx.measureText(maxLength);
    }

    var y = 18;

    for(var i = 0; i < lines.length; i++) {
        ctx.fillText(lines[i], 0, y);
        y += 22;
    }

    y -= 18

    return {
        width: 500,
        height: y
    }

}

function fitHash(canvas, ctx, hash) {
    const fontSize = 22;
    const maxLineLength = 30;
    const lines = hash.match(new RegExp(`.{1,${maxLineLength}}`,'g'))

    ctx.font = 'bold '+ fontSize + 'px arial';
    let y = 18;
    
    for(let line of lines) {
        ctx.fillText(line, 0, y);
        y += 22;
    }		

    return {
        width:500,
        height: y
    }

}

function fitNameLength(name, maxLength)  {

    const splittedName = name.split(' ');
    let newName = '';
    
    for(let i = 0; i < splittedName.length; i++) {

        if((newName.length + splittedName[i].length) < maxLength) {
            newName += splittedName[i] + ' ';

        } else if((newName.length + 1) <= maxLength) {
            newName += splittedName[i][0] + '.';
            break;
        }
    }

    return newName;

}

function fitText(canvas, context, name) {

    var fontSize = 30;

    context.font = fontSize + 'px ' + FONT_FAMILY;    
    var textSize = context.measureText(name);
    while(textSize.width > canvas.width) {

        context.font = --fontSize + 'px ' + FONT_FAMILY;
        textSize = context.measureText(name);
    }

    textSize.height = fontSize;

    context.fillText(name, 0, textSize.height/2 + 5);

    return textSize;
}

function formatName(name) {
    let pieces = name.split(' ');
    if(pieces.length > 1) {
        pieces = name.split(' ').map(p => p[0].toUpperCase() + p.substring(1).toLowerCase()); 
    }
    if(pieces.length > 2) {                
        name = pieces[0] + ' ' + pieces[1] + ' ' + pieces[2].substring(0,1) + '.'
    }
    return name;
}

export default {
    generateDesc,
    generateQR,
    generateName,
    generateHash,
    generateImageForSticker,
    requestSignatureDrawing,
    IMAGE_TYPES: {
        IMAGE_TYPE_DESC,
        IMAGE_TYPE_NAME,
        IMAGE_TYPE_HASH,
        IMAGE_TYPE_DRAWING,
        IMAGE_TYPE_QR
    }
}