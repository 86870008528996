import React from 'react';
import { useTranslation } from 'react-i18next';
import { IS_MOBILE } from '../Consts';

export default function GeoLocateOverlay({ isOpen }) {

    const { t } = useTranslation()

    return (
        isOpen ?
            !IS_MOBILE ?
                <div className="GeoLocateOverlay">
                    <img style={{ maxWidth: '320px', marginLeft: '45%' }} src="https://storage.googleapis.com/static.firmamex.com/arrow_white.png" />
                    <br />
                    <span>{t('LOCATION_DENIED')}</span>
                    <br />
                    <span>
                        {t('LOCATION_DENIED_DESCRIPTION_DESKTOP_1')}
                         <img style={{ maxWidth: '48px' }} src="https://storage.googleapis.com/static.firmamex.com/location_denied.png" /> 
                        {t('LOCATION_DENIED_DESCRIPTION_DESKTOP_2')}     
                    </span>
                </div> :
                <div className="GeoLocateOverlayMobile">
                    <span className="TextOverlayMobile">
                        {t('LOCATION_DENIED')}
                    </span>
                    <br />
                    <span className="TextOverlayMobile">
                        <p>{t('LOCATION_DENIED_DESCRIPTION_MOBILE_1')}</p>
                        <p>{t('LOCATION_DENIED_DESCRIPTION_MOBILE_2')}</p>
                        <p>
                            <a href="https://support.google.com/chrome/answer/114662" target="_blank">
                                <span>{t('LOCATION_DENIED_DESCRIPTION_MOBILE_CLICK')}</span>
                            </a>
                            <span>{t('LOCATION_DENIED_DESCRIPTION_MOBILE_3')}</span>
                        </p>
                    </span>
                </div> :
            null
    )
}